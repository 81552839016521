import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 

@Component({
  selector: 'app-capabilitiesgrid',
  templateUrl: './capabilitiesgrid.component.html',
  styleUrls: ['./capabilitiesgrid.component.scss']
})
export class CapabilitiesgridComponent implements OnInit {
 name;
 fullname1;
 frm1_email;

 phoneNumber;
 email;
 countryCode;
  constructor(private toaster: ToastrService,private meta: Meta, private titleService: Title,public commonfunctions: CommonfunctionsService) {

    this.titleService.setTitle('IT Staff Augmentation | Hire Dedicated Resources| JTSi Technologies India|USA');
      this.meta.addTags([
      {name:'description', content:'JTSi Technologies provides top rated staff augmentation services offering dedicated resources and teams for software companies in Virginia,New jersey,USA'},
      {name:'keywords', content:'IT Staff Augmentation services,Hire Dedicated Resources,Offshore Dedicated Teams,IT outsourcing services'},
      
      {property :'og:locale', content:'en_US'},
      {property :'og:type', content:'website'},
      {property:'og:title',content:'JTSi offers top rated IT Outsourcing & Staff Augmentation services in USA | VIRGINIA' },
      {property:'og:description', content:'"Engage our highly skilled, dedicated resources and teams by quickly ramping up capabilities or bridge skill gaps to fulfill growing software project demands quikly at reduced costs.' } ,                                                         
      {property:'og:url', content:'"https://www.jtsiindia.com/IT-staffing-solutions"'},
      {property:'og:image', content:'"https://www.jtsiindia.com/assets/img/promoheader.webp"'},
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
    
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'JTSI offers top rated IT Outsourcing & Staff Augmentation services in USA | VIRGINIA'},
      {name:'twitter:description', content:'Engage our highly skilled,ramping up capabilities or bridge skill gaps to fulfill growing software project demands quickly at reduced costs'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/promoheader.webp'}
      ]);
    
    
   }

  ngOnInit(): void {

      let i;
 var freeaq = document.getElementsByClassName("freeaqs");
 var eachque = document.getElementsByClassName("ottachodyam");
 
 // Create event listeners for each accordion heading
 for (i = 0; i < eachque.length; i++) {
  eachque[i].addEventListener("click", ansWer);
 }
 
 function ansWer() {
   var itemClassName = this.parentNode.className;
 
   // If we want to close previous open item when opening another
   for (i = 0; i < freeaq.length; i++) {
    freeaq[i].className = 'freeaqs closed';
   }
 
   if (itemClassName == 'freeaqs closed') {
     this.parentNode.className = 'freeaqs open';
   }
 }
 
 window.onunload = function() {
   for (i = 0; i < eachque.length; i++) {
    eachque[i].removeEventListener("click", ansWer);
   }        
   return;
 }


 var skillset = document.getElementsByClassName("skillsets");
 var skillname = document.getElementsByClassName("skillname");
 
 // Create event listeners for each accordion heading
 for (i = 0; i < skillname.length; i++) {
  skillname[i].addEventListener("click", empSkills);
 }
 
 function empSkills() {
   var itemClassName = this.parentNode.className;
 
   // If we want to close previous open item when opening another
   for (i = 0; i < skillset.length; i++) {
    skillset[i].className = 'skillsets closed';
   }
 
   if (itemClassName == 'skillsets closed') {
     this.parentNode.className = 'skillsets open';
   }
 }
 
 window.onunload = function() {
   for (i = 0; i < skillname.length; i++) {
    skillname[i].removeEventListener("click", empSkills);
   }        
   return;
 }
   
  }

  phoneValid = true;



  onSubmitPopumfrm(frm: NgForm) {

    if (frm.invalid) {
     return;

    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
       
       
  }

  onSubmitContactfrm(frm1: NgForm) {
   
    
    if (frm1.invalid) {
     
      return;
     

    }
    if (!this.commonfunctions.validateEmail(frm1.value.email1)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }
  
   
    frm1.controls['phoneNumber'].setValue(frm1.value.countryCode + frm1.value.phoneNumber);
   
    
  }

  hasError(event: any, frm1: NgForm) {
    
    this.phoneValid = true;
    console.log("error",frm1.value.phoneNumber);
    if (!event && frm1.value.phoneNumber != "") {
      this.phoneValid = false;
      console.log("error",frm1.value.phoneNumber);
      
      
    }
  }

  onContryChange(event: any, frm: NgForm) {
    frm.controls['countryCode'].setValue(event.dialCode);
  }

}
