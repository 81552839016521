import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-currentnews',
  templateUrl: './currentnews.component.html',
  styleUrls: ['./currentnews.component.scss']
})
export class CurrentnewsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Current News | Latest Company Updates | JTSi Technologies');
    this.meta.addTags([
      {name:'description', content:'Learn about our products, services, and the latest news in the software/IT industry by signing up on our website'},
      {property:'og:locale', content:'en_US' } ,
      {property:'og:type', content:'website'},
      {property:'og:title' ,content:'="Current News | Latest Company Updates | JTSi Technologies '},
      {property:'og:description', content:'"Learn about our products, services, and the latest news in the software/IT industry by signing up on our website.'},
      {property:'og:url', content:'"https://www.jtsiindia.com/why-JTSi/current-news'},
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/news.webp'},
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time' },
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Current News | Latest Company Updates | JTSi Technologies '},
      {name:'twitter:description', content:'Learn about our products, services, and the latest news in the software/IT industry by signing up on our website.'},
      {name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/news.webp'}
      ]);
    }
  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
