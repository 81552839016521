
<section class="commonPadding" id="priceDelivery">
    <div class="texttitle_two">
      <h2 class="nocase">Delivery/Pricing</h2>
    </div>
    <div class="containers homeGrid">
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/hire_01.webp" alt="IT/software recruitment & staffing company in USA">
        </div>
        <div class="overlays">
          <h4>
            Hire Resources
          </h4>
  
          <a [routerLink]="['/delivery-pricing/hire-resources']" class="btn btn-md btn-primary" id="hire-esources-view">Detailed View</a>
        </div>
      </div>
  
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/team_thumb.webp" alt="software team as a service solution providers in USA.">
        </div>
        <div class="overlays">
          <h4>
            Team as a Service (TaaS)
          </h4>
  
          <a [routerLink]="['/delivery-pricing/Team-as-a-Service']" class="btn btn-md btn-primary" id="team-service-view">Detailed View</a>
        </div>
      </div>
  
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/time_01.webp" alt="Time and material software development pricing">
        </div>
        <div class="overlays">
          <h4>
            Time and Material (T&M)
          </h4>
  
          <a [routerLink]="['/delivery-pricing/time-and-material']" class="btn btn-md btn-primary" id="time-material-view">Detailed View</a>
        </div>
      </div>
  
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/fulltime_01.webp" alt="Full time equivalent software development pricing">
        </div>
        <div class="overlays">
          <h4>
            Full Time Equivalent (FTE)
          </h4>
  
          <a [routerLink]="['/delivery-pricing/fulltime-equivalent']" class="btn btn-md btn-primary" id="full-time-view">Detailed View</a>
        </div>
      </div>
  
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/ffp_01.webp" alt="Firm fixed price software development pricing">
        </div>
        <div class="overlays">
          <h4>
            Firm Fixed Price (FFP)
          </h4>
  
          <a [routerLink]="['/delivery-pricing/firm-fixed-price']" class="btn btn-md btn-primary" id="firm-fixed-view">Detailed View</a>
        </div>
      </div>
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/agile_01.webp" alt="Agile offshore software development models in USA">
        </div>
        <div class="overlays">
          <h4>
            Agile Development Model
          </h4>
  
          <a [routerLink]="['/delivery-pricing/agile-development-model']" class="btn btn-md btn-primary" id="agie-development-view">Detailed View</a>
        </div>
      </div>
  
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/waterfall_01.webp" alt="Waterfall software development and design models in Newjersey,USA">
        </div>
        <div class="overlays">
          <h4>
            Waterfall Development Model
          </h4>
  
          <a [routerLink]="['/delivery-pricing/waterfall-development-model']" class="btn btn-md btn-primary" id="waterfall-development-view">Detailed View</a>
        </div>
      </div>
  
      <div class="homeGrid-box">
        <div class="pictures">
          <img src="../../assets/img/methodoly_01.webp" alt="">
        </div>
        <div class="overlays">
          <h4>
            Activate Methodology
          </h4>
          <a [routerLink]="['/delivery-pricing/activate-methodology']" class="btn btn-md btn-primary" id="activate-method-view">Detailed View</a>
        </div>
      </div>
  
    </div>
  </section>
  