<div class="innerHeader capabilities customSD">
  <div class="innerHeadings">
    <h3>Software </h3>
    <h1>Engineering  <span> Centre</span></h1>
  </div>
</div>
<app-cirlelinks></app-cirlelinks>
<div class="captop">
  <div class="picture">
    <img class="img-fluid" src="../../assets/img/se_center.webp" alt="software engineering technology company in New Jersey and washington DC, USA.">
  </div>
  <div class="texts">
    <div class="heading">
      <div class="redBg">
          <h2>Meet the emerging</h2>
      </div>
      <div class="darkBg">
          <h3>Business challenges tellingly!</h3>
      </div>
  </div>
    <p>
      JTSi has a strong network of industry professionals who can thrust our Software Engineering Centre on par with latest software technologies. Innovation is the essence we add to our process to ensure that software is developed that can actualize making tasks easy through automation. Our Strengths are the unique codes and skilful team who can accomplish the most difficult tasks. We have full-stack know-how to make the software engineering centre to advance on the right track.
    </p><p>
      We have a dedicated R&D team that pursues research on latest technologies in software engineering that ideally places the discovered findings in an ingenious and innovative way. Every now and then different types of software engineering technologies are emerging and we dedicatedly implement these software development technologies in the appropriate projects. Our areas of expertise lie in developing various types of software applications, AI interfaces and information systems for enterprises. 

    </p>
  </div>
</div>


<section class="grayBg commonPadding">

<div class="containers homeGrid">

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/sap_01.webp" alt="SAP Software Engineering support and technology consulting company in USA, UK , Canada and Europe">
    </div>
    <div class="overlays">
      <h4>
        SAP Support Services & FIORI Development
      </h4>
      <a [routerLink]="['/capabilities/SAP-support-services']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>  

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/csd_01.webp" alt="Top rated software development and engineering technology company in the US, Uk, Canada and Europe">
  </div>
  <div class="overlays">
    <h4>
      Custom Software Development
    </h4>
    <a [routerLink]="['/capabilities/custom-software-development']"class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/enterprise_01.webp" alt="Professional Software engineering  services in New Jersey, USA">
  </div>
  <div class="overlays">
    <h4>
      Enterprise Software Development
    </h4>
<a [routerLink]="['/capabilities/enterprise-software-development']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/product_01.webp" alt="One of the best IT engineering and technology Company in Virginia, USA">
  </div>
  <div class="overlays">
    <h4>
      Product Development Services
    </h4>
    <a [routerLink]="['/capabilities/product-development-services']"class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/webapp_01.webp" alt="Advanced software research engineering and development centre in Washington DC, USA">
  </div>
  <div class="overlays">
    <h4>
      Web Application Development
    </h4>
    <a [routerLink]="['/capabilities/web-application-development']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/mobile_02.webp" alt="Software engineering development company  in New Jersey and Washington DC, USA that uses latest software and mobile technologies">
  </div>
  <div class="overlays">
    <h4>
      Mobile Application Development
    </h4>
    <a [routerLink]="['/capabilities/mobile-application-development']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/cloud_01.webp" alt="Cloud application software is one of the best software engineering technology in UK and USA.">
  </div>
  <div class="overlays">
    <h4>
      Cloud Application Development
    </h4>
    <a [routerLink]="['/capabilities/cloud-application-development']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/api_01.webp" alt="API Integration development is a part of JTSi's Software engineering development centre in UK,US and Europe.">
  </div>
  <div class="overlays">
    <h4>
      API Integration
    </h4>

    <a [routerLink]="['/capabilities/API-integration']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

</div>
</section>