import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    var solidName = "solidBg";
var scrollvalue = 55;

window.onscroll = function(){
  if (window.scrollY >= scrollvalue || window.pageYOffset >= scrollvalue){
    document.getElementsByClassName("topHeader")[0].classList.add(solidName);
  } else {

    document.getElementsByClassName("topHeader")[0].classList.remove(solidName);
  }
};


    const menuBtn = document.querySelectorAll(".menuBtn");

menuBtn.forEach(button =>
  button.addEventListener("click", _ => {
    document.getElementById("navmenu").classList.toggle("showMenu");
  })
);

const closeMenu = document.querySelectorAll(".closeMenu");

closeMenu.forEach(span =>
  span.addEventListener("click", _ => {
    document.getElementById("navmenu").classList.remove("showMenu");
  })
);

  }

}
