

<section class="grayBg commonPadding">
    <div class="texttitle_two">
        <h2>Capabilities</h2>
    </div>
  <div class="containers homeGrid">
  
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/sap_01.webp" alt="SAP Software support and consulting company">
      </div>
      <div class="overlays">
        <h4>
          SAP Support Services & FIORI Development
        </h4>
        <a [routerLink]="['/capabilities/SAP-support-services']"  class="btn btn-md btn-primary" id="Sap-detailed-view">Detailed View</a>
      </div>
    </div>  
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/csd_01.webp" alt="Top rated software company in us,uk,canada and europe">
    </div>
    <div class="overlays">
      <h4>
        Custom Software Development
      </h4>
      <a [routerLink]="['/capabilities/custom-software-development']"class="btn btn-md btn-primary"id="custom-soft-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/enterprise_01.webp" alt="professional software services in Newjersey, USA">
    </div>
    <div class="overlays">
      <h4>
        Enterprise Software Development
      </h4>
  <a [routerLink]="['/capabilities/enterprise-software-development']" class="btn btn-md btn-primary" id="enterprise-soft-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/product_01.webp" alt="Leading IT development and product services Company in USA">
    </div>
    <div class="overlays">
      <h4>
        Product Development Services
      </h4>
      <a [routerLink]="['/capabilities/product-development-services']"class="btn btn-md btn-primary" id="product-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/webapp_01.webp" alt="software outsourcing development company">
    </div>
    <div class="overlays">
      <h4>
        Web Application Development
      </h4>
      <a [routerLink]="['/capabilities/web-application-development']" class="btn btn-md btn-primary" id="web-app-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/mobile_02.webp" alt="software application development company ">
    </div>
    <div class="overlays">
      <h4>
        Mobile Application Development
      </h4>
      <a [routerLink]="['/capabilities/mobile-application-development']" class="btn btn-md btn-primary" id="mobile-app-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/cloud_01.webp" alt="Cloud software application development company">
    </div>
    <div class="overlays">
      <h4>
        Cloud Application Development
      </h4>
      <a [routerLink]="['/capabilities/cloud-application-development']" class="btn btn-md btn-primary" id="cloud-app-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/api_01.webp" alt="API Integration development company">
    </div>
    <div class="overlays">
      <h4>
        API Integration
      </h4>
  
      <a [routerLink]="['/capabilities/API-integration']" class="btn btn-md btn-primary" id="app-integration-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/busy_consult_01.webp" alt="Offshore Business technology company">
    </div>
    <div class="overlays">
      <h4>
        Business Technology Consulting
      </h4>
      <a [routerLink]="['/capabilities/business-technology-consulting']" class="btn btn-md btn-primary" id="business-tech-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/migration_01.webp" alt="Software Migration services company">
    </div>
    <div class="overlays">
      <h4>
        Migration Services
      </h4>
  
      <a [routerLink]="['/capabilities/migration-services']" class="btn btn-md btn-primary" id="migration-services-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/maintanace_01.webp" alt="Software maintenance services company">
    </div>
    <div class="overlays">
      <h4>
        Maintenance Services
      </h4>
      <a [routerLink]="['/capabilities/maintenance-services']" class="btn btn-md btn-primary" id="maintenanace-services-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/qat_01.webp" alt="Software testing services company">
    </div>
    <div class="overlays">
      <h4>
        Application QAT Services
      </h4>
      <a [routerLink]="['/capabilities/application-QAT-services']" class="btn btn-md btn-primary" id="app-quat-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/impliment_01.webp" alt="Software implementation services company">
    </div>
    <div class="overlays">
      <h4>
        Implementation Services
      </h4>
      <a [routerLink]="['/capabilities/implementation-services']" class="btn btn-md btn-primary" id="implement-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/hr_01.webp" alt="Software professional services company">
    </div>
    <div class="overlays">
      <h4>
        Recruitment & HR Services
      </h4>
      <a [routerLink]="['/capabilities/recruitment-hr-services']" class="btn btn-md btn-primary" id="recruit-hr-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/accounting_01.webp" alt="Outsourced accounting services company">
    </div>
    <div class="overlays">
      <h4>
        Accounting Services
      </h4>
      <a [routerLink]="['/accounting-services']" class="btn btn-md btn-primary" id="accounting-view">Detailed View</a>
    </div>
  </div>
  
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/software_engine_01.webp" alt="Software engine development company">
    </div>
    <div class="overlays">
      <h4>
        Software Factory
      </h4>
  
      <a  [routerLink]="['/capabilities/software-factory']" class="btn btn-md btn-primary" id="soft-factory-view">Detailed View</a>
    </div>
  </div>
  
  
  </div>
  </section>