
  <header class="topHeader navbar">
      <div class="logo">
          <a [routerLink]="['']">
          <div class="mainlogo">
          <img class="iwhite" src="../../assets/img/JTSi-Logo.webp" alt="JTSi India">
          <img class="ired" src="../../assets/img/JTSiindia-Logo.svg" alt="JTSi India Logo">
          <div class="logotxt">
            <h5>Affordable | Quality | On-Time</h5>
        </div>
        </div>
        </a>
        </div>
        <div class="awardpoints">
            <ul>
              <li>
              <a [routerLink]="['/key-differentiators']" title="SOP/Work-Flow based">  
                <i class="icon ion-md-checkbox"></i> 
                ISO 9001-2015
              </a>
              </li>
              <li>
                <a [routerLink]="['/key-differentiators']" title="17 years of Reputation"> 
                  <i class="icon ion-md-checkbox"></i> 
                  19 Years Into Business
                </a>
              </li>
              <li>
                <a [routerLink]="['/key-differentiators']" title="3 Tier Facility Security Control"> 
                  <i class="icon ion-md-checkbox"></i> 
                  3 Tier Security
                </a>
              </li>
              <li>
                <a [routerLink]="['/key-differentiators']" title=" Achievers Award"> 
                  <i class="icon ion-md-checkbox"></i> 
                 Achievers Award
                </a>
              </li>
            </ul>
          </div>
          <div class="menubar">
              <div class="contactnumbers">
              <ul>
                  <li>
                    <a href="tel:+914844070368" id="call-india-top">
                      <svg>
                        <use xlink:href="../../assets/img/sprite.svg#indiaflag"></use>
                      </svg>
                      +91 484 407-0368
                    </a>
                  </li>
                  <li class="showmobile">
                    <a href="tel:12024645874" id="call-usa-top">
                      <svg>
                        <use xlink:href="../../assets/img/sprite.svg#usflag"></use>
                      </svg>
                      +1(202)464-JTSi(5874)
                    </a>
                  </li>
                  <!-- <li>
                    <a class="betatag yellowBg"  href="#">
                      Beta Version
                    </a>
                  </li> -->
                </ul>
              </div>
    <nav class="topNav">
      <ul class="topNav-menu" id="navmenu">
        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/why-JTSi']" id="why-jtsi-top-menu">Why JTSi</a>
          <div class="dropDownBox">
            <ul>
              <li><a [routerLink]="['/why-JTSi/who-we-are']">Who We Are</a></li>
              <!--<li><a [routerLink]="['/why-JTSi/leadership']">Leadership Team</a></li>-->
              <li><a [routerLink]="['/why-JTSi/value-proposition']">Value Proposition</a></li>
              <li><a [routerLink]="['/why-JTSi/current-news']">Current News</a></li>
              <li><a [routerLink]="['/why-JTSi/partner-with-us']">Partner with Us</a></li>
              <!-- <li><a [routerLink]="['/why-JTSi/success-stories']"></a></li> -->
              <!-- <li><a [routerLink]="['/why-JTSi/testimonials']">Client Testimonials</a></li> -->
              <!-- <li><a [routerLink]="['//why-JTSi/employeetestimonials']">Employee Testimonials</a></li> -->
              <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
            </ul>
          </div>
        </li>

        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/capabilities']" id="capabiities-top-menu">Capabilities</a>
          <div class="dropDownBox largeColumn">
            <div class="division">
              <h4><a [routerLink]="['/capabilities/software-engineering-center']">Software Engineering Center</a></h4>
              <ul>
                <li><a [routerLink]="['/capabilities/custom-software-development']"> Custom Software Development</a></li>
                <li><a [routerLink]="['/capabilities/enterprise-software-development']"> Enterprise Software Development</a></li>
                <li><a [routerLink]="['/capabilities/product-development-services']"> Product Development Services</a></li>
                <li><a [routerLink]="['/capabilities/web-application-development']"> Web Application Development</a></li>
                <li><a [routerLink]="['/capabilities/mobile-application-development']"> Mobile Application Development</a></li>
                <li><a [routerLink]="['/capabilities/cloud-application-development']"> Cloud Application Development</a></li>
                <li><a [routerLink]="['/capabilities/SAP-support-services']"> SAP Support Services</a></li>
                <li><a [routerLink]="['/capabilities/API-integration']"> API Integration</a></li>
                <li><a [routerLink]="['/capabilities/bigdata-analytics']"> Bigdata/Analytics</a></li>
              </ul>
            </div>

            <div class="division">
              <h4><a [routerLink]="['/capabilities/it-consulting-services']">IT Consulting Services</a></h4>
              <ul>
                <li><a [routerLink]="['/capabilities/business-technology-consulting']">Business Technology Consulting</a></li>
                <li><a [routerLink]="['/capabilities/migration-services']">Migration Services</a></li>
                <li><a [routerLink]="['/capabilities/maintenance-services']">Maintenance Services</a></li>
                <li><a [routerLink]="['/capabilities/application-QAT-services']">Application QAT Services</a></li>
                <li><a [routerLink]="['/capabilities/implementation-services']">Implementation Services</a></li>
              </ul>
            </div>

            <div class="division">
              <h4><a [routerLink]="['/capabilities/professional-services']">Professional Services</a></h4>
              <ul>
                <li><a [routerLink]="['/capabilities/recruitment-hr-services']">Recruitment &#38; HR Services</a></li>
                <li><a [routerLink]="['/accounting-services']">Accounting Services</a></li>
              </ul>
            </div>

            <div class="division">
              <h4><a [routerLink]="['/capabilities/software-factory']">Software Factory </a></h4>
              <ul>
                <li><a [routerLink]="['/capabilities/software-engine']">Software Engine</a></li>
              </ul>
            </div>

          </div>
        </li>

        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a  [routerLink]="['/delivery-pricing']" id ="delivery-pricing-top-menu">Delivery/Pricing</a>
          <div class="dropDownBox threeColumn">
            <div class="division">
              <h4>Model</h4>
              <ul>
                <li><a [routerLink]="['/delivery-pricing/hire-resources']">Hire Resources</a></li>
                <li><a [routerLink]="['/delivery-pricing/Team-as-a-Service']">Team as a Service (TaaS)</a></li>
              </ul>
            </div>

            <div class="division">
              <h4>Pricing</h4>
              <ul>
                <li><a [routerLink]="['/delivery-pricing/time-and-material']">Time and Material (T&M)</a></li>
                <li><a [routerLink]="['/delivery-pricing/fulltime-equivalent']">Full Time Equivalent (FTE)</a></li>
                <li><a [routerLink]="['/delivery-pricing/firm-fixed-price']">Firm Fixed Price (FFP)</a></li>
              </ul>
            </div>

            <div class="division">
              <h4>Development Methodology</h4>
              <ul>
                <li><a [routerLink]="['/delivery-pricing/agile-development-model']">Agile Development Model </a></li>
                <li><a [routerLink]="['/delivery-pricing/waterfall-development-model']">Waterfall Development Model</a></li>
                <li><a [routerLink]="['/delivery-pricing/activate-methodology']">Activate Methodology</a></li>
              </ul>
            </div>
          </div>
        </li>

        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/solutions']" id="solutions-top-menu">Solutions</a>
          <div class="dropDownBox">
            <ul>
              <li><a [routerLink]="['/solutions/qualcheckers']">QualCheckers</a></li>
              <li><a [routerLink]="['/solutions/OfficeGaps']">OfficeGaps</a></li>
              <!-- <li><a [routerLink]="['/solutions/surveyshield']">SurveyShield</a></li> -->
              <li><a [routerLink]="['/solutions/MRO-for-aviation']">MRO for Aviation</a></li>
              <!--<li><a [routerLink]="['/solutions/SAPiM']">SAPiM V1.0</a></li>-->
            </ul>
          </div>
        </li>
        
        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/technologies']" id="techstacks-top-menu">Techstacks</a>
          <div class="dropDownBox largeColumn">
              <div class="division">
                  <h4><a [routerLink]="['/technologies/sap']">SAP</a></h4>
                  <ul>
                    <li><a [routerLink]="['/technologies/sap']">	FIORI / Ui5</a></li>    
                    <li><a [routerLink]="['/technologies/sap']">	ABAP</a></li>    
                    <li><a [routerLink]="['/technologies/sap']">	HANA</a></li>    
                    
                </ul>
  
                <h4><a [routerLink]="['/technologies/mobile']">Mobile</a></h4>
                  <ul>
                    <li><a [routerLink]="['/technologies/mobile']">	Android</a></li>
                    <li><a [routerLink]="['/technologies/mobile']">	iOS</a></li>
                    <li><a [routerLink]="['/technologies/mobile']">	Xamarin</a></li>
                    <li><a [routerLink]="['/technologies/mobile']">	React</a></li>
                    <li><a [routerLink]="['/technologies/mobile']">	Native</a></li>
                    <li><a [routerLink]="['/technologies/mobile']">	Ionic</a></li>
                    <li><a [routerLink]="['/technologies/mobile']">	Flutter</a></li>
                    
                  </ul>
                </div>
                <div class="division">
                    <h4><a [routerLink]="['/technologies/microsoft']">Microsoft</a></h4>
                    <ul>
                      <li><a [routerLink]="['/technologies/microsoft']">	Microsoft Power BI</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	C, C++</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	.NET</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	.NET Core </a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	.NET framework</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	MVC Framework</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	Entity Framework</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	MS SQL</a></li>
                      <li><a [routerLink]="['/technologies/microsoft']">	Backbone JS</a></li>
                      
                  </ul>               
                  </div>
                  <div class="division">
     
                      <h4><a [routerLink]="['/technologies/java']">Java</a></h4>
                      <ul>
                        <li><a [routerLink]="['/technologies/java']">	Core Java</a></li> 
        <li><a [routerLink]="['/technologies/java']">	Java EE</a></li> 
        <li><a [routerLink]="['/technologies/java']">	Java FX</a></li> 
        <li><a [routerLink]="['/technologies/java']">	J2ME</a></li> 
        <li><a [routerLink]="['/technologies/java']">	Hibernate</a></li> 
        <li><a [routerLink]="['/technologies/java']">	Spring</a></li> 
        <li><a [routerLink]="['/technologies/java']">	Struts</a></li> 
        <li><a [routerLink]="['/technologies/java']">	JBoss</a></li> 
         
                    </ul> 
                    </div>
                    <div class="division">
                        <h4><a [routerLink]="['/technologies/opensource']">Open Source</a></h4>
                        <ul>
                          <li><a [routerLink]="['/technologies/opensource']">	Angular JS</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Vue JS</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	React JS</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Python</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	RubyOnRails</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Node JS </a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Scala </a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Golang</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Django</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Laravel</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Spring</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Code Igniter</a></li> 
          <li><a [routerLink]="['/technologies/opensource']">	Express JS</a></li> 
          
                      </ul>               
                    </div>
          
          </div>
        </li>
        <li routerLinkActive="active"><a [routerLink]="['/careers']" id="careers-top-menu">Careers</a></li>
        <div class="closeMenu">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#closeIcon"></use>
          </svg>
        </div>
      </ul>
      <div class="endnav">
      <a href="https://og.jtsiindia.com/userlogin" target="_blank" class="userLogin" id="Login-top-menu">
          Login
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#userlog"></use>
          </svg>
        </a>
        <!-- <div class="searchBox">
          <input type="Search" placeholder="Search Here">
          <button class="searchBtn">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#search"></use>
            </svg>
          </button>
        </div> -->
        <button id="mobilemenubtn" class="menuBtn" aria-label="mobile-menu-button">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#threeLines"></use>
          </svg>
        </button>
      </div>
    </nav>
    </div>
    <div class="gstitab">Formerly known as GSTi Technologies India Pvt. Ltd.</div>
  </header>
  

