<div class="innerHeader success">
    <div class="innerHeadings">
  <h1>Success  <span>Stories</span></h1>
  </div>
  </div>
  
  <div class="commonPadding">
  <div class="advantages">
  
      <div class="advantagePoints">
      <div class="pics">
          <img src="../../assets/img/success_01.webp" alt="" class="img-fluid">
      </div>
  
      <div class="textcontent">
  
          <h4>Providing Support to a Manufacturing Company in Dallas TX for Resolving their SAP HR Issues</h4>
          <p>A Manufacturing Company in Dallas TX had SAP HR related issues. The company started encountering this problem after undergoing a 5 year SAP implementation</p>
          <h4>SAP Supply Chain project Novartis (Vaccines & Diagnostics)</h4>
          <p>The task in JTSi’s hand was to fine-tune a newly built SAP supply chain facility for Novartis (Vaccines & Diagnostics). With our effort, the client’s vaccine production increased by 90% on every batch of vaccines being run and thus increasing the number of vaccines available for sale.</p>
          <h4>SAP Finance and Logistic Implementation – City of Fairfax</h4>
          <p>JTSi was responsible for the SAP FI (AR and AP), HCM, SD technical development for City of Fairfax. Our task was to streamline the day-to-day operations of the county government and the public school system.</p>
          <h4>Implementation of SAP HR Time Entry and Pay for State of California</h4>
          <p>JTSi successfully implemented SAP HR Time Entry and Pay for the State of California. The work included the development of 250 Objects in about 8 months. The objects included enhancements, interfaces, reports, conversion programs, etc.</p>
          <h4>SAP Implementation for a Leading Independent Food Service Distributor</h4>
          <p>Implementing SAP logistics and Finance modules to meet the current business trends and plan for future expansion with optimized resource utilization.</p>
      <h4>Creating CRM for AeroTech Flight Safety Solutions</h4>
      <p>JTSi was responsible for creating CRM software called G-CRM for AeroTech Flight Safety Solutions.</p>
      <h4>SAP ECC for Dr. Pepper Snapple Group</h4>
      <p>JTSi successfully completed SAP ECC for Dr. Pepper Snapple Group. This was part 1 of a larger project for DPSG. Part 1 includes: all of SAP EAM’s business content activated and ready for use using SAP BEx. Part 1 includes: detailed documentation of all relevant metadata content such as info-providers, info-objects, transformation rules, queries, etc.</p>
        </div>
  </div>
  </div>
  </div>

  <app-cirlelinks></app-cirlelinks>