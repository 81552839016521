import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-hire-resources',
  templateUrl: './hire-resources.component.html',
  styleUrls: ['./hire-resources.component.scss']
})
export class HireResourcesComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Hiring Professional, Talented and Dedicated Human resources |USA| JTSi Technologies.');
    this.meta.addTag({name:'description', content:'JTSi is a fast-growing IT staffing and recruitment firm whose headquartered is in New Jersey, USA. We are hiring dedicated, talented, and professional resources for both global, local and niche clients. '});
    this.meta.addTag({name:'keywords', content:'Hire dedicated resources, Hire resource staffing, hire resource management, IT Staff segmentation'});
 
    this.meta.addTags([

      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:' Hiring Professional, Talented and Dedicated Human resources |USA| JTSi Technologies. '},
      { property:'og:description', content:'JTSi is a fast-growing IT staffing and recruitment firm whose headquartered is in New Jersey, USA. We are hiring dedicated, talented, and professional resources for both global, local and niche clients.'},
      { property:'og:url', content:'https://www.jtsiindia.com/delivery-pricing/hire-resources'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Hire-Resources.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Hiring Professional, Talented and Dedicated Human resources |USA| JTSi Technologies.'},
      { name:'twitter:description', content:'JTSi is a fast-growing IT staffing and recruitment firm whose headquartered is in New Jersey, USA. We are hiring dedicated, talented, and professional resources for both global, local and niche clients. '},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Hire-Resources.webp'},
    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  }

}
