import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-api-integration',
  templateUrl: './api-integration.component.html',
  styleUrls: ['./api-integration.component.scss']
})
export class ApiIntegrationComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
      this.titleService.setTitle('Web API to API Integration development company in USA | New Jersy| JTSi Technologies.');
      this.meta.addTags([
      {name:'description', content:'We are a leading API Integration development company that has a team of API developers to develop API Programming solutions and offers third party api integration and web API to API integration.'},
      {name:'keywords', content:'Web API Integration, API Development company, API to API Integration'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:' "Web API to API Integration development company in USA | New Jersy| JTSi Technologies.'},
      {property :'og:description', content:'"We are a leading API Integration development company that has a team of API developers to develop API Programming solutions and offers third party api integration and web API to API integration.'},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/API-integration' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/API-Integration.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Web API to API Integration development company in USA | New Jersy| JTSi Technologies.'},
      {name:'twitter:description', content:'We are a leading API Integration development company that has a team of API developers to develop API Programming solutions and offers third party api integration and web API to API integration.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/API-Integration.webp'}
      ]);
    }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
