import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
//import { WhyjtsiComponent } from './whyjtsi/whyjtsi/whyjtsi.component';
import { WhoweareComponent } from './whyjtsi/whoweare/whoweare.component';
import { LeadershipComponent } from './whyjtsi/leadership/leadership.component';
import { ValuepropositionComponent } from './whyjtsi/valueproposition/valueproposition.component';
import { CurrentnewsComponent } from './whyjtsi/currentnews/currentnews.component';
import { PartnerwithusComponent } from './whyjtsi/partnerwithus/partnerwithus.component';
import { SuccessStoriesComponent } from './whyjtsi/success-stories/success-stories.component';
import { TestimonialsComponent } from './whyjtsi/testimonials/testimonials.component';
//import { DeliveryPricingComponent } from './delivery/delivery-pricing/delivery-pricing.component';
import { HireResourcesComponent } from './delivery/hire-resources/hire-resources.component';
import { TaaSComponent } from './delivery/taa-s/taa-s.component';
import { TimeAndMaterialComponent } from './delivery/time-and-material/time-and-material.component';
import { FulltimeEquivalentComponent } from './delivery/fulltime-equivalent/fulltime-equivalent.component';
import { FirmFixedPriceComponent } from './delivery/firm-fixed-price/firm-fixed-price.component';
import { AgileDevelopmentComponent } from './delivery/agile-development/agile-development.component';
import { WaterfallDevelopmentComponent } from './delivery/waterfall-development/waterfall-development.component';
//import { TechnologiesComponent } from './technologies/technologies/technologies.component';
import { MicrosoftComponent } from './technologies/microsoft/microsoft.component';
import { JavaComponent } from './technologies/java/java.component';
import { SapComponent } from './technologies/sap/sap.component';
import { OpensourceComponent } from './technologies/opensource/opensource.component';
import { MobileComponent } from './technologies/mobile/mobile.component';
//import { SolutionsComponent } from './solutions/solutions/solutions.component';
import { OfficegapsComponent } from './solutions/officegaps/officegaps.component';
import { SurveyshieldComponent } from './solutions/surveyshield/surveyshield.component';
import { MroComponent } from './solutions/mro/mro.component';
import { SapimComponent } from './solutions/sapim/sapim.component';
//import { CapabilitiesComponent } from './capabilities/capabilities/capabilities.component';
import { CustomSoftwareComponent } from './capabilities/custom-software/custom-software.component';
import { EnterpriseSoftwareComponent } from './capabilities/enterprise-software/enterprise-software.component';
import { ProductDevelopmentComponent } from './capabilities/product-development/product-development.component';
import { WebApplicationComponent } from './capabilities/web-application/web-application.component';
import { MobileAppComponent } from './capabilities/mobile-app/mobile-app.component';
import { CloudAppComponent } from './capabilities/cloud-app/cloud-app.component';
import { SapSupportComponent } from './capabilities/sap-support/sap-support.component';
import { ApiIntegrationComponent } from './capabilities/api-integration/api-integration.component';
import { BusinessTechnologyComponent } from './capabilities/business-technology/business-technology.component';
import { MigrationComponent } from './capabilities/migration/migration.component';
import { MaintenanceComponent } from './capabilities/maintenance/maintenance.component';
import { ApplicationQatComponent } from './capabilities/application-qat/application-qat.component';
import { ImplementationComponent } from './capabilities/implementation/implementation.component';
import { RecruitmentComponent } from './capabilities/recruitment/recruitment.component';
import { AccountingComponent } from './capabilities/accounting/accounting.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SoftwareCenterComponent } from './capabilities/software-center/software-center.component';
import { ItConsultingComponent } from './capabilities/it-consulting/it-consulting.component';
import { ProffessionalServiceComponent } from './capabilities/proffessional-service/proffessional-service.component';
import { SoftwareFactoryComponent } from './capabilities/software-factory/software-factory.component';
import { EmployeetestimonialsComponent } from './whyjtsi/employeetestimonials/employeetestimonials.component';
import { BigdataAnalyticsComponent } from './capabilities/bigdata-analytics/bigdata-analytics.component';
import { ActivatemethodologyComponent } from './delivery/activatemethodology/activatemethodology.component';  
import { PagethumbnailsComponent } from './pagethumbnails/pagethumbnails/pagethumbnails.component';
import { WhyjtsigridComponent } from './pagethumbnails/whyjtsigrid/whyjtsigrid.component';
//import { CapabilitiesgridComponent } from './pagethumbnails/capabilitiesgrid/capabilitiesgrid.component';
//import { DeliverypricinggridComponent } from './pagethumbnails/deliverypricinggrid/deliverypricinggrid.component';
import { SuccessstoriesgridComponent } from './pagethumbnails/successstoriesgrid/successstoriesgrid.component';
import { TechnologiesgridComponent } from './pagethumbnails/technologiesgrid/technologiesgrid.component';
import { PricingpageComponent } from './pagethumbnails/pricingpage/pricingpage.component';
import { KeyDefferentiatorsComponent } from './key-defferentiators/key-defferentiators.component';
import { SoftwareEngineComponent } from './capabilities/software-engine/software-engine.component';
import { LayoutComponent } from './layout/layout.component'; 
import { CapabilitiesgridComponent } from './pagethumbnails/capabilitiesgrid/capabilitiesgrid.component';
import { DeliverypricinggridComponent } from './pagethumbnails/deliverypricinggrid/deliverypricinggrid.component';
import { DecorationsComponent } from './decorations/decorations.component';
import { AccountingservicenewComponent } from './accountingservicenew/accountingservicenew.component';
import { EmptylayoutsComponent } from './emptylayouts/emptylayouts.component';
import { QuickcontactComponent } from './quickcontact/quickcontact.component';
import { QualcheckersComponent } from './qualcheckers/qualcheckers.component';

const routes: Routes = [

 {path:'dec',component:DecorationsComponent} ,
{path: '', 
component: LayoutComponent,
children:[
  {path:'', component:HomeComponent}
]
},


{path: 'why-JTSi', component:LayoutComponent,
  children:[
    {path:'', pathMatch:'full', redirectTo:'why-JTSi'},
    {path: '', component:WhyjtsigridComponent},
    {path: 'who-we-are', component: WhoweareComponent},
    {path: 'leadership', component: LeadershipComponent},
    {path: 'value-proposition', component: ValuepropositionComponent},
    {path: 'current-news', component: CurrentnewsComponent},
    {path: 'partner-with-us', component: PartnerwithusComponent},
    {path: 'success-stories', component: SuccessStoriesComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'employeetestimonials', component: EmployeetestimonialsComponent}
    
  ]},
  
  {path: 'delivery-pricing', component:LayoutComponent,
  children:[
    {path:'', pathMatch:'full', redirectTo:'delivery-pricing'},
    {path: '', component:PricingpageComponent},
    {path: 'hire-resources', component: HireResourcesComponent},
    {path: 'Team-as-a-Service', component: TaaSComponent},
    {path: 'time-and-material', component: TimeAndMaterialComponent},
    {path: 'fulltime-equivalent', component: FulltimeEquivalentComponent},
    {path: 'firm-fixed-price', component: FirmFixedPriceComponent},
    {path: 'agile-development-model', component: AgileDevelopmentComponent},
    {path: 'waterfall-development-model', component: WaterfallDevelopmentComponent},
    {path: 'activate-methodology', component: ActivatemethodologyComponent}
  ]},

  {path: 'technologies', component:LayoutComponent,
  children:[
    {path:'', pathMatch:'full', redirectTo:'technologies'},
    {path: '', component: TechnologiesgridComponent},
    {path: 'microsoft', component: MicrosoftComponent},
    {path: 'java', component: JavaComponent},
    {path: 'sap', component: SapComponent},
    {path: 'opensource', component: OpensourceComponent},
    {path: 'mobile', component: MobileComponent}
  ]},

  {path: 'solutions', component:LayoutComponent,
  children:[
    {path:'', pathMatch:'full', redirectTo:'solutions'},
    {path: '', component:SuccessstoriesgridComponent},
    {path: 'OfficeGaps', component: OfficegapsComponent},
    {path: 'surveyshield', component: SurveyshieldComponent},
    {path: 'MRO-for-aviation', component: MroComponent},
    {path: 'SAPiM', component: SapimComponent},
    {path: 'qualcheckers', component: QualcheckersComponent},
  ]},

  {path: 'capabilities', component:LayoutComponent,
  children:[
    {path:'', pathMatch:'full', redirectTo:'capabilities'},
    {path: '', component:PagethumbnailsComponent},
    
    {path: 'custom-software-development', component: CustomSoftwareComponent},
    {path: 'enterprise-software-development', component: EnterpriseSoftwareComponent},
    {path: 'product-development-services', component: ProductDevelopmentComponent},
    {path: 'web-application-development', component: WebApplicationComponent},
    {path: 'mobile-application-development', component: MobileAppComponent},
    {path: 'cloud-application-development', component: CloudAppComponent},
    {path: 'SAP-support-services', component: SapSupportComponent},
    {path: 'API-integration', component: ApiIntegrationComponent},
    {path: 'business-technology-consulting', component: BusinessTechnologyComponent},
    {path: 'migration-services', component: MigrationComponent},
    {path: 'maintenance-services', component: MaintenanceComponent},
    {path: 'application-QAT-services', component: ApplicationQatComponent},
    {path: 'implementation-services', component: ImplementationComponent},
    {path: 'recruitment-hr-services', component: RecruitmentComponent},
    // {path: 'accounting-services', component: AccountingComponent},
    {path: 'software-engineering-center', component: SoftwareCenterComponent},
    {path: 'it-consulting-services', component: ItConsultingComponent},
    {path: 'professional-services', component: ProffessionalServiceComponent},
    {path: 'software-factory', component: SoftwareFactoryComponent},
    {path: 'bigdata-analytics', component: BigdataAnalyticsComponent},
    {path: 'software-engine', component: SoftwareEngineComponent}
  ]}, 
  // {path: 'thumbnails', component:PagethumbnailsComponent,
  // children:[    
  //   {path:'', pathMatch:'full', redirectTo:'capabilities'},
  //   //{path: 'home', component:CapabilitiesgridComponent},
  //   // {path:'', redirectTo:'/pagethumbnails', pathMatch:'full'},
  //   //{path: 'pagethumbnails', component:PagethumbnailsComponent},
  //   {path: 'why-JTSi', component: WhyjtsigridComponent},
  //   {path: 'capabilities', component: CapabilitiesgridComponent}, 
  //   {path: 'delivery-pricing', component: DeliverypricinggridComponent},
  //   {path: 'solutions', component: SuccessstoriesgridComponent},
  //   {path: 'technologies', component: TechnologiesgridComponent},
    
  // ]}, 

  {path:'', component:LayoutComponent,
  children:[
    {path:'', redirectTo:'', pathMatch:'full'},
    {path: 'careers', component: CareersComponent},
    {path: 'contact-us', component: ContactComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'privacy-policy', component: PrivacyComponent},
    {path: 'disclaimer', component: DisclaimerComponent},
    {path: 'key-differentiators', component: KeyDefferentiatorsComponent},
    {path: 'IT-staffing-solutions', component: CapabilitiesgridComponent},
    
  ]},

  {path:'', component:EmptylayoutsComponent,
  children:[
    {path:'', redirectTo:'', pathMatch:'full'},
    {path: 'accounting-services', component: AccountingservicenewComponent},
    {path: 'quickcontact', component: QuickcontactComponent}
  ]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration:'enabled',
      //useHash: true,
     onSameUrlNavigation: 'reload',
     anchorScrolling: 'enabled',
      enableTracing: false,
      scrollOffset: [0, 100]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

