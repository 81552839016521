import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { TalktousService } from '../shared/talktous.service';
import { CommonfunctionsService } from '../utils/commonfunctions.service';
import { DOCUMENT } from '@angular/common';
//import { from } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(

    private titleService: Title,
    private meta: Meta,private commonfunctions : CommonfunctionsService,
    public _renderer2:Renderer2,@Inject(DOCUMENT) public _document:Document

  ) { 
      this.titleService.setTitle('Software Development | IT Offshore Services Company | USA | VIRGINIA ');
      this.meta.addTags([
        {name:'description', content:'JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.'},
        {name:'keywords', content:'Software Development company, IT Offshore development company, Professional business consulting'},
	      {name :'description', content:'website',property:'og:type'},
        {property :'og:title', content:'Software Development | IT Offshore Services Company | USA | VIRGINIA'},
        {property :'og:description', content:'JTSi technologies is a software development company based in Virginia,United States  that offers offshore software and IT development services as well as professional business consulting'
        },
        {property:'og:url',content:'https://www.jtsiindia.com/' },
        {property:'og:image', content:'https://www.jtsiindia.com/assets/img/slide_01.webp' } ,                                                         
        {property:'og:site_name', content:'JTSi Technologies India'},
        {property:'article:modified_time', content:'fetch current time'},
        {name:'twitter:card', content:'summary_large_image'},
        {name:'twitter:site', content:'@Jtsiindia'},
        {name:'twitter:creator', content:'@Jtsiindia'},
        {name:'twitter:title ',content:'Software Development | IT Offshore Services Company | USA | VIRGINIA'},
        {name:'twitter:description', content:'JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.'},
        {name:'twitter:image', content:'https//www.jtsiindia.com/assets/img/slide_01.webp'}
        ]);
  }

  displayModal = "none"

  ngOnInit(): void {

//onload
    // const modal = document.getElementById('eventModal');
    // window.addEventListener('load', () => {
    //   if (modal) {
    //     this.displayModal = 'block';
    //   }
    // })
    // window.addEventListener('click', (event) => {
    //   if (event.target === modal) {
    //     this.displayModal = 'none';
    //   }
    // })

    // Get the element with id="defaultOpen" and click on it
            document.getElementById("opened").click();
            this.commonfunctions.CreateCanonicalURL();
        
            
// $(window).load(function(){
//   $('#testmodal').modal('show')
// });
          // let script1=this._renderer2.createElement('script');
          // script1.type=`application/Id+json`;
          // script1.text=`{
            
          //     "@context": "http://schema.org/",
          //     "@type": "WebSite",
          //     "name": "JTSi Technologies India",
          //     "url": " https://www.jtsiindia.com/",
          //     "default":"https://www.jtsiindia.com"
          //     }`
          //     this._renderer2.appendChild(this._document.body,script1)

          //     let script2=this._renderer2.createElement('script');
          //     script2.type=`application/Id+json`;
          //     script2.text=`{
          //       "@context": "http://schema.org/",
          //       "@type": "Corporation",
          //       "name": "JTSi Technologies India Pvt.Ltd",
          //       "url": "https://www.jtsiindia.com",
          //       "image": "https://www.jtsiindia.com/assets/img/JTSiindia-Logo.svg",
               
          //       "contactPoint": [
          //         {
          //           "@type": "ContactPoint",
          //           "telephone": "+9-148-440-7036",
          //           "contactType": "Customer service",
          //           "contactOption": [
          //             "Toll free",
          //             "Hearing impaired support"
          //           ],
          //           "areaServed": "IN",
          //           "availableLanguage": "English"
          //         },
          //         {
          //           "@type": "ContactPoint",
          //           "telephone": "+1-202-464-5874",
          //           "contactType": "Technical support",
          //           "contactOption": "Hearing impaired support",
          //           "areaServed": "US",
          //           "availableLanguage": "English"
          //         }
          //       ],
          //       "sameAs": [
          //         "https://www.facebook.com/jtsitechnologies",
          //         "https://www.linkedin.com/company/jtsi-technologies-india",
          //         "https://twitter.com/Jtsiindia",
          //         "https://www.instagram.com/jtsiindia/",
          //         "https://in.pinterest.com/jtsitechnologiesindia"
          //       ]
          //     }`
          //     this._renderer2.appendChild(this._document.body,script2)
          //     let script3=this._renderer2.createElement('script');
          //     script3.type=`application/Id+json`;
          //     script3.text=`{
          //       "@context": "http://schema.org/",
          //       "@type": "Organization",
          //       "name": "JTSi Technologies India",
          //       "address": {
          //         "@type": "PostalAddress",
          //         "streetAddress": "11490 Commerce Park Dr., 4th Floor,Reston, Virginia, USA",
          //         "addressLocality": "Reston",
          //         "addressRegion": "Virginia",
          //         "postalCode": "20191",
          //         "telephone": "+1 (703)949-9100"
          //       }
          //     }`
          //     this._renderer2.appendChild(this._document.body,script3)
          //     {

          //     let script4=this._renderer2.createElement('script');
          //     script4.type=`application/Id+json`;
          //     script4.text=`{
          //       "@context": "http://www.schema.org",
          //       "@type": "Organization",
          //       "name": "JTSi Technologies India",
          //       "url": "https://www.jtsiindia.com/",
          //       "logo": "https://www.jtsiindia.com/assets/img/JTSi-Logo.svg",
          //       "image": "https://www.jtsiindia.com/assets/img/slide_01.webp",
          //       "description": "JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.",
          //       "address": {
          //         "@type": "PostalAddress",
          //         "streetAddress": "Third Floor, Thapasya Building, Infopark, Kakkanad, Kochi – 682042, Kerala, India.",
          //         "postOfficeBoxNumber": "Infopark",
          //         "addressLocality": "Kochi",
          //         "addressRegion": "Kerala",
          //         "postalCode": "682042",
          //         "addressCountry": "India"
          //       },
          //       "contactPoint": {
          //         "@type": "ContactPoint",
          //         "telephone": "+91 484 407-0368",
          //         "contactType": "Local"
          //       }
          //     }`
              
          //    this._renderer2.appendChild(this._document.body,script4)
          
          
          
          
          }
          // closeModal(): void {
          //   this.displayModal = "none"
          // }
                  
          priceMethod(evt:any, cityName:any) {
            var i, tabcontent, tablinks;
            
            tabcontent = document.getElementsByClassName("pricingtab");
            for (i = 0; i < tabcontent.length; i++) {
              tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tabitem");
            for (i = 0; i < tablinks.length; i++) {
              tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(cityName).style.display = "block";
            evt.currentTarget.className += " active";
          }
        }
      
