
<div class="innerHeader capabilities Capabilities_header">
    <div class="innerHeadings">
      <!-- <h4>Capabilities</h4> -->
      <!-- <h3>Software </h3> -->
      <h1>Capabilities</h1>
    </div>
  </div>
  
  <app-cirlelinks></app-cirlelinks>
  <!-- <app-capabilitiesgrid></app-capabilitiesgrid> -->
  <app-capabilities-item></app-capabilities-item>
 