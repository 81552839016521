import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from '../utils/commonfunctions.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService

  ) { 
    this.titleService.setTitle('Privacy Policy | JTSi Technologies. ');
    
    this.meta.addTags([
      {name:'description', content:'JTSi ensures the data protection policies with collection and usage of data, data security, updates and amendments to the privacy policy.'},
    { property:'og:locale',content:'en_US'},
    { property:'og:type', content:'website'},
    { property:'og:title', content:'Privacy Policy | JTSi Technologies.'},
    { property:'og:description', content:'JTSi ensures the data protection policies with collection and usage of data, data security, updates and amendments to the privacy policy.'},
    { property:'og:url', content:'https://www.jtsiindia.com/privacy-policy'},
    { property:'og:image', content:'https://www.jtsiindia.com/assets/img/privacy-policy.webp'},                                                           
    { property:'og:site_name', content:'JTSi Technologies India'},
    { property:'article:modified_time', content:'fetch current time'},
  
    { name:'twitter:card', content:'summary_large_image'},
    { name:'twitter:site', content:'@Jtsiindia'},
    { name:'twitter:creator', content:'@Jtsiindia'},
    { name:'twitter:title', content:'Privacy Policy | JTSi Technologies.'},
    { name:'twitter:description', content:'JTSi ensures the data protection policies with collection and usage of data, data security, updates and amendments to the privacy policy.'},
    { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/privacy-policy.webp'},
    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  }

}
