import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {  ToastrModule} from "ngx-toastr";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
import { CirlelinksComponent } from './cirlelinks/cirlelinks.component';
import { WhyjtsiComponent } from './whyjtsi/whyjtsi/whyjtsi.component';
import { WhoweareComponent } from './whyjtsi/whoweare/whoweare.component';
import { LeadershipComponent } from './whyjtsi/leadership/leadership.component';
import { ValuepropositionComponent } from './whyjtsi/valueproposition/valueproposition.component';
import { CurrentnewsComponent } from './whyjtsi/currentnews/currentnews.component';
import { PartnerwithusComponent } from './whyjtsi/partnerwithus/partnerwithus.component';
import { SuccessStoriesComponent } from './whyjtsi/success-stories/success-stories.component';
import { TestimonialsComponent } from './whyjtsi/testimonials/testimonials.component';
import { HireResourcesComponent } from './delivery/hire-resources/hire-resources.component';
import { TaaSComponent } from './delivery/taa-s/taa-s.component';
import { TimeAndMaterialComponent } from './delivery/time-and-material/time-and-material.component';
import { FulltimeEquivalentComponent } from './delivery/fulltime-equivalent/fulltime-equivalent.component';
import { FirmFixedPriceComponent } from './delivery/firm-fixed-price/firm-fixed-price.component';
import { AgileDevelopmentComponent } from './delivery/agile-development/agile-development.component';
import { WaterfallDevelopmentComponent } from './delivery/waterfall-development/waterfall-development.component';
import { DeliveryPricingComponent } from './delivery/delivery-pricing/delivery-pricing.component';
import { MicrosoftComponent } from './technologies/microsoft/microsoft.component';
import { JavaComponent } from './technologies/java/java.component';
import { SapComponent } from './technologies/sap/sap.component';
import { OpensourceComponent } from './technologies/opensource/opensource.component';
import { MobileComponent } from './technologies/mobile/mobile.component';
import { OfficegapsComponent } from './solutions/officegaps/officegaps.component';
import { SurveyshieldComponent } from './solutions/surveyshield/surveyshield.component';
import { MroComponent } from './solutions/mro/mro.component';
import { SapimComponent } from './solutions/sapim/sapim.component';
import { SolutionsComponent } from './solutions/solutions/solutions.component';
import { TechnologiesComponent } from './technologies/technologies/technologies.component';
import { CapabilitiesComponent } from './capabilities/capabilities/capabilities.component';
import { CustomSoftwareComponent } from './capabilities/custom-software/custom-software.component';
import { EnterpriseSoftwareComponent } from './capabilities/enterprise-software/enterprise-software.component';
import { ProductDevelopmentComponent } from './capabilities/product-development/product-development.component';
import { WebApplicationComponent } from './capabilities/web-application/web-application.component';
import { MobileAppComponent } from './capabilities/mobile-app/mobile-app.component';
import { CloudAppComponent } from './capabilities/cloud-app/cloud-app.component';
import { SapSupportComponent } from './capabilities/sap-support/sap-support.component';
import { ApiIntegrationComponent } from './capabilities/api-integration/api-integration.component';
import { BusinessTechnologyComponent } from './capabilities/business-technology/business-technology.component';
import { MigrationComponent } from './capabilities/migration/migration.component';
import { MaintenanceComponent } from './capabilities/maintenance/maintenance.component';
import { ApplicationQatComponent } from './capabilities/application-qat/application-qat.component';
import { ImplementationComponent } from './capabilities/implementation/implementation.component';
import { RecruitmentComponent } from './capabilities/recruitment/recruitment.component';
import { AccountingComponent } from './capabilities/accounting/accounting.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { HttpClientModule } from '@angular/common/http';
import { SoftwareCenterComponent } from './capabilities/software-center/software-center.component';
import { ItConsultingComponent } from './capabilities/it-consulting/it-consulting.component';
import { ProffessionalServiceComponent } from './capabilities/proffessional-service/proffessional-service.component';
import { SoftwareFactoryComponent } from './capabilities/software-factory/software-factory.component';
import {Ng2TelInputModule} from "ng2-tel-input";
import { EmployeetestimonialsComponent } from './whyjtsi/employeetestimonials/employeetestimonials.component';
import { BigdataAnalyticsComponent } from './capabilities/bigdata-analytics/bigdata-analytics.component';
import { ActivatemethodologyComponent } from './delivery/activatemethodology/activatemethodology.component';
import { PagethumbnailsComponent } from './pagethumbnails/pagethumbnails/pagethumbnails.component';
import { WhyjtsigridComponent } from './pagethumbnails/whyjtsigrid/whyjtsigrid.component'; 
import { CapabilitiesgridComponent } from './pagethumbnails/capabilitiesgrid/capabilitiesgrid.component'; 
import { TechnologiesgridComponent } from './pagethumbnails/technologiesgrid/technologiesgrid.component';
import { DeliverypricinggridComponent } from './pagethumbnails/deliverypricinggrid/deliverypricinggrid.component';
import { SuccessstoriesgridComponent } from './pagethumbnails/successstoriesgrid/successstoriesgrid.component';
import { KeyDefferentiatorsComponent } from './key-defferentiators/key-defferentiators.component';
import { SoftwareEngineComponent } from './capabilities/software-engine/software-engine.component';
import { LayoutComponent } from './layout/layout.component';
import { PricingpageComponent } from './pagethumbnails/pricingpage/pricingpage.component';
import { DeliveryPricingItemComponent } from './delivery-pricing-item/delivery-pricing-item.component';
import { CapabilitiesItemComponent } from './capabilities-item/capabilities-item.component';
import { SnowFlakeComponent } from './snow-flake/snow-flake.component';
import { DecorationsComponent } from './decorations/decorations.component'; 
import { AccountingservicenewComponent } from './accountingservicenew/accountingservicenew.component';
import { EmptylayoutsComponent } from './emptylayouts/emptylayouts.component';
import { SwiperModule} from "swiper/angular"
import { CookieService } from 'ngx-cookie-service';
import { QuickcontactComponent } from './quickcontact/quickcontact.component';
import { QualcheckersComponent } from './qualcheckers/qualcheckers.component';
//import { stat } from 'fs';
//import { from } from 'rxjs';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CareersComponent,
    ContactComponent,
    CirlelinksComponent,
    WhoweareComponent,
    LeadershipComponent,
    ValuepropositionComponent,
    CurrentnewsComponent,
    PartnerwithusComponent,
    SuccessStoriesComponent,
    TestimonialsComponent,
    HireResourcesComponent,
    TaaSComponent,
    TimeAndMaterialComponent,
    FulltimeEquivalentComponent,
    FirmFixedPriceComponent,
    AgileDevelopmentComponent,
    WaterfallDevelopmentComponent,
    WhyjtsiComponent,
    DeliveryPricingComponent,
    MicrosoftComponent,
    JavaComponent,
    SapComponent,
    OpensourceComponent,
    MobileComponent,
    OfficegapsComponent,
    SurveyshieldComponent,
    MroComponent,
    SapimComponent,
    SolutionsComponent,
    TechnologiesComponent,
    CapabilitiesComponent,
    CustomSoftwareComponent,
    EnterpriseSoftwareComponent,
    ProductDevelopmentComponent,
    WebApplicationComponent,
    MobileAppComponent,
    CloudAppComponent,
    SapSupportComponent,
    ApiIntegrationComponent,
    BusinessTechnologyComponent,
    MigrationComponent,
    MaintenanceComponent,
    ApplicationQatComponent,
    ImplementationComponent,
    RecruitmentComponent,
    AccountingComponent,
    FaqComponent,
    PrivacyComponent,
    DisclaimerComponent,
    SoftwareCenterComponent,
    ItConsultingComponent,
    ProffessionalServiceComponent,
    SoftwareFactoryComponent,
    EmployeetestimonialsComponent,
    BigdataAnalyticsComponent,
    ActivatemethodologyComponent,
    PagethumbnailsComponent,
    WhyjtsigridComponent,
    CapabilitiesgridComponent, 
    TechnologiesgridComponent,
    DeliverypricinggridComponent,
    SuccessstoriesgridComponent,
    KeyDefferentiatorsComponent,
    SoftwareEngineComponent,
    LayoutComponent,
    PricingpageComponent,
    DeliveryPricingItemComponent,
    CapabilitiesItemComponent,
    SnowFlakeComponent,
    DecorationsComponent,
    AccountingservicenewComponent,
    EmptylayoutsComponent,
    QuickcontactComponent,
    QualcheckersComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({positionClass:'toast-top-center'}),
    Ng2TelInputModule,
    SwiperModule,
    ReactiveFormsModule,
    
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
