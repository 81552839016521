import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-firm-fixed-price',
  templateUrl: './firm-fixed-price.component.html',
  styleUrls: ['./firm-fixed-price.component.scss']
})
export class FirmFixedPriceComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Firm and fixed Software development price contract model| JTSi Technologies');
    
    this.meta.addTags([
      {name:'description', content:'JTSi implements firm and fixed price software development contract model based on budget requirements of the project for our clients in Washington DC, USA.'},
    {name:'keywords', content:'Fixed price contract, Firm and fixed price model, software development fixed and variable costs '},
      {name :'description', content:'en-US',property:'og:locale'},
     {name :'description', content:'website',property:'og:type'},
     { property :'og:title', content:'"Software Development | IT Offshore Services Company | USA | VIRGINIA '},
   
     { property :'og:description', content:' "JTSi implements firm and fixed price software development contract model based on budget requirements of the project for our clients in Washington DC, USA.'},
         { property:'og:url',content:'"https://www.jtsiindia.com/delivery-pricing/firm-fixed-price' },
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Firm-Fixed-Price-(-FFP-).webp' } ,                                                         
      { property:'og:site_name', content:'JTSi Technologies India'},
     {property:'article:modified_time', content:'fetch current time'},
     {name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'Software Development | IT Offshore Services Company | USA | VIRGINIA'},
     { name:'twitter:description', content:' JTSi implements firm and fixed price software development contract model based on budget requirements of the project for our clients in Washington DC, USA.'},
   {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Firm-Fixed-Price-(-FFP-).webp'}
  ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
