import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-successstoriesgrid',
  templateUrl: './successstoriesgrid.component.html',
  styleUrls: ['./successstoriesgrid.component.scss']
})
export class SuccessstoriesgridComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('JTSi Technologies: Software Solutions and Products | USA |India');
    this.meta.addTags([
  {name:'description', content:'By covering an extensive range of industries and integrating the latest IT technologies, we can provide customers with custom software solutions and products that perfectly fit their needs.'},
  { property:'og:locale', content:'en_US'},
	{ property:'og:type', content:'website'},
	{ property:'og:title', content:' JTSi Technologies : Software Solutions and Products | USA|India'},
	{ property:'og:description', content:'By covering an extensive range of industries and integrating the latest IT technologies, we can provide customers with custom software solutions and products that perfectly fit their needs.'},
	{ property:'og:url', content:'https://www.jtsiindia.com/solutions'},
	{ property:'og:image', content:'https://www.jtsiindia.com/assets/img/Solutions_header.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
	{ property:'article:modified_time', content:'fetch current time'},
    
  { name:'twitter:card', content:'summary_large_image'},
  { name:'twitter:site', content:'@Jtsiindia'},
  { name:'twitter:creator', content:'@Jtsiindia'},
  { name:'twitter:title', content:'JTSi Technologies : Software Solutions and Products | USA|India'},
  { name:'twitter:description', content:'By covering an extensive range of industries and integrating the latest IT technologies, we can provide customers with custom software solutions and products that perfectly fit their needs.'},
  { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Solutions_header.webp'}, 
    ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();


  }

}
