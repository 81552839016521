import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-custom-software',
  templateUrl: './custom-software.component.html',
  styleUrls: ['./custom-software.component.scss']
})
export class CustomSoftwareComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,


  ) { 
    this.titleService.setTitle('Custom software application development company in New Jersey | JTSi Technologies');
    
    
     this.meta.addTags([
      {name:'description', content:'We are one of the best custom software development companies in New Jersey, USA. We specialize in outsourced application development and custom software design.'},     
      {name:'keywords', content:'Custom software development, Custom application development services, custom software design'},
      {property :'og:locale', content:'en_US'},
      {property :'og:type',content:'website '},
      {property :'og:title',content:'Custom software application development company in New Jersey | JTSi Technologies'},
      {property :'og:description', content:'We are one of the best custom software development companies in New Jersey, USA. We specialize in outsourced application development and custom software design.'},
      {property :'og:url',content:'https://www.jtsiindia.com/capabilities/custom-software-development'},
      {property :'og:image',content:'https://www.jtsiindia.com/assets/img/Custom-software-development.webp'},                                                          
      {property :'og:site_name', content:'JTSi Technologies India'},
      {property :'article:modified_time', content:'fetch current time'},
      {name:'twitter:card',  content:'summary_large_image'},
      {name:'twitter:card:twitter:site', content:'@Jtsiindia'},
      {name:'twitter:card:twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:card:twitter:title', content:'Custom software application development company in New Jersey | JTSi Technologies'},
      {name:'twitter:card:twitter:description', content:'We are one of the best custom software development companies in New Jersey, USA. We specialize in outsourced application development and custom software design.'},
      {name:'twitter:card:twitter:image', content:'https://www.jtsiindia.com/assets/img/Custom-software-development.webp'},
     ]);
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
