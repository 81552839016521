export class Contact {
    ctD_ID:number=0;
    ctD_Name:string='';
    ctD_Email:string='';
    ctD_Phone:string='';
    ctD_Message:string='';
    ctD_Website:string='';
    countryCode:string='';
}
 
