<div class="innerHeader  Key-Differentiators">
    <div class="innerHeadings">
<!-- <h4>technologies</h4> -->
<h1>Key Differentiators</h1>
</div>
</div>

<app-cirlelinks></app-cirlelinks>

  <section class=" commonPadding">
    <div class="containers">
      <div class="keyDefference">
        <!-- <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#stpimember"></use>
            </svg>
          </div>
          <h3>
            Member STPI
          </h3>
          <p>
            STPI (Software Technology Park of India) is unique in its nature as it focuses on one product - Computer Software. 100% foreign equity is permitted and are completely duty free.
          </p>
        </div> -->
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#iso9001"></use>
            </svg>
          </div>
          <h3>
            ISO 9001 – 2015 
          </h3>
          <p>
            The Primary focus of quality management is to meet customer requirements and to strive to exceed customer expectations.
          </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#achievers"></use>
            </svg>
          </div>
          <h3>
            Achievers Award
          </h3>
        <p>
          JTSi has received "Indian Leadership Award For Industrial Development" award for its contribution to the industrial development.
        </p>
        </div>
        <!-- <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#nasscomlogo"></use>
            </svg>
          </div>
          <h3>
            NASSCOM
          </h3>
          <p>
            It accelerates the pace of transforming the industry to a global digital transformation. It also makes sure that service quality and execution of Intellectual Property Rights have been properly implemented in the Indian software. 
          </p>
        </div> -->
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#govtfecility"></use>
            </svg>
          </div>
          <h3>
            In Govt. Facility
          </h3>
       <p>
        JTSi is located in an IT park established by the Government of Kerala. It boasts a campus spanning over 260 acres, with 450 companies and over 100,000 professionals and is a leading hub of IT expertise in Kerala
       </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#powerbackup"></use>
            </svg>
          </div>
          <h3>
            24/7 Power Backup
          </h3>
          <p>
            JTSi has 63 KVA, 500 KVA and 750 KVA diesel generators, providing 100% power back up.
          </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#biometric"></use>
            </svg>
          </div>
          <h3>
            Biometric Enabled
          </h3>
          <p>
            JTSi premises is biometric enabled, it identifies and authenticates an individuals by physiological traits such as fingerprints and eyes.
          </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#zerodebt"></use>
            </svg>
          </div>
          <h3>
            Zero Debt Company
          </h3>
          <p>
            Since 2004, JTSi is a zero debt company and hence it is financially stable. Employees can undoubtedly build long term careers with us.
          </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#threetier"></use>
            </svg>
          </div>
          <h3>
            3 Tier Facility Security
          </h3>
          <p>
            Physical security for the campus is provided by SISF (State Industrial Security Force), the major areas of the campus is covered through CCTV Surveillance and Pink Patrol, by lady police officers, patrols the campus day and night.
          </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#directinternational"></use>
            </svg>
          </div>
          <h3>
            Direct International Flights
          </h3>
         <p>
          Kochi Airport has direct international flights to many cities from around the globe. Kochi is also a great destination to admire nature’s beauty. 
         </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#facereco"></use>
            </svg>
          </div>
          <h3>
            Face Recognition 
          </h3>
        <p>
          JTSi also has face recognition technology that automatically identify or verify a person from a digital image or a video frame. It works by pinpointing and measuring facial features from a given image.
        </p>
        </div>
        <div class="keybox">
          <div class="keyicon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#onehour"></use>
            </svg>
          </div>
          <h3>
            One Hour To International Airport
          </h3>
        <p>
          Distance from Airport to our office premise is 18.6 miles which would take approximately an hour.
        </p>
        </div>
      </div>
    </div>
  </section>