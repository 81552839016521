import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { CareersService } from '../shared/careers.service';
import { Careers } from '../shared/careers.model';
import { ApplynowService } from '../shared/applynow.service';
import { CommonfunctionsService } from '../utils/commonfunctions.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Applynow } from '../shared/applynow.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { Meta, Title}from '@angular/platform-browser';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit, AfterViewChecked {
  reqList: Careers[] = [];
  phoneValid = true;
  selectedFiles: any = [];
  fileUploadSuccess=false;
  constructor(
    public service: CareersService,
    public cdRef: ChangeDetectorRef,
    public applynowservice: ApplynowService,
    public commonfunctions: CommonfunctionsService,
    private toaster: ToastrService,
    public http: HttpClient,
    private titleService: Title,
    private meta: Meta,
    
  ) { 

    this.titleService.setTitle('Current Openings | IT Careers | JTSi Technologies');
    
    
    this.meta.addTags([
      {name:'description', content:'JTSi is the perfect career destination for dedicated, talented, and experienced individuals looking for new challenges and opportunities. We provide career opportunities with an ideal work-life balance.'},
      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:' Current Openings | IT Careers | JTSi Technologies. '},
      { property:'og:description', content:'JTSi is the perfect career destination for dedicated, talented, and experienced individuals looking for new challenges and opportunities. We provide career opportunities with an ideal work-life balance.'},
      { property:'og:url', content:'https://www.jtsiindia.com/careers'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/slide_04.webp'},                                                           
      { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Current Openings | IT Careers | JTSi Technologies.'},
      { name:'twitter:description', content:'JTSi is the perfect career destination for dedicated, talented, and experienced individuals looking for new challenges and opportunities. We provide career opportunities with an ideal work-life balance.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/slide_04.webp'},

    ])
  
  
  }

  ngAfterViewChecked() {
    let i;

    var jobtitle = document.getElementsByClassName("designation");

    // Create event listeners for each accordion heading
    for (i = 0; i < jobtitle.length; i++) {

      jobtitle[i].addEventListener("click", function (e) {
        e.stopPropagation();
        e.preventDefault();

        var opening = document.getElementsByClassName("vacancies");
        // If we want to close previous open item when opening another
        for (i = 0; i < opening.length; i++) {
          opening[i].className = 'vacancies closed';
        }

        let itemClassName = this.parentNode.className;
        if (itemClassName == 'vacancies closed') {
          this.parentNode.className = 'vacancies open';
        }

      }, false

      );
    } 

  }
  ngOnInit(): void {
    this.getRequisition();
    this.commonfunctions.CreateCanonicalURL();

  }
  getRequisition(): void {
    this.service.getRequisiotnDetails().subscribe(
      res => this.reqList = res.sort((a, b) => {
        if (a.department > b.department) { return 1; }
        if (a.department < b.department) { return -1; }
        return 0;
      }
      )
    );

  }
  onSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }
 
    if (!this.uploadFiles(frm)) {
      return;
    }
    frm.controls['phoneNumber'].setValue(frm.value.countryCode + frm.value.phoneNumber);
    frm.controls['fileuploaded'].setValue(frm.value.fileuploaded.replace("C:\\fakepath\\",""));
    console.log(frm.value);
    this.applynowservice.postResumeDetail().subscribe(
      res => {
        this.resetForm(frm);
        this.toaster.success("You have applied successfully", "");
      },
      err => {
        this.toaster.error("Failed to apply", "");
        console.log(err);
      }
    )
  }
  resetForm(frm: NgForm) {
    frm.resetForm();
    this.applynowservice.formData = new Applynow();
  }
  hasError(event: any, frm: NgForm) {
    this.phoneValid = true;
    if (!event && frm.value.phoneNumber != "") {
      this.phoneValid = false;
    }
  }
  onContryChange(event: any, frm: NgForm) {
    frm.controls['countryCode'].setValue(event.dialCode);
  }
  showApplyNow(id:number, frm: NgForm){
    frm.controls['boReqID'].setValue(id); 
    
  }
  savefiles(frm: NgForm):boolean  {
     
    let inputLabel = '', filenames = '';
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        let formData = new FormData();
        let curTime = (new Date().toLocaleDateString().replace("/", "")).replace("/", "") + new Date().getTime();
        let fname = this.selectedFiles[i].File.name;
        let extension = fname.slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1);
        let filesaved = curTime + '.' + extension;
        let fullpath = "uploadFiles/Resume/" + filesaved;
        fullpath = (((fullpath.replace("/", "~")).replace("/", "~")).replace("/", "~")).replace("/", "~");
        formData.append('file', this.selectedFiles[i].File);
        formData.append('userpath', 'ApplyNow');
        formData.append('fileWithext', filesaved);
        formData.append('userid', "Resume");
        const root = environment.appUrl;
        const boroot = environment.boappUrl;
        let applURL = root + 'upload.php';
        let boapplURL = boroot + 'upload.php';
        frm.controls['fileuploaded'].setValue(filesaved); 
        this.http.post(applURL, formData,{responseType:'text'})
          .subscribe(response => {
            this.http.post(boapplURL, formData) 
            .subscribe(response => {
              return true;
            },
              error => {
                console.log('fileupload Error:', error);
               // this.toaster.error("Failed to upload resume", "");
                return false;
              });
            }, error => {
              console.log('fileupload Error:', error);
             // this.toaster.error("Failed to upload resume", "");
              return false;
            });
          }
        }
      //    forkJoin(this.http.post(applURL, formData),this.http.post(boapplURL, formData))
      //   .subscribe(response => {
      //     return true;
      //     }, error => {
      //       console.log('fileupload Error:', error);
      //       return false;
      //     });
      //   }
      // };
        return true;
         
      }
      uploadFiles(frm: NgForm):boolean {
        let AllFiles: any = '';
         
        if (this.selectedFiles.length <= 0) {
          this.toaster.warning('Please upload your resume.');
          return;
        }
         
        for (let i = 0; i < this.selectedFiles.length; i++) {
          let filename = this.selectedFiles[i].File.name;
        }
         
        return this.savefiles(frm); 
    
      }
      onFileSelected(fileInput,  frm: NgForm) {
        let file = fileInput.target.files[0]; 
        let AllFiles: any = '';
        this.selectedFiles = [];
    
        let filename: any = '';
        for (let i = 0; i < fileInput.target.files.length; i++) {
          this.selectedFiles.push({
            'File': fileInput.target.files[i] 
          });
          filename = fileInput.target.files[i].name;
          
        }
        frm.controls['fileuploaded'].setValue(filename); 
      
      }

}
