import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-pricing-item',
  templateUrl: './delivery-pricing-item.component.html',
  styleUrls: ['./delivery-pricing-item.component.scss']
})
export class DeliveryPricingItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
