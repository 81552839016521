<div class="quckcontactwarper">
    <app-header></app-header>
<section class="quickcpadding">
    <hr>
    <div class="containers">
       <div class="gtexthumbs">
        <div class="box">
            <img class="desk" src="../../assets/img/Gitex-logoText.png" alt="">
            <img class="mob" src="../../assets/img/Gitex-Global-Strip-Logo.png" alt="">
        </div>
        <div class="box">
            <img src="../../assets/img/90dayscloud.png" alt="">
            <p>
                90 Days Cloud Solutions
            </p>
        </div>
        <div class="box">
            <img src="../../assets/img/QASystems.png" alt="">
            <p>
                QA Systems
            </p>
        </div>
        <div class="box">
            <img src="../../assets/img/MROERP.png" alt="">
            <p>
                MRO ERP
            </p>
        </div>
        <div class="box">
            <img src="../../assets/img/APIIntegrations.png" alt="">
            <p>
                API Integrations
            </p>
        </div>
       </div>
        </div>
        </section>
        <div class="quickgrayform">
            <div class="containers">
                <div class="quickformgrid">

                    <article>
                        <form class="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">

                        <h2>
                            Let's Get <strong>Connected</strong> 
                        </h2>
                        <div class="inputform">
                            <div class="formelmnt">
                                <label for="">Email <strong>*</strong></label>
                                <div style="position: relative;">
                                <input type="email" name="email" #ctD_Email="ngModel" [(ngModel)]="service.formData.ctD_Email"
                                [ngClass]="{'is-invalid': f.submitted &&  ctD_Email.invalid}" required maxlength="100">
                            </div>
                            </div>
                            <div class="formelmnt">

                                <label for="">Phone Number <strong>*</strong></label>
                             <div style="position: relative;">
                                <input style="text-indent: 37px;" type="text" ng2TelInput name="phoneNumber" #ctD_Phone="ngModel" [(ngModel)]="service.formData.ctD_Phone"
                                [ngClass]="{'is-invalid': f.submitted &&  ctD_Phone.invalid}" required maxlength="20" (hasError)="hasError($event,f)"
                                [ng2TelInputOptions]="{autoPlaceholder:'false'}"
                                (countryChange)="onContryChange($event,f)">
                                <input type="hidden" name="countryCode" #CountryCode="ngModel" [(ngModel)]="service.formData.countryCode">
                            </div>
                            </div>
                        </div>
                        <label class="agreecheck" for="quickagree">
                            I agree to recive future email communication from JTSi India Pvt Ltd.
                        <input type="checkbox" name="emailflag" id="quickagree" checked>
                        <span></span>
                    </label>
                    <button class="btn btn-lg btn-primary" type="submit">Submit &#10093;</button>
                </form>

                    </article>
                    <picture>
                        <img src="../../assets/img/ContactPerson.jpg" alt="">
                    </picture>
                </div>
                <ul class="phonnmail">
                    <li>
                        <span>
                            <svg>
                                <use xlink:href="../../assets/img/sprite.svg#call"></use>
                              </svg>
                        </span>
                    +91 484 407-0368, +1(202)464-JTSi(5874) (US)
                </li>
                <li>
                    <span>
                        <svg>
                            <use xlink:href="../../assets/img/sprite.svg#mail"></use>
                          </svg>
                    </span>
                    info@jtsiindia.com
                </li>
                </ul>
                </div>
        </div>
        <div class="quickfoot">
            <div class="containers">
            &copy; 2023 All Rights Reserved.
        </div>
        </div>
    </div>