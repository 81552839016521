import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-opensource',
  templateUrl: './opensource.component.html',
  styleUrls: ['./opensource.component.scss']
})
export class OpensourceComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

        ) 
  { 
    this.titleService.setTitle('Opensource Technologies and services in USA | New Jersy | JTSi Technologies');
    this.meta.addTags([
      {name:'description', content:'JTSi has many open source projects that involves web, mobile and ecommerce platforms using open source technologies that meets business requirements on ideal budgets.'},
      {name:'keywords', content:'opensource technologies, opensource software, opensource project'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:'"Opensource Technologies and services in USA | New Jersy | JTSi Technologies'},
      {property :'og:description', content:'"JTSi has many open source projects that involves web, mobile and ecommerce platforms using open source technologies that meets business requirements on ideal budgets '},
      {property:'og:url',content:'https://www.jtsiindia.com/technologies/opensource' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/opensourceBanner.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Opensource Technologies and services in USA | New Jersy | JTSi Technologies'},
      {name:'twitter:description', content:' JTSi has many open source projects that involves web, mobile and ecommerce platforms using open source technologies that meets business requirements on ideal budgets.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/opensourceBanner.webp'}
      ]);
    }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
