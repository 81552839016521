import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Applynow } from './applynow.model';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplynowService {

  constructor(private http:HttpClient) { }
  formData:Applynow=new Applynow();
    
  postResumeDetail(){
    return  this.http.post(`${environment.apiServer}ResumeDetails`,this.formData)
     
  //  return forkJoin(this.http.post(`${environment.apiServer}ResumeDetails`,this.formData),this.http.post(`${environment.boApiServer}Resume/saveResumeDetails`, null, {
  //     params: {
  //       fullName: this.formData.res_Name,
  //       emailid: this.formData.res_Email, 
  //       phoneNumber: this.formData.res_Phone, 
  //       fileName: this.formData.res_File, 
  //       message: this.formData.res_Message,
  //       boReqID:this.formData.res_BOReqID
  //     }}));
      
  }
}
