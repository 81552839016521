import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from '../utils/commonfunctions.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,
    private _renderer2:Renderer2,
    @Inject(DOCUMENT) private _document:Document

  ) { 
    this.titleService.setTitle('General Frequently Asked questions | JTSi Technologies');
    
    this.meta.addTags([
      {name:'description', content:'FAQ of JTSi explains our services, security, contract agreements, staff, pricing, Project and testing.'},
      {property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:' General Frequently Asked questions | JTSi Technologies '},
      { property:'og:description', content:'FAQ of JTSi explains our services, security, contract agreements, staff, pricing, Project and testing. '},
      { property:'og:url', content:'https://www.jtsiindia.com/faq'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/faq.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'General Frequently Asked questions | JTSi Technologies'},
      { name:'twitter:description', content:'FAQ of JTSi explains our services, security, contract agreements, staff, pricing, Project and testing.'},
      { name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/faq.webp'},
    ])

    // let script=this._renderer2.createElement('script');
    // script.type=`application/Id+json`;
    // script.text=`{
    //   "@context": "http://schema.org",
    //   "@type": "FAQPage",
    //   "@id": "https://www.jtsiindia.com/faq",
    //   "mainEntity": [
    //     { 
    //       "@type": "Question",
    //       "name": "What is your experience? Which are the projects that you undertake?",
    //       "acceptedAnswer": {
    //         "@type": "Answer",
    //         "text": "Established 18 years ago, JTSi provides superior web, mobile, and cloud development services, as well as IT consulting services and business solutions to our clients worldwide. Since its inception, JTSi has grown as a well-established and reliable organization with over 250+ satisfied clients worldwide, ranging from startups to Fortune 500 companies across diverse industries. Our areas of expertise lies in the following verticals:\n\nEnterprise and custom software Development\nInformation Technology Staffing (On-shore/Off-shore model)\nProfessional Consulting services (Consultants/Contractors)\nUX and UI Engineering & Independent Testing\nSecurity and Server Technology Support\nIntegration Services, Application Services, Data Warehousing & Business Intelligence Services\nUS Recruitment, Quickbooks Accounting, SEO and Internet Marketing\nAs a result of our team members' combined business experience and strong software development skills, our approach is focused, sound and innovative.\n\nThrough the use of the latest cutting-edge technologies, we are able to understand the Client's business needs, propose creative IT strategies in line with the business vision, and implement the business plan through building or implementing high quality scalable business solutions."
    //       }
    //     },
    //     {
    //       "@type": "Question",
    //       "name": "What are your core capabilities?",
    //       "acceptedAnswer": {
    //         "@type": "Answer",
    //         "text": "Microsoft technologies\nJava technologies\nCustom software development\nWebsite design\nE-commerce solutions\nRecruitment and Accounting services\nRemote Staffing\nQuality Assurance\nCloud Application Development"
    //       }
    //     },
    //     {
    //       "@type": "Question",
    //       "name": "What are your technological capabilities?",
    //       "acceptedAnswer": {
    //         "@type": "Answer",
    //         "text": "Database: MS SQL Server, MySQL, Oracle and MS Access\nProgramming Languages: ASP.NET, C#, VB Script, Java Script, ASP, PHP, XML, Java, HTML, J2EE technology, Application Servers (WebLogic, JBoss, Websphere, etc.)\nOOAD Tools: Rational, Visio, UML\nEmbedded / mobile solutions (J2ME, WinCE, eVB, eVC++, PocketPC, WAP / WML, etc.)\nDevelopment Tools: MS Visual Studio 2003, LLBLGenPro\nGraphic Tools: Adobe Photoshop, Macromedia FlashMX, Dream Weaver MX, Corel Draw\nSource Control: VSS\nServers: Advanced server technologies like Apache"
    //       }
    //     },
    //     {
    //       "@type": "Question",
    //       "name": "What is your development work methodology?",
    //       "acceptedAnswer": {
    //         "@type": "Answer",
    //         "text": "We follow a process-centric approach for our development projects. For us, innovative products and satisfied clients are what we aim at. We are committed to provide maximum benefits to our customers. For more optimal, processes, methodologies and project deliverables, contact us!"
    //       }
    //     }
    //   ]
    // }`

    // this._renderer2.appendChild(this._document.body,script)
  
   
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  let i;
 var freeaq = document.getElementsByClassName("freeaqs");
 var eachque = document.getElementsByClassName("ottachodyam");
 
 // Create event listeners for each accordion heading
 for (i = 0; i < eachque.length; i++) {
  eachque[i].addEventListener("click", ansWer);
 }
 
 function ansWer() {
   var itemClassName = this.parentNode.className;
 
   // If we want to close previous open item when opening another
   for (i = 0; i < freeaq.length; i++) {
    freeaq[i].className = 'freeaqs closed';
   }
 
   if (itemClassName == 'freeaqs closed') {
     this.parentNode.className = 'freeaqs open';
   }
 }
 
 window.onunload = function() {
   for (i = 0; i < eachque.length; i++) {
    eachque[i].removeEventListener("click", ansWer);
   }        
   return;
 }
 

  }

}
