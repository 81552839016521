import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-application-qat',
  templateUrl: './application-qat.component.html',
  styleUrls: ['./application-qat.component.scss']
})
export class ApplicationQatComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Outsourcing Software Application quality assurance & testing |USA| JTSi Technologies.');
    this.meta.addTag({name:'description', content:'JTSi has a dedicated application quality assurance testing team who takes care of software quality at optimal price and delivers unique services as a software testing company in Washington DC, USA'});
    this.meta.addTag({name:'keywords', content:'Software testing company, quality assurance and testing, Application QAT Services.'});
  
    this.meta.addTags([

      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:' Outsourcing Software Application quality assurance & testing |USA| JTSi Technologies. '},
      { property:'og:description', content:'JTSi has a dedicated application quality assurance testing team who takes care of software quality at optimal price and delivers unique services as a software testing company in Washington DC, USA'},
      { property:'og:url', content:'https://www.jtsiindia.com/capabilities/application-QAT-services'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Application-QAT-Service.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Outsourcing Software Application quality assurance & testing |USA| JTSi Technologies.'},
      { name:'twitter:description', content:'JTSi has a dedicated application quality assurance testing team who takes care of software quality at optimal price and delivers unique services as a software testing company in Washington DC, USA.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Application-QAT-Service.webp'},
      


    ])
  
  
  
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
