import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-surveyshield',
  templateUrl: './surveyshield.component.html',
  styleUrls: ['./surveyshield.component.scss']
})
export class SurveyshieldComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,
    private _renderer2:Renderer2,@Inject(DOCUMENT) private _document:Document

  ) { 
    this.titleService.setTitle('Survey Shield| Online Panel Survey management software solutions| JTSi Technologies');
    
    this.meta.addTags([

    {name:'description', content:'JTSi helps to create and conduct administrative professional online surveys using Survey shield, Leading Automated survey panel management software solutions in USA, UK and Canada.'},
    {name:'keywords', content:'Survey Management, Online survey maker, survey creating tool'},
    { property:'og:locale', content:'en_US'},
    { property:'og:type', content:'website'},
    { property:'og:title', content:' Survey Shield| Online Panel Survey management software solutions| JTSi Technologies'},
    { property:'og:description', content:'JTSi helps to create and conduct administrative professional online surveys using Survey shield, Leading Automated survey panel management software solutions in USA, UK and Canada.'},
    { property:'og:url', content:'https://www.jtsiindia.com/solutions/surveyshield'},
    { property:'og:image', content:'https://www.jtsiindia.com/assets/img/survey.webp'},                                                           
    { property:'og:site_name', content:'JTSi Technologies India'},
    { property:'article:modified_time', content:'fetch current time'},

    { name:'twitter:card', content:'summary_large_image'},
    { name:'twitter:site', content:'@Jtsiindia'},
    { name:'twitter:creator', content:'@Jtsiindia'},
    { name:'twitter:title', content:'Survey Shield| Online Panel Survey management software solutions| JTSi Technologies'},
    { name:'twitter:description', content:'JTSi helps to create and conduct administrative professional online surveys using Survey shield, Leading Automated survey panel management software solutions in USA, UK and Canada.'},
    { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/survey.webp'},
    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  //   let script=this._renderer2.createElement('script');
  //   script.type=`application/Id+json`;
  //   script.text=`{

  // "@context": "http://schema.org/",
  // "@type": "Product",
  // "name": "Surveyshield",
  // "url": "https://www.jtsiindia.com/solutions/surveyshield",
  // "image": "https://www.jtsiindia.com/assets/img/survey.webp",
  // "description": "SurveyShield is a feature-rich, secure, and easy-to-use survey application that JTSi provides for creating and administering professional surveys online. Through SurveyShield, JTSi provides Freedom Data Server (FDS) enabled Application Service Provider (ASP). You will be able to store survey responses in the host data server or in own server with the help of FDS. We understand that the survey responses are very sensitive information and we offer a completely secure setting for the collected data with the help of FDS.",
  // "brand": "JTSi India"

      
    

  //   }`
  //   this._renderer2.appendChild(this._document.body,script)
  // }

}
}