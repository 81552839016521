import { Injectable } from '@angular/core';
import { Talktous } from './talktous.model';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TalktousService {

  constructor(private http:HttpClient) { }
  formData:Talktous=new Talktous();
    
  postTalktousDetail(){
     return this.http.post(`${environment.apiServer}TalktoUs`,this.formData)
  }

 

}
