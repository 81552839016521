import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-partnerwithus',
  templateUrl: './partnerwithus.component.html',
  styleUrls: ['./partnerwithus.component.scss']
})
export class PartnerwithusComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) 
  { 
    this.titleService.setTitle('Partner with us | Social Impact | JTSi Technologies India');
    this.meta.addTags([
      {name:'description', content:'JTSi has 15+ years of experience in the software industry to develop and support a global network of social entrepreneurs through collaboration with individuals, foundations, corporations.'},
      {property:'og:locale', content:'en_US' } ,
      {property:'og:type', content:'website'},
      {property:'og:title' ,content:'" Partner with us | Social Impact | JTSi Technologies India '},
      {property:'og:description', content:'"JTSi has 15+ years of experience in the software industry to develop and support a global network of social entrepreneurs through collaboration with individuals, foundations, corporations.'},
      {property:'og:url', content:'https://www.jtsiindia.com/why-JTSi/partner-with-us'},
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/partner.webp'},
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time' },
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Partner with us | Social Impact | JTSi Technologies Indias'},
      {name:'twitter:description', content:'JTSi has 15+ years of experience in the software industry to develop and support a global network of social entrepreneurs through collaboration with individuals, foundations, corporationa'},
      {name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/partner.webp'}
      ]);
        
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
