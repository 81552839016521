
<div class="innerHeader capabilities Delivery">
    <div class="innerHeadings">
      <!-- <h4>Capabilities</h4> -->
      <!-- <h3>Software </h3> -->
      <h1>Delivery  <span> / Pricing</span></h1>
    </div>
  </div>
  
  <app-cirlelinks></app-cirlelinks>
  <app-deliverypricinggrid></app-deliverypricinggrid>