import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emptylayouts',
  templateUrl: './emptylayouts.component.html',
  styleUrls: ['./emptylayouts.component.scss']
})
export class EmptylayoutsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
