import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-proffessional-service',
  templateUrl: './proffessional-service.component.html',
  styleUrls: ['./proffessional-service.component.scss']
})
export class ProffessionalServiceComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
      this.titleService.setTitle('Software Professional service companies in USA | New Jersy | JTSi Technologies');
      this.meta.addTags([
      {name:'description', content:'We provide enterprise IT software professional services and SaaS Solutions in New Jersey and Washington DC to help our clients achieve better performance and results in their business.'},
      {name:'keywords', content:'Software professional services, Saas Professional services'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:' Software Professional service companies in USA | New Jersy | JTSi Technologies'},
      {property :'og:description', content:'"We provide enterprise IT software professional services and SaaS Solutions in New Jersey and Washington DC to help our clients achieve better performance and results in their business.'},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/professional-services' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/cutting.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Software Professional service companies in USA | New Jersy | JTSi Technologies'},
      {name:'twitter:description', content:'We provide enterprise IT software professional services and SaaS Solutions in New Jersey and Washington DC to help our clients achieve better performance and results in their business.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/cutting.webp'}
      ]);
    }



  ngOnInit(): void {

    this.commonfunctions.CreateCanonicalURL();
  }

}
