import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Form, FormBuilder } from '@angular/forms';
import { ContactService } from '../shared/contact.service';
import { ToastrService } from 'ngx-toastr';
import { Contact } from '../shared/contact.model';
import { CommonfunctionsService } from '../utils/commonfunctions.service';

@Component({
  selector: 'app-quickcontact',
  templateUrl: './quickcontact.component.html',
  styleUrls: ['./quickcontact.component.scss']
})
export class QuickcontactComponent implements OnInit {
  phoneValid = true;
  constructor(
    public service: ContactService,
    public commonfunctions: CommonfunctionsService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
  }
  onSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }
   
    frm.controls['phoneNumber'].setValue(frm.value.countryCode + frm.value.phoneNumber);
    console.log(frm.value);
    this.service.postquickContactDetail().subscribe(
      res => {
        this.resetForm(frm);
        this.toaster.success("You have successfully submitted response", "");
      },
      err => {
        this.toaster.error("Failed to submit response", "");
        console.log(err);
      }
    )
  }
  resetForm(frm: NgForm) {
    frm.resetForm();
    this.service.formData = new Contact();
  }
  hasError(event: any, frm: NgForm) {
    this.phoneValid = true;
    if (!event && frm.value.phoneNumber != "") {
      this.phoneValid = false;
    }
  }
  onContryChange(event: any, frm: NgForm) {
    frm.controls['countryCode'].setValue(event.dialCode);
  }

}
