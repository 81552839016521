import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-it-consulting',
  templateUrl: './it-consulting.component.html',
  styleUrls: ['./it-consulting.component.scss']
})
export class ItConsultingComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('IT Business consulting services for start-up and corporate companies in USA | New Jersey | JTSi Technologies ');
   

    this.meta.addTags([
      {name:'description', content:'JTSI’s IT business solutions consulting services like IT cloud management, IT investment advisory, IT network and infrastructure services for US, UK, Canada and Europe areas.'},
      {name:'keywords', content:'IT Consulting, IT Solutions, IT Management'},
      {property:'og:locale', content:'en_US'},
      { property:'og:type' ,content:'website'},
      { property:'og:title', content:' IT Business consulting services for start-up and corporate companies in USA | New Jersey | JTSi Technologies  '},
      { property:'og:description', content:'JTSi’s IT business solutions consulting services like IT cloud management, IT investment advisory, IT network and infrastructure services for US, UK, Canada and Europe areas.'},
      { property:'og:url',content:'https://www.jtsiindia.com/capabilities/it-consulting-services'},
      { property:'og:image' ,content:'https://www.jtsiindia.com/assets/img/IT-Consulting-Service.webp'},                                                          
      {property:'og:site_name' ,content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time' },
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'IT Business consulting services for start-up and corporate companies in USA | New Jersey | JTSi Technologies '},
      { name:'twitter:description', content:'JTSi’s IT business solutions consulting services like IT cloud management, IT investment advisory, IT network and infrastructure services for US, UK, Canada and Europe areas.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/IT-Consulting-Service.webp'},



   ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
