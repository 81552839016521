<div class="innerHeader WhoWeAre">
  <div class="innerHeadings">
    <h1>Who <span>We Are</span></h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>


<div class="whoweare mb-0">
  <div class="picture">
      <img class="img-fluid" src="../../assets/img/whoeare.webp" alt="JTSi technologies is one of the leading software development company in Kochi, Kerala">
  </div>
  <div class="texts">
      <div class="heading">
          <div class="redBg">
              <h2>why JTSi </h2>
          </div>
          <div class="darkBg">
              <h3>We are exclusive because...</h3>
          </div>
      </div>
      <ul class="bulletpoints">
        <li>Ours is an ISO 9001-2015 certified Company with a diversified set of global portfolios that include major US fortune 100 companies</li>	
<li>We employ an extensive global team backed by a dedicated group of innovative partners</li>	
<li>We deploy processes that are novel, services that are proficient and investment that are most favorable</li>	
<li>We can transfuse any sort of business challenges into towering and cost-effective solutions</li>	
<li>JTSi has business models that are of global standards with skillful offshore employees and onsite strategic partnerships that deliver outstanding upshots at competitive costs</li>	
<li>We have the best software development projects, full-service SAP implementations, consulting and advisory services and offshore development and support for public as well as private companies</li>	

      </ul>
  </div>
</div>
<div class="containers commonPadding Tpadd0">
<ul class="bulletpoints">
  <li>Our Software Development services are customizable, and we have the best R&D department that constantly studies on the projects and the most viable tools and techniques required to execute those projects optimally</li>
  <li>A strategic alliance partnership has been established between JTSi India and the Technology Innovation Center of Johnson Technology Systems Inc</li>
  <li>JTSi has successfully delivered over 100 projects including MRO for Aviation Industry and served more than 20 Fortune 500 companies</li>
  <li>We have automated course of actions that truss our organization with that of the clients</li>
  
</ul>
</div>

<section class="grayBg commonPadding">
<div class="containers">
  <div class="boxtitle_two">
    <h2>
     Core Values
    </h2>
  
</div>

  <div class="partnerNeeds">
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Integrity"></use>
        </svg>
      </div>
      <h3>
        Integrity
      </h3>
      <p>
        Be honest and transparent, maintaining strong moral principles in our dealing with everyone
      </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Loyalty"></use>
        </svg>
      </div>
      <h3>
        Loyalty 
      </h3>
      <p>
        Deliver beyond expectations by faithfully adhering to all our commitments/obligations
      </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Honesty"></use>
        </svg>
      </div>
      <h3>
        Honesty
      </h3>
      <p>
        Maintain openness, fairness and sincerity at all level of our relationships and communications
      </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Trust"></use>
        </svg>
      </div>
      <h3>
        Trust
      </h3>
   <p>
    Deliver solutions to our customer that yield consistently dependable high-quality business solution
   </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Accountability"></use>
        </svg>
      </div>
      <h3>
        Accountability
      </h3>
      <p>
        Take ownership of the task and follow up until expectations are met/exceeded to our Customer's satisfaction
      </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Partnership"></use>
        </svg>
      </div>
      <h3>
        Partnership
      </h3>
      <p>
        Foster partnership between employees and customers while maintaining corporate commitments
      </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Teamwork"></use>
        </svg>
      </div>
      <h3>
        Teamwork
      </h3>
      <p>
        Work collaboratively to achieve all goals through cooperative and coordinates efforts at all levels
      </p>
    </div>
    <div class="partBox">
      <div class="partIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#Recognition"></use>
        </svg>
      </div>
      <h3>
        Recognition
      </h3>
      <p>
        We believe that recognition supports growth of the organization
      </p>
    </div>
  </div>
</div>
</section>

<div class="">
<div class="infrastructure">
  <div class="texttitle_two">
    <h2> Our Location Specifications</h2>
</div>
      <div class="infraPoints">
  
      <div class="textcontent">
  <ul class="bulletpoints">
    <li>Round-the-clock support and security with 24-hour surveillance, CCTV recording, face recognition entry, VPNs, Firewalls, Disaster Recovery and Back-up plans</li>	
    <li>Located at the Thapasya building in Infopark, Kochi; the growing IT hub of Southern India with an unparalleled infrastructure</li>	
    <li>Connectivity Advantage with 'SEA-ME-WE-3' and 'SAFE' submarine cable landings for high-speed internet connectivity</li>	
    <li>100% DG auto changeover Power Back-Up with 63 KVA, 500 KVA and 750 KV</li>	
    <li>Edifice has centrally air-conditioned lobby, three 20 passenger lifts, ramp, cafeteria on ground floor, ATM, stationary shop, coffee shop</li>	
    <li>In the vicinity of Infopark Police Station which is Kochi’s first Cyber Police Station for the Central Kerala</li>	
    <li>State Industrial Security Force (SISF) Guards are posted in all the entry gates and periphery of the campus and other government-owned buildings in Infopark</li>	
    <li>Uninterrupted power supply in the entire campus that is backed by efficient transformers and DG sets</li>	
    
  </ul>
      </div>
      <div class="pics">
        <img src="../../assets/img/GSTi_interior.jpg" alt="bespoke software development firm in Infopark, koch" class="img-fluid">
    </div>

  </div>
  </div>
</div>

<div class="splitcontent mt-0 mb-0">
<div class="pictures vision">
   
</div>
 
      <div class="darkBg contents rightSide">
  <div class="titles blueBg">
      <svg>
          <use xlink:href="../../assets/img/sprite.svg#visions"></use>
        </svg>
<h3>
  Vision
</h3>
</div>
<p>
Lean mean on the scene
</p>
<div class="titles redBg mt-4">
    <svg>
        <use xlink:href="../../assets/img/sprite.svg#missions"></use>
      </svg>
  <h3>
    Mission
  </h3>
</div>
<p>
  To provide quality and innovative professional services at an affordable price and on-time delivery
</p>
</div>

</div>


<div class="advantages">
<div class="boxtitle">
  <h2>
      Products
      <Span>&</Span>
      Services
  </h2>
  <h3 class="text-center">Quality assured projects serving sundry global clients</h3>
</div>

  <div class="advantagePoints">
  <div class="pics">
      <img src="../../assets/img/advantage.webp" alt="software development outsourcing company in kerala, India." class="img-fluid">
  </div>

  <div class="textcontent">

      <h4>Industry Solutions</h4>
    <p>
      JTSi has industry solutions for various business verticals like Manufacturing, Banking and Securities, Insurance, Communications, and Embedded Systems
    </p>
      <h4>Services</h4>
      <p>
        Apart from Software Development services, our services include Custom Application Development, Enterprise Application Development & Maintenance, Mobile Application Development, Cloud Application Development, SAP Support Services, Maintenance & Migration Services, Application QAT Services and Implementation Services
      </p>
      <h4>Product</h4>
      <p>
        Our approaches are highly flexible that can tackle Change Management at any given point of time of the project phases enabling the identification of errors for easy problem solving that ensure client satisfaction and rightful execution of the project
      </p>
      <h4>Innovation for Excellence</h4>
      <p>
        JTSi has set up a dedicated Center of Excellence (CoE) to adopt cutting-edge technologies for business solutions. Our team continuously identifies and researches on generic and specific technologies. The CoE team focuses on initiatives aimed at assimilating and disseminating knowledge from different technology areas to the various delivery groups within the organization
      </p>
  </div>
</div>
</div>

