<div class="circlelinks">
  <div class="iconblue">
    <a [routerLink]="['/delivery-pricing/Team-as-a-Service']" id="team-as-service">
      <span class="">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#hirearesource"></use>
        </svg>
      </span>
      <h3>Team as a Service</h3>
    </a>
  </div>
  <div class="icongreen">
    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#letstalk" id="request-demo">
      <span class="">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#softwares"></use>
        </svg>
      </span>
      <h3>Request a Demo</h3>
    </a>
  </div>
  <div class="iconyellow">
    <a [routerLink]="['/solutions/OfficeGaps']" id="office-gaps">
      <span class="">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#proservice"></use>
        </svg>
      </span>
      <h3>OfficeGaps</h3>
    </a>
  </div>
  <div class="iconred">
    <a [routerLink]="['/contact-us']">
      <span class="">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#contactnew" id="contact-us"></use>
        </svg>
      </span> 
      <h3>Contact Us</h3>
    </a>
  </div>
</div>
