<footer>
  <div class="footerlinks">
    <div class="">
      <img src="../../assets/img/white-logo.webp" alt="JTSi India">
      <ul>
          <li>JTSi Technologies India Pvt. Ltd.</li>
          <li>Infopark Campus, Kakkanad</li>
              <li>Kochi - 682042, Kerala, India</li>
                <li><span>IN Tel: </span> <a href="tel:+914844070368" id="call-india-footer">+91 484 407-0368</a> </li>
                <li><span>IN Tel: </span> <a href="tel:+918041114111" id="call-india-mobile-footer">+91 80 4111-4111</a> </li>
                  <li><span>US Tel: </span> <a href="tel:+12024645874" id="call-usa-footer" >+1(202)464-JTSi(5874)</a> </li>
                  <li><span>Fax: </span> +91 80 4111-4646</li>
                  <li><span>Email: </span> <a href="mailto:marketing@jtsiindia.com" id="send-market-mail-footer" >marketing@jtsiindia.com</a></li>
                  <li><span>Support: </span> <a href="mailto:info@jtsiindia.com" id="send-info-mail-footer" > info@jtsiindia.com </a></li>
                 <li> <a [routerLink]="['/contact-us']" id="find-licatons-footer" >Find All Our Locations</a></li>
        </ul>
  

      <div class="social">
        <a target="_blank" href="https://www.facebook.com/jtsitechnologies">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#faseBuk"></use>
          </svg>
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/jtsi-technologies-india">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#Lenkden"></use>
          </svg>
        </a>
        <a target="_blank" href="https://twitter.com/Jtsiindia">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#Tweetter"></use>
          </svg>
        </a>
        <a target="_blank" href="https://in.pinterest.com/jtsitechnologiesindia">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#pinttrest"></use>
          </svg>
        </a>
        <a target="_blank" href="https://www.instagram.com/jtsiindia/">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#instalogo"></use>
          </svg>
        </a>

        <a target="_blank" href="https://www.youtube.com/channel/UCcnZRAkcseIL8MbR47A6wlA">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#youtube"></use>
          </svg>
        </a>
      </div>
    </div>

    <div class="">
      <h4>Support</h4>
      <ul>
        <!-- <li><a [routerLink]="['/why-JTSi/testimonials']">Client Testimonials</a></li> -->
        <li><a [routerLink]="['/why-JTSi/current-news']" id="current-news-footer">Current News</a></li>
        <li><a [routerLink]="['/careers']" id="career-footer" >Careers</a></li>
        <li><a [routerLink]="['/faq']" id="faq-footer" >FAQ</a></li>
        <li><a [routerLink]="['/privacy-policy']"id="privacy-policy-footer" >Privacy Policy</a></li>
        <li><a [routerLink]="['/disclaimer']" id="disclaimer-footer" >Disclaimer</a></li>
        <li><a href="#"  data-bs-toggle="modal" data-bs-target="#letstalk" id="brouchure-download-footer" >Download Brochure</a></li>
      </ul>
    </div>
    <div class="">
      <h4>Capabilities</h4>
      <ul>
        <li><a [routerLink]="['/capabilities/custom-software-development']" id="custom-soft-footer" > Custom Software Development</a></li>
        <li><a [routerLink]="['/capabilities/mobile-application-development']" id="mobile-app-footer" > Mobile Application Development</a></li>
        <li><a [routerLink]="['/capabilities/SAP-support-services']" id="sap-support-footer" > SAP Support Services</a></li>
        <li><a [routerLink]="['/capabilities/API-integration']" id="api-footer" > API Integration</a></li>
        <li><a [routerLink]="['/capabilities/application-QAT-services']" id="app-qat-footer" >Application QAT Services</a></li>
        <li><a [routerLink]="['/capabilities/business-technology-consulting']" id="busisiness-tech-footer" >Business Technology Consulting</a></li>
        <li><a [routerLink]="['/capabilities/recruitment-hr-services']" id="recruit-hr-footer" >Recruitment &#38; HR Services</a></li>

      </ul>
    </div>
    <div class="">
      <h4>Solutions</h4>
      <ul>
        <li><a [routerLink]="['/solutions/qualcheckers']">QualCheckers</a></li>
        <li><a [routerLink]="['/solutions/OfficeGaps']" id="Office-gaps-footer" >OfficeGaps</a></li>
        <!-- <li><a [routerLink]="['/solutions/surveyshield']" id="survey-shield-footer" >SurveyShield</a></li> -->
        <li><a [routerLink]="['/solutions/MRO-for-aviation']" id="mro-aviation-footer" >MRO for Aviation</a></li>
        <!--<li><a [routerLink]="['/solutions/SAPiM']">SAPiM V1.0</a></li>-->
      </ul>
      <h4 class="mt-4">Pricing</h4>
      <ul>
          <li><a [routerLink]="['/delivery-pricing/time-and-material']" id="time-material-footer" >Time and Material (T&M)</a></li>
          <li><a [routerLink]="['/delivery-pricing/fulltime-equivalent']" id="ful-time-footer" >Full Time Equivalent (FTE)</a></li>
          <li><a [routerLink]="['/delivery-pricing/firm-fixed-price']" id="firm-fixed-footer" >Firm Fixed Price (FFP)</a></li>
        </ul>
    </div>

  </div>
  <div class="copyrights">

    <p>
      &copy; All Rights Reserved by <a [routerLink]="['']"> JTSi Technologies India</a> Pvt. Ltd.
    </p>
  </div>
</footer>

 <div class="buttn-red" data-bs-toggle="modal" data-bs-target="#letstalk" id="Talk-to-us-button">Talk <span>to Us</span>
  
</div> 
<!-- Modal -->
<div class="modal fade" id="letstalk" tabindex="-1" aria-labelledby="letstalkLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Let’s get to know</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="ltsTalk">
          <div class="filltheForm">
            <p>There is an greater chance we might be able to help you out.  Please submit the form below:</p>
            <form class="form" novalidate #talktous="ngForm" (ngSubmit)="talktous.form.valid && onSubmit(talktous)">
              <div class="formGroup">
                <label>Full Name</label>
                <input type="text" name="fullname" #tkt_Name="ngModel" [(ngModel)]="talktousservice.formData.tkt_Name"
                       class="form-control" [ngClass]="{'is-invalid': talktous.submitted &&  tkt_Name.invalid}" required maxlength="50">
              </div>
              <div class="formGroup">
                <label>Email</label>
                <input type="email" name="email" #tkt_Email="ngModel" [(ngModel)]="talktousservice.formData.tkt_Email"
                       class="form-control" [ngClass]="{'is-invalid': talktous.submitted &&  tkt_Email.invalid}" required maxlength="100">
              </div>
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #tkt_Phone="ngModel" [(ngModel)]="talktousservice.formData.tkt_Phone"
                       class="form-control" [ngClass]="{'is-invalid': talktous.submitted &&  tkt_Phone.invalid}" required maxlength="20"
                       (hasError)="hasError($event,talktous)"
                       [ng2TelInputOptions]="{autoPlaceholder:'false'}"
                       (countryChange)="onContryChange($event,talktous)">
                <input type="hidden" name="countryCode" class="form-control" #CountryCode="ngModel" [(ngModel)]="talktousservice.formData.countryCode">
              </div>
              <div class="formGroup">
                <label>Your Message</label>
                <textarea cols="5" rows="5" name="message" #tkt_Message="ngModel" [(ngModel)]="talktousservice.formData.tkt_Message"
                          maxlength="3000"></textarea>
              </div>

              <button class="submitBrochure" type="submit" id="Talk-to-us-submit-download">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#pdf"></use>
                  </svg>
                  Submit & Download Brochure
                </button>
            </form>
          </div>
          <div class="addresses">
              <div class="contacts">
                  <i class="icon ion-md-call"></i><a href="tel:+12024645874"> +1(202)464-JTSi(5874)</a>
                  <i class="icon ion-md-mail"></i> <a href="mailto:jtsiindia.com">info@jtsiindia.com </a>
                  </div>
                  <h4>KOCHI-INDIA OFFICE </h4>
                  <ul>
                  <li>Infopark, Kakkanad,</li>
                  <li>Kochi – 682042, Kerala, India.</li>
                  <li class="bold"><i class="icon ion-md-call"></i><a href="tel:+914844070368"> +91 484 407-0368 </a></li>
                  </ul>
                  
                  <h4>BANGALORE-INDIA OFFICE</h4>
                  <ul>
                  <li>Doresani Palya, Near Kalyani Infopark,</li>
                  <li>Bengaluru, Karnataka, India (HQ).</li>
                  <li class="bold"><i class="icon ion-md-call"></i><a href="tel:+918041114111"> +91 80 4111-4111 </a></li>
                  </ul>
           
            <h4>Other ways to contact us</h4>
            <ul>
              <li><a [routerLink]="['/contact-us']"  data-bs-dismiss="modal">Find an office</a></li>
              <li><a [routerLink]="['/careers']"  data-bs-dismiss="modal">Search jobs and submit your resume </a></li>
            </ul>
              <div class="findUs">

                      <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/jtsitechnologies">
                        <svg>
                          <use xlink:href="../../assets/img/sprite.svg#faseBuk"></use>
                        </svg>
                      </a>
                      <a target="_blank" href="https://www.linkedin.com/company/jtsi-technologies-india">
                        <svg>
                          <use xlink:href="../../assets/img/sprite.svg#Lenkden"></use>
                        </svg>
                      </a>
                      <a target="_blank" href="https://twitter.com/Jtsiindia">
                        <svg>
                          <use xlink:href="../../assets/img/sprite.svg#Tweetter"></use>
                        </svg>
                      </a>
                      <a target="_blank" href="https://in.pinterest.com/jtsitechnologiesindia">
                        <svg>
                          <use xlink:href="../../assets/img/sprite.svg#pinttrest"></use>
                        </svg>
                      </a>
                      <a target="_blank" href="https://www.instagram.com/jtsiindia/">
                        <svg>
                          <use xlink:href="../../assets/img/sprite.svg#instalogo"></use>
                        </svg>
                      </a>
              
                      <a target="_blank" href="https://www.youtube.com/channel/UCcnZRAkcseIL8MbR47A6wlA">
                        <svg>
                          <use xlink:href="../../assets/img/sprite.svg#youtube"></use>
                        </svg>
                      </a>
                   
                  
                <!-- <a target="_blank" href="https://www.instagram.com/jtsiindia/"><i class=" ion-logo-instagram"></i> </a> -->
                    </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Side bar for manage-cookies -->
<nav class="cookiesidebar" [class.sidebar-slide-in]="sidebarShow">
  <div class="cookieoptions">
  <button type="button" class="btn-close"  (click)="sidebarShow=!sidebarShow" ></button>
  <div class="cookoptioncontent">
  <ul id="cookienavmenu">
  
    <li  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
      <form class="form" novalidate [formGroup]="frmMngCookies" (ngSubmit)="manageCookies(frmMngCookies.value)">
<ul class="choosecookies">
  <li>  <span>
              <label>Essentials</label>
              <input type="checkbox" formControlName='essentials' [(ngModel)]="essentials" disabled>
            </span>
       
                <p>Website the you visit may use cookies and other similar technologies (referred to as "cookies")
                  to store or retreive information about you. Cookies could be required for the webiste's fundamental 
                  functionality and other uses. You can opt to disable some types of cookies, but doing so might alter 
                  how the webiste works for you.
                </p>
                <p>Need to enable fundamental webiste features and cannot be disabled.</p>
          </li>
          <li>
            <span>
            <label>Target Advertising</label> 
            <input type="checkbox" formControlName='targetadvertise'  [(ngModel)]="targetadvertise" >
          </span>
            <p>Used to send marketing that is more appropriate to your interests and needs. It may also be used 
              to control  the freequency of advertisements you view and assess to success of marketing initiatives.
               They are often placed by advertising networks with the consent of the website owner.
           </p>
          </li>
          <li>
            <span>
            <label>Analytics</label>
          <input type="checkbox" formControlName='analytics' [(ngModel)]="analytics">
        </span>
          <p>Gives the website permission to remember preferences you made (such as  your username, language, 
            or the area you are in) s it can offer better, more individualized features. By preserving information
            about  your general location, a website might, for instance, give you access to regional weather reports
           or traffic updates.</p>
        </li>
        <li>
          <span>
            <label>Personalisation</label>
            <input type="checkbox" formControlName='personalisation' [(ngModel)]="personalisation">
          </span>
            <p>Aid the website owner in understanding how well the site functions, how users interact with it, 
              and whether any technical problems might be present.
            </p>
          </li>
          </ul>
    
        <button class="btn btn-md btn-primary" type="submit" >
            Save
          </button>
      </form>
    </li>
    </ul>
  </div>
  </div>
    </nav>
    <div class="cookie-bar" *ngIf="showCookiePopup==false">
    
         <article>
        <p>
          by visiting our website,  <a [routerLink]="['']">www.jtsiindia.com.</a>, you are conserting to us using cookies to provide you with a better experience and enable
          us to improve our service. If you choose to continue to explore the website you are agreeing to the use of cookies.
          For more information, please read our policy.
        </p>
         </article>
         <div class="btns">
          <button class="btn btn-lg btn-primary"  (click)="showPreference()" >Manage Preferences</button>
         
        </div>
     
    </div>
   <div class="cookiechoice" *ngIf="isShowBtn==true">
    <button   (click)="getCookie()" >Manage Cookies</button>
   </div>
<!-- END -->

<!-- <div class="mobilebeta"> Beta Version </div> -->

<!-- Modal -->
<!-- <div class="modal fade" id="forgetModal" tabindex="-1" aria-labelledby="forgetModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="forgetModalLabel">Having Trouble Logging In?</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="formGroup">
          <p>Enter your email/JTSi id to reset your password.</p>
          <input type="email" placeholder="Enter Your Email">
        </div>
        <div class="text-right">
          <button class="btn btn-md btn-info" type="submit">Continue</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <app-decorations></app-decorations> -->