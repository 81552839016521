import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-activatemethodology',
  templateUrl: './activatemethodology.component.html',
  styleUrls: ['./activatemethodology.component.scss']
})
export class ActivatemethodologyComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('SAP Activate Methodologies in New Jersey |USA | Next Gen Agile Technology | JTSi Technologies');
  
    this.meta.addTags([
      {name:'description', content:'The SAP Activate methodology, developed by JTSi, provides ready-to-use business process implementations with a next-generation agile approach to quality and rapid software deployment based on the Activate methodology'},
      {name :'description', content:'en-US',property:'og:locale'},
     {name :'description', content:'website',property:'og:type'},
     { property :'og:title', content:'" SAP Activate Methodologies in New Jersey |USA |  Next Gen Agile Technology | JTSi Technologies'},
   
     { property :'og:description', content:'"SAP Activate methodology, developed by JTSi, enables ready-to-use business process implementations that are coupled with a next-generation agile approach to rapid deployment of the S/4HANA software implementation. '},
         { property:'og:url',content:'https://www.jtsiindia.com/delivery-pricing/activate-methodology' },
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Activating-Methodology.webp' } ,                                                         
      { property:'og:site_name', content:'JTSi Technologies India'},
     {property:'article:modified_time', content:'fetch current time'},
     {name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'SAP Activate Methodologies in New Jersey |USA |  Next Gen Agile Technology | JTSi Technologies'},
     { name:'twitter:description', content:'SAP Activate methodology, developed by JTSi, enables ready-to-use business process implementations that are coupled with a next-generation agile approach to rapid deployment of the S/4HANA software implementation. '},
   {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Activating-Methodology.webp'}
  ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
