
<div class="innerHeader capabilities Solutions">
  <div class="innerHeadings">
    <!-- <h4>Capabilities</h4> -->
    <!-- <h3>Software </h3> -->
    <h1>Solutions</h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>
<section class="grayBg commonPadding" id="Deployables">
  <div class="texttitle_two">
    <h2 class="nocase">Solutions</h2>
  </div>
  <div class="containers homeGrid">
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/og_thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          OfficeGaps
        </h4>

        <a [routerLink]="['/solutions/OfficeGaps']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/survey_01.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          SurveyShield
        </h4>

        <a [routerLink]="['/solutions/surveyshield']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/mro_thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          MRO for Aviation
        </h4>

        <a [routerLink]="['/solutions/MRO-for-aviation']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

  </div>
</section>
