<div class="innerHeader partner">
  <div class="innerHeadings">
    <h1>Partner <span>with Us</span></h1>
  </div>
</div>
<app-cirlelinks></app-cirlelinks>
<div class="whoweare mb-0">
  <div class="picture">
      <img class="img-fluid" src="../../assets/img/partner_01.webp" alt="JTSi creates win-win partnerships that effectively address the social and environmental challenges">
  </div>
  <div class="texts">
      <div class="heading">
          <div class="redBg">
              <h2>social impact</h2>
          </div>
          <div class="darkBg">
              <h3>We create win-win partnerships that respond effectively to social and environmental challenges</h3>
          </div>
      </div>
      <p>
        Software industry is all about selling quality products that help earning a competitive edge in the IT world. JTSi is the perfect partner that strengthens everyone in the partnership pool with futuristic technologies, innovative approaches and finest products and services. We value client satisfaction foremost and for ensuring their inputs are also taken in during the different processes we follow collaborative approaches.
      </p><p>
        Our partnership with regionally and globally recognized clients dates back to 2004. Our partnerships are firmly based on utopian engagement with our partners and associates. We deal with our Partner’s project requirements as our own and work towards achieving outcomes par excellence. We have a very flexible functional spectrum that can adjust to the latest IT and software industry’s environmental challenges. We have solutions larger than the challenge itself with effective and prompt responsiveness.
      </p>
  </div>
</div>
<div class="containers commonPadding Tpadd0">
<p>
Our decades of partnership services in various business verticals have helped us to gain a large base of partners. The products we have delivered and the quality it possesses is the very reason for our clients support and trust in us. This is the very reason behind our years long partnership and loyal clients.
</p>
</div>
<div class="splitcontent mt-0">
  <div class="grayBg contents leftSide">
   <h4 class="small_title">The Impact We Make!</h4>
    <p>
      JTSi partners to co-develop products that are on par with the organizational priorities and agenda that values clients from regional or global locations.
    </p>
    
     <h4 class="small_title">Employee Engagement</h4>
    <p>
      We have expert employees working on various projects. We make sure that their expertise is rightly invested which catalyzes mutual growth of both the company and its employees.
    </p>
     <h4 class="small_title">Associate with Discerning Minds</h4>
    <p>
      Nurturing our partners is always a dutiful matter for us. Our in-depth knowledge and industry experience will enrich your profile needs. JTSi’s network array musters talented entrepreneurial minds and a system that reconciles to refashioning market conditions.
    </p>
    
</div>
  <div class="pictures partner_02">
     
  </div>
</div>



