import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';

@Component({
  selector: 'app-key-defferentiators',
  templateUrl: './key-defferentiators.component.html',
  styleUrls: ['./key-defferentiators.component.scss']
})
export class KeyDefferentiatorsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta

  ) { 
    this.titleService.setTitle('Key Differentiators | Zero debt company | ISO 9001-2015 ');
    
  this.meta.addTags([
    {name:'description', content:'We are a Zero Debt Company with ISO 9001-2015 and NASSCOM certifications, serving 200+ global clients, including Fortune 500 companies.'},
    { property:'og:locale', content:'en_US'},
    { property:'og:type', content:'website'},
    { property:'og:title', content:' Key Differentiators | Zero debt company | ISO 9001-2015 '},
    { property:'og:description', content:'We are a Zero Debt Company with ISO 9001-2015 and NASSCOM certifications, serving 200+ global clients, including Fortune 500 companies.'},
    { property:'og:url', content:'https://www.jtsiindia.com/key-differentiators'},
    { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Key-Differentiators.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
    { property:'article:modified_time', content:'fetch current time'},
    { name:'twitter:card', content:'summary_large_image'},
    { name:'twitter:site', content:'@Jtsiindia'},
    { name:'twitter:creator', content:'@Jtsiindia'},
    { name:'twitter:title', content:'Key Differentiators | Zero debt company | ISO 9001-2015'},
    { name:'twitter:description', content:'We are a Zero Debt Company with ISO 9001-2015 and NASSCOM certifications, serving 200+ global clients, including Fortune 500 companies.'},
    { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Key-Differentiators.webp'},
      
  ]);
  
  }

  ngOnInit(): void {
  }

}
