import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-qualcheckers',
  templateUrl: './qualcheckers.component.html',
  styleUrls: ['./qualcheckers.component.scss']
})
export class QualcheckersComponent implements OnInit {

  constructor(private titleService: Title,
    private meta: Meta,) { 
      this.titleService.setTitle('QualCheckers | AI Powered Online Survey creation software solutions in USA | JTSi Technologies.');
    
      this.meta.addTags([
        {name:'description', content:'JTSi helps to create and conduct administrative professional online surveys using QualCheckers, Leading Automated survey panel management software solutions in USA, UK, and Canada.'},
        {name:'keywords', content:'AI Powered survey solution, Survey creation software, Survey Management, Online survey maker, Survey creating tool.'}
      ]) }

  ngOnInit(): void {
  }

}
