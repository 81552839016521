import { Injectable, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonfunctionsService {

  constructor(@Inject(DOCUMENT)private _document,) { }
  validateEmail(email) {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,::\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegExp.test(String(email).toLowerCase());
  }

  validatePhoneAndFax(number, countryCode) {
    let phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
    switch (countryCode) {
      case 'us':
        {
          phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          break;
        }
      case 'india':
        {
          phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/
          break;
        }
      case 'uk':
        {
          phoneRegExp = /^(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}$/
          break;
        }
    }
    let val=phoneRegExp.test(String(number));
    if (val == true) {
      let cleaned = ('' + number).replace(/\D/g, '');
      if (cleaned != null && cleaned.startsWith('91') || cleaned.startsWith('44')) {
        cleaned = cleaned.substring(2);
      }
      switch (countryCode) {
        case 'us':
          {
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (!match) {
              return false;
            }
          }
        case 'india':
          {
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (!match) {
              return false;
            }
          }
        case 'uk':
          {
            let match = cleaned.match(/^(\d{4})(\d{6})$/);
            if (!match) {
              return false;
            }
          }
      }
    }
    else {
      return false;
    }
    return true; 
  }
  validateWebsite(website){
    let websiteRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]$/
    return websiteRegExp.test(String(website).toLowerCase());
  }
  validatePhoneNumber(phone){
    let phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegExp.test(String(phone).toLowerCase());
  }

  CreateCanonicalURL(){
    let link:HTMLLinkElement=this._document.createElement('link');
    link.setAttribute('rel','cannonical');
    this._document.head.appendChild(link);
    link.setAttribute('href',this._document.URL);
  }

  public setJsonLd(Renderer2:Renderer2,data:any){
  let script = Renderer2.createElement('script');
  script.type = 'application/Id+json';
  script.text = `${JSON.stringify(data)}`;
  Renderer2.appendChild(this._document.head,script)
}
 



}




