
<div class="innerHeader testimonials">
  <div class="innerHeadings">
    <h1>Client   <span>Testimonials</span></h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>
<div class="containers commonPadding">
  <div class="client_testimonial">
    <p>The SEO team at JTSi is a very professional team. Two things that make them stand out in my opinion: on time and effective communication. Always keeping me updated with the progress of the work. Two, very ethical. During my research, I found out that there are loads of companies that use shortcut ranking/SEO methods which lead to falling foul with Google. I have found that the SEO team at JTSi includes resources with great integrity and advised me against using any unethical methods to gain ranking! Our website is at the top of the search results for the most important keyword and the credit for this goes to JTSi. I am so pleased that I chose them over 100s of others!</p>
    <h5>One of our many customers in the UK</h5>
  </div>
  <div class="client_testimonial">
    <p>My appreciation and thanks to the JTSi team for the great job done on the SEO project for one of our e-commerce sites. Your suggested modifications have helped us to optimize our SEO work in order to improve our rankings. We appreciate your time and effort put in this project. It has been a pleasure to work with your motivated team on this project.</p>
    <h5>One of our many customers in Germany</h5>
  </div>
</div>
