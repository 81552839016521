import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-software-factory',
  templateUrl: './software-factory.component.html',
  styleUrls: ['./software-factory.component.scss']
})
export class SoftwareFactoryComponent implements OnInit {

  constructor(

    private titleService: Title,
    private meta: Meta,
    private commonfunctions: CommonfunctionsService

  ) {
    this.titleService.setTitle('Software Factory Company | Software Manufacturing and Operations | JTSi Technologies');
    this.meta.addTags([
      { name: 'description', content: 'Our software manufacturing and operations team develops custom built, High performance-oriented software solutions and products for US, UK, Canada and Europe regions.' },
      { name: 'keywords', content: 'Agile Software Factory, Software manufacturing company, software development operating model' },
      { name: 'description', content: 'Our software manufacturing and operations team develops custom built, High performance-oriented software solutions and products for US, UK, Canada and Europe regions.' },
      { name: 'keywords', content: 'Agile Software Factory, Software manufacturing company, software development operating model' },
      { name: 'description', content: 'en-US', property: 'og:locale' },
      { name: 'description', content: 'website', property: 'og:type' },
      { property: 'og:title', content: '" Software Factory Company | Software Manufacturing and Operations | JTSi Technologies  ' },

      { property: 'og:description', content: '"Our software manufacturing and operations team develops custom built, High performance-oriented software solutions and products for US, UK, Canada and Europe regions. ' },
      { property: 'og:url', content: 'https://www.jtsiindia.com/capabilities/software-factory' },
      { property: 'og:image', content: 'https://www.jtsiindia.com/assets/img/OGinnerhead.webp' },
      { property: 'og:site_name', content: 'JTSi Technologies India' },
      { property: 'article:modified_time', content: 'fetch current time' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@Jtsiindia' },
      { name: 'twitter:creator', content: '@Jtsiindia' },
      { name: 'twitter:title', content: 'Software Factory Company | Software Manufacturing and Operations | JTSi Technologies' },
      { name: 'twitter:description', content: 'Our software manufacturing and operations team develops custom built, High performance-oriented software solutions and products for US, UK, Canada and Europe regions. ' },
      { name: 'twitter:image', content: 'https://www.jtsiindia.com/assets/img/OGinnerhead.webp' }
    ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
