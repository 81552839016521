import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-sap',
  templateUrl: './sap.component.html',
  styleUrls: ['./sap.component.scss']
})
export class SapComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) 
    { 
    this.titleService.setTitle('SAP Development and Support Company in USA | New Jersy | JTSi Technologies');
    this.meta.addTags([
        {name:'description', content:'US based SAP software development and support company providing  SAP Solutions, SAP applications including Financial SAP services and enterprise resource planning (ERP).'},
        {name:'keywords', content:'SAP Software, SAP Program, SAP Solution'},
        {name :'description', content:'en-US',property:'og:locale'},
        {name :'description', content:'website',property:'og:type'},
        {property :'og:title', content:'" SAP Development and Support Company in USA | New Jersy | JTSi Technologies '},
        {property :'og:description', content:'"US based SAP software development and support company providing  SAP Solutions, SAP applications including Financial SAP services and enterprise resource planning (ERP). '},
        {property:'og:url',content:'https://www.jtsiindia.com/technologies/sap' },
        {property:'og:image', content:'https://www.jtsiindia.com/assets/img/sapInnerhead.webp' } ,                                                         
        {property:'og:site_name', content:'JTSi Technologies India'},
        {property:'article:modified_time', content:'fetch current time'},
        {name:'twitter:card', content:'summary_large_image'},
        {name:'twitter:site', content:'@Jtsiindia'},
        {name:'twitter:creator', content:'@Jtsiindia'},
        {name:'twitter:title', content:'SAP Development and Support Company in USA | New Jersy | JTSi Technologies'},
        {name:'twitter:description', content:'US based SAP software development and support company providing  SAP Solutions, SAP applications including Financial SAP services and enterprise resource planning (ERP). '},
        {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/sapInnerhead.webp'}
        ]);
    }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
