<script type="application/ld+json">
  {
   "@context": "http://schema.org",
   "@type": "FAQPage",
   "@id": "https://www.jtsiindia.com/faq",
   "mainEntity": [
     { 
       "@type": "Question",
       "name": "What is your experience? Which are the projects that you undertake?",
       "acceptedAnswer": {
         "@type": "Answer",
         "text": "Established 18 years ago, JTSi provides superior web, mobile, and cloud development services, as well as IT consulting services and business solutions to our clients worldwide. Since its inception, JTSi has grown as a well-established and reliable organization with over 250+ satisfied clients worldwide, ranging from startups to Fortune 500 companies across diverse industries. Our areas of expertise lies in the following verticals:\n\nEnterprise and custom software Development\nInformation Technology Staffing (On-shore/Off-shore model)\nProfessional Consulting services (Consultants/Contractors)\nUX and UI Engineering & Independent Testing\nSecurity and Server Technology Support\nIntegration Services, Application Services, Data Warehousing & Business Intelligence Services\nUS Recruitment, Quickbooks Accounting, SEO and Internet Marketing\nAs a result of our team members' combined business experience and strong software development skills, our approach is focused, sound and innovative.\n\nThrough the use of the latest cutting-edge technologies, we are able to understand the Client's business needs, propose creative IT strategies in line with the business vision, and implement the business plan through building or implementing high quality scalable business solutions."
       }
     },
     {
       "@type": "Question",
       "name": "What are your core capabilities?",
       "acceptedAnswer": {
         "@type": "Answer",
         "text": "Microsoft technologies\nJava technologies\nCustom software development\nWebsite design\nE-commerce solutions\nRecruitment and Accounting services\nRemote Staffing\nQuality Assurance\nCloud Application Development"
       }
     },
     {
       "@type": "Question",
       "name": "What are your technological capabilities?",
       "acceptedAnswer": {
         "@type": "Answer",
         "text": "Database: MS SQL Server, MySQL, Oracle and MS Access\nProgramming Languages: ASP.NET, C#, VB Script, Java Script, ASP, PHP, XML, Java, HTML, J2EE technology, Application Servers (WebLogic, JBoss, Websphere, etc.)\nOOAD Tools: Rational, Visio, UML\nEmbedded / mobile solutions (J2ME, WinCE, eVB, eVC++, PocketPC, WAP / WML, etc.)\nDevelopment Tools: MS Visual Studio 2003, LLBLGenPro\nGraphic Tools: Adobe Photoshop, Macromedia FlashMX, Dream Weaver MX, Corel Draw\nSource Control: VSS\nServers: Advanced server technologies like Apache"
       }
     },
     {
       "@type": "Question",
       "name": "What is your development work methodology?",
       "acceptedAnswer": {
         "@type": "Answer",
         "text": "We follow a process-centric approach for our development projects. For us, innovative products and satisfied clients are what we aim at. We are committed to provide maximum benefits to our customers. For more optimal, processes, methodologies and project deliverables, contact us!"
       }
     }
   ]
 }
</script>

 





<div class="innerHeader  faq">
  <div class="innerHeadings">
    <!-- <h4>technologies</h4> -->
    <h1>FAQ</h1>
  </div>
</div>
<app-cirlelinks></app-cirlelinks>


<div class="containers commonPadding">

  <div class="commonquestions">
    <h4 class="mt-0">Our Company</h4>
<div class="freeaqs open">
    
    <h5 class="ottachodyam">What is your experience? Which are the projects that you undertake?</h5>
    <div class="viewdetails ">
        <p>
          Established in the year 2004, JTSi provides superior web, mobile, and cloud development services, as well as IT consulting services and business solutions to our clients worldwide.  Since its inception, JTSi has grown as a well-established and reliable organization with over 250+ satisfied clients worldwide, ranging from startups to Fortune 500 companies across diverse industries. Our areas of expertise lies in the following verticals:
        </p>
         <ul class="bulletpoints">
          <li>Enterprise and custom software Development</li>
          <li>Information Technology Staffing (On-shore/Off-shore model)</li>
          <li>Professional Consulting services (Consultants/Contractors)</li>
          <li>UX and UI Engineering & Independent Testing</li>
          <li>Security and Server Technology Support</li>
          <li>Integration Services, Application Services, Data Warehousing & Business Intelligence Services</li>
          <li>US Recruitment, Quickbooks Accounting, SEO and Internet Marketing</li>
        </ul>
          <p>
            As a result of our team members' combined business experience and strong software development skills, our approach is focused, sound and innovative.
          </p><p>
Through the use of the latest cutting-edge technologies, we are able to understand the Client's business needs, propose creative IT strategies in line with the business vision, and implement the business plan through building or implementing high quality scalable business solutions.
          </p>

          
    </div>
</div>
<div class="freeaqs closed">
    
  <h5 class="ottachodyam">What are your core capabilities?</h5>
  <div class="viewdetails ">
  
       <ul class="bulletpoints">
        <li>Microsoft technologies</li>
<li>Java technologies</li>
<li>Custom software development</li>
<li>Website design</li>
<li>E-commerce solutions</li>
<li>Recruitment and Accounting services</li>
<li>Remote Staffing</li>
<li>Quality Assurance</li>
<li>Cloud Application Development</li>
      </ul>

  </div>
</div>

<div class="freeaqs closed">
    
<h5 class="ottachodyam">What are your technological capabilities?</h5>
<div class="viewdetails ">

     <ul class="bulletpoints">
      <li>Database: MS SQL Server, MySQL, Oracle and MS Access</li>
<li>Programming Languages: ASP.NET, C#, VB Script, Java Script, ASP, PHP, XML, Java, HTML, J2EE technology, Application Servers (WebLogic, JBoss, Websphere, etc.)</li>
<li>OOAD Tools: Rational, Visio, UML</li>
<li>Embedded / mobile solutions (J2ME, WinCE, eVB, eVC++, PocketPC, WAP / WML, etc.)</li>
<li>Development Tools: MS Visual Studio 2003, LLBLGenPro</li>
<li>Graphic Tools: Adobe Photoshop, Macromedia FlashMX, Dream Weaver MX, Corel Draw</li>
<li>Source Control: VSS</li>
<li>Servers: Advanced server technologies like Apache</li>
    </ul>

</div>
</div>

<div class="freeaqs closed">
    
<h5 class="ottachodyam">What is your development work methodology?</h5>
<div class="viewdetails ">

<p>
We follow a process-centric approach for our development projects. For us, innovative products and satisfied clients are what we aim at. We are committed to provide maximum benefits to our customers. For more optimal, processes, methodologies and project deliverables, contact us!
</p>

</div>
</div>
    <h4>Security</h4>
   
    <div class="freeaqs closed">
    
      <h5 class="ottachodyam">How do you ensure client confidentiality?</h5>
      <div class="viewdetails ">   
<p>
JTSi is committed to the security and confidentiality of the information that you share with us. With the aim of giving you a safe outsourcing experience with maximum benefits, we have set up a reliable information security system.
</p><p>
To ensure confidentiality of information, we encourage our customers to sign a Confidential Non-Disclosure Agreement (NDA) at the very initial stage of project discussion. The NDA has legal bindings and is an important document which ensures the total security of your information in our hands. We also do not publish anything about our clients or projects unless we get prior permission. We ensure that your information is always safe and secure with us.
</p>
      </div>
    </div>

    <div class="freeaqs closed">
    
      <h5 class="ottachodyam">Do you have a protected infrastructure and systems?</h5>
      <div class="viewdetails ">
   <p>
    Keeping in mind information security concerns, we have perfectly engineered infrastructure that eliminates any likely leakages in processes or systems.
   </p>
      </div>
    </div>

    <div class="freeaqs closed">
    
      <h5 class="ottachodyam">Employee Login for Enterprise Access</h5>
      <div class="viewdetails ">
      
           <ul class="bulletpoints">
            <li>Secure Smart Card, Secure Premises Login</li>	
<li>Network and Windows Login, PC ‘locking’</li>	
<li>Secure Remote Access (VPN)</li>	
<li>Secure email with digital signatures</li>	
<li>Single sign on to enterprise and desktop applications</li>	
<li>Employees sign confidentiality and Non-Disclosure Agreements</li>	

          </ul>
    
      </div>
    </div>


    <div class="freeaqs closed">
    
      <h5 class="ottachodyam">Secured VPN and Network</h5>
      <div class="viewdetails ">
      
           <ul class="bulletpoints">
            <li>	Total secured CISCO VPN Tunnel to Client, which blocks all Peers to Peep in</li>
<li>	Secured Cisco® Intelligent switches and Cisco® Network Assistant optimized for LAN</li>
<li>	Cisco® PIX® Firewall to block all ports for HTTP, FTP, TCP/IP, UDP and even ICMP</li>

          </ul>
    
      </div>
    </div>

    <div class="freeaqs closed">
    
      <h5 class="ottachodyam">Work Station Security</h5>
      <div class="viewdetails ">
      
           <ul class="bulletpoints">
            <li>	Wide and stage-wise security management for administrative auditing, reporting, and monitoring facilities</li>
            <li>	Records of all hardware and electronic media movements and the individuals responsible are maintained</li>
            <li>	Workstations are secured individually by Anti-Virus Protection and Back-Up Drive Manager</li>
          </ul>
    
      </div>
    </div>

<h4>Contract Agreements</h4>
<div class="freeaqs closed">
    
  <h5 class="ottachodyam">What service level agreements / guarantees do you offer?</h5>
  <div class="viewdetails ">
  <p>
    Before the commencement of the project, we sign a Services Contract that details the responsibilities of each party, the deliverables, pricing, complaint handling mechanisms and delivery schedule of the projects.
  </p><p>
    All payments are milestone-based and made after the client approves of the milestone and its deliverables.

  </p>

  </div>
</div>

<div class="freeaqs closed">
    
  <h5 class="ottachodyam">Would the timescale be agreed upfront? What would happen if this was over/underestimated? What flexibility do you offer?</h5>
  <div class="viewdetails ">
  <p>
    We first develop a Project Plan with an estimated schedule according to the requirements specified by the customer. What matters the most is how well the application aligns to your business.
  </p><p>
    To meet this objective, we shall discuss and review with you both the estimate and the schedule of the project going forward after the completion of the architecture and design phase.

  </p>

  </div>
</div>

<div class="freeaqs closed">
    
  <h5 class="ottachodyam">Can I cancel the agreement or get my money back if I am dissatisfied with the service outcomes?</h5>
  <div class="viewdetails ">
  
<p>
This is covered through clear termination clauses in the contract. However, we try to prevent such a situation arising by providing you with clear problem escalation routes. You can raise any issue to the Operations Manager and the CEO for review and an appropriate solution will be provided.
</p>

  </div>
</div>

<div class="freeaqs closed">
    
  <h5 class="ottachodyam">Would I own the copyright and intellectual property associated with any work conducted by you?</h5>
  <div class="viewdetails ">
  
<p>
This depends on the type of project contract we have entered into. If the arrangement is on the basis of hiring employees (FTE basis), you will own the copyright and intellectual property rights. If we work on a project approach, then we would co-own the copyright. Both parties will have all rights to reproduce/modify or resell the code.
</p>
  </div>
</div>

<h4>Staff</h4>
    <div class="freeaqs closed">
    <h5 class="ottachodyam">How do you ensure employee credibility?</h5>
    <div class="viewdetails">
        <p>
          Employees are recruited into the organization through tested methods of evaluation of capability and integrity. While entering into projects, all the employees involved in the projects are required to sign confidentiality agreements with Stylus Inc. We restrict the access to client information to a limited number of people and employ high level security systems to their workstations.
        </p>
    </div>
</div>

<div class="freeaqs closed">
  <h5 class="ottachodyam">How do I hire your people to work on my project?</h5>
  <div class="viewdetails">
    <p>
      You can hire individual employees or a dedicated team of programmers, software developers and designers who will work exclusively on your project, on a contract basis. We have FTE hiring for smaller team size requirements and remote working with different working times. For large teams, we provide recruitment services for hiring dedicated workforce who have the expertise regarding the project requirements and our teams can handle every aspect of the project.
    </p>
  </div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">Do you have fluent English-speaking representatives?</h5>
<div class="viewdetails">
<p>
  All our people communicate in English.
</p>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">What if clients are unhappy with the programmer’s output?</h5>
<div class="viewdetails">
<p>
  Client satisfaction is our primary concern at each stage. If the client raises a concern regarding a particular team member’s output, we either mend it through proper mentoring or provide a replacement. This is once in a blue moon situation. As JTSi takes utmost care in hiring skillset. 
</p>
</div>
</div>


<h4>Pricing</h4>
<div class="freeaqs closed">
<h5 class="ottachodyam">What sort of pricing strategy do you have in place for development projects?</h5>
<div class="viewdetails">
   <p>
    Our pricing model basically depends on the nature of the project, requirement complexity, team velocity ascertained, and other services selected. However, the general structure of pricing is as follows.
   </p>
   <p>
    For your convenience, we offer 3 different types of pricing options:
   </p>
   <ul class="bulletpoints">
    <li>Firm Fixed Price (FFP)</li>	
    <li>Time and Material (T&M)</li>	
    <li>Full Time Equivalent (FTE)/Rate Card</li>	
    
   </ul>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">How do I pay you?</h5>
<div class="viewdetails">
<p>
JTSi provides the invoice in a detailed format and the clients will be provided with the bank details to which the agreed upon amount is to be credited. The invoice will have all the necessary information so that you can wire payments to our partner bank. 
</p>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">Are there any additional costs?</h5>
<div class="viewdetails">
<p>
The only other costs you would bear would be bank charges you incur while making payments, as well as any third-party software that we have purchased for your clients.
</p>
<p>
We do not charge for the standard tools of our trade such as MS Project, Dreamweaver, Flash, MS Visual Studio or Visio. However, if there is any additional tool you would have us use for a specific project, we may require billing you for the same. In such cases, we will discuss with you beforehand implementing such decisions.
</p>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">Do you offer any discounts for long term project undertakings?</h5>
<div class="viewdetails">
<p>
JTSi already offers our clients a very beneficial pricing structure and these benefits are most for our long-term clients. Pertaining to the client-friendly pricing of JTSi, we do not usually offer any further discounts
</p>
</div>
</div>

<h4>Project</h4>

<div class="freeaqs closed">
<h5 class="ottachodyam">What is the procedure for initiating a project?</h5>
<div class="viewdetails">
<p>
Once you enquire with us about your requirements, our sales team will get back to you within 24 hours. After the initial estimations, our team will be in constant contact with you throughout the following phases of project development:
</p>
<ul class="bulletpoints">
<li>	Research and Requirements Analysis</li>
<li>	Analysis and Design</li>
<li>	Prototype Development</li>
<li>	Code Generation</li>
<li>	Testing</li>
<li>	Technical Maintenance</li>

</ul>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">What information would you require from me?</h5>
<div class="viewdetails">
<p>
A client will be prompted to provide a detailed Request for Proposal (RFP). This will help us in having a good idea of client requirements or else the client only needs to give a rough detailing of requirements, Based on multiple discussions and critically analyzing the requirements, our business team will develop a RFP and submit to clients for their review and approval.
</p>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">How do I send my work to you?</h5>
<div class="viewdetails">
<p>
Most of our projects are web-based and so we can communicate and transfer files through the internet. At the initial stage of communicating project requirements, you can send us a brief description or a document about your project requirements. If you have an application on which you need us to work on or analyze, we will need to get to the bottom of the following factors:
</p>
<ul class="bulletpoints">
<li>	Web server details</li>
<li>	Database details</li>
<li>	FTP details</li>
</ul>
<p>
If the client wants JTSi to work on your server, we will need your Remote Desktop details.
</p>
</div>
</div>

<h4>Testing</h4>

<div class="freeaqs closed">
<h5 class="ottachodyam">Does pricing structure include testing expenses as well?</h5>
<div class="viewdetails">
<p>
Yes, we see testing as part of the development process. We have a dedicated team of testers and testing prices are included in the pricing structure in addition to the development costs. 
</p>
</div>
</div>

<div class="freeaqs closed">
<h5 class="ottachodyam">What are your testing procedures? Does this include load testing?</h5>
<div class="viewdetails">
<p>
  Testing is an integral part of our software development process. It involves a comprehensive assessment of the software to make sure it meets the client's needs. In addition to maintaining software quality, testing provides confidence to our clients. A reliable, accurate, and consistent end product will also ensure lower maintenance costs
</p>
<p>
  Our testing processes are as follows:
</p>
<ul class="bulletpoints">
<li>Planning and Control</li>
<li>Analysis and Design</li>
<li>Implementation and Execution</li>
<li>Evaluating exit criteria and Reporting</li>
<li>Test Closure activities</li>
</ul>
<p>
  We offer load testing services that are designed to test the software under normal load and a predicted peak load to determine the optimum operating capacity and the elements that are responsible for degradation of application performance.
</p>
</div>
</div>

  </div>

</div>
