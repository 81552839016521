import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-mro',
  templateUrl: './mro.component.html',
  styleUrls: ['./mro.component.scss']
})
export class MroComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,
    private _renderer2:Renderer2,@Inject(DOCUMENT) private _document:Document

  ) { 
    this.titleService.setTitle('EMRO | MRO (Maintenance, Repair and Overhaul) Aviation Software Company in USA | JTSi Technologies');
   
    
    this.meta.addTags([
   {name:'description', content:'Our commercial aircraft MRO aviation business management software is fully integrated and comprehensive software solutions to manage, maintenance, engineering and logistics of airlines'},
   {name:'keywords', content:'MRO (Maintenance, Repair and Overhaul) Aviation Software, Aviation business management solution, Commercial Aircraft MRO'},
   { property:'og:locale', content:'en_US'},
   { property:'og:type', content:'website'},
   { property:'og:title', content:'EMRO | MRO (Maintenance, Repair and Overhaul) Aviation Software Company in USA | JTSi Technologies '},
   { property:'og:description', content:'Our commercial aircraft MRO aviation business management software is fully integrated and comprehensive software solutions to manage, maintenance, engineering and logistics of airlines'},
   { property:'og:url', content:'https://www.jtsiindia.com/solutions/MRO-for-aviation'},
   { property:'og:image', content:'https://www.jtsiindia.com/assets/img/emro.webp'},                                                          { property:'og:site_name', content:'JTSi Technologies India'},
   { property:'article:modified_time', content:'fetch current time'},
   { name:'twitter:card', content:'summary_large_image'},
   { name:'twitter:site', content:'@Jtsiindia'},
   { name:'twitter:creator', content:'@Jtsiindia'},
   { name:'twitter:title', content:'EMRO | MRO (Maintenance, Repair and Overhaul) Aviation Software Company in USA | JTSi Technologies'},
   { name:'twitter:description', content:'Our commercial aircraft MRO aviation business management software is fully integrated and comprehensive software solutions to manage, maintenance, engineering and logistics of airlines.'},
   { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/emro.webp'},
    ])
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

    // let script=this._renderer2.createElement('script');
    //       script.type=`application/Id+json`;
    //       script.text=`{
    //         "@context": "http://schema.org/",
    //         "@type": "Product",
    //         "name": "MRO For Aviation",
    //         "url": "https://www.jtsiindia.com/solutions/MRO-for-aviation",
    //         "image": "https://www.jtsiindia.com/assets/img/emro.webp",
    //         "description": "eMRO is such a comprehensive software solution that steers the complex, high valued and sensitive data that has been especially designed for the aviation industry. It falls under the ERP software genre and assimilate the different aspects of airline operations that includes a multitude of modules that take in quoting, contract, sales and purchase, inventory, shop control, pricing, MRO, quality and audit, asset, accounts and finances management.",
    //         "brand": "JTSi India"
    //       }`
    //       this._renderer2.appendChild(this._document.body,script)

  }

}
