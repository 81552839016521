import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-cloud-app',
  templateUrl: './cloud-app.component.html',
  styleUrls: ['./cloud-app.component.scss']
})
export class CloudAppComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) 
    { 
      this.titleService.setTitle('Top Cloud application development solutions | USA| Washington DC | JTSi Technologies');
      this.meta.addTags([
      {name:'description', content:'Are you looking for an outsourcing cloud application development company in Washington DC & New Jersey? We are successfully delivering cloud software products and services in UK, Canada & Europe areas.'},
      {name:'keywords', content:'Cloud application development, Cloud solutions development, Outsourcing cloud app development'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:' Top Cloud application development solutions | USA| Washington DC | JTSi Technologies '},
      {property :'og:description', content:'"Are you looking for an outsourcing cloud application development company in Washington DC & New Jersey? We are successfully delivering cloud software products and services in UK, Canada & Europe areas.'},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/cloud-application-development' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/Cloud-Application-Developement.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Top Cloud application development solutions | USA| Washington DC | JTSi Technologie'},
      {name:'twitter:description', content:'Are you looking for an outsourcing cloud application development company in Washington DC & New Jersey? We are successfully delivering cloud software products and services in UK, Canada & Europe areas.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Cloud-Application-Developement.webp'}
       ]);
    }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
