  
<div class="innerHeader capabilities WhyJTSi">
  <div class="innerHeadings">
    <!-- <h4>Capabilities</h4> -->
    <!-- <h3>Software </h3> -->
    <h1>Why  <span> JTSi</span></h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>
<section class="commonPadding" id="whyJTSi">
  <div class="texttitle_two">
    <h2 class="nocase">Why JTSi</h2>
  </div>
  <div class="containers homeGrid">
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/whoeare.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Who We Are
        </h4>

        <a [routerLink]="['/why-JTSi/who-we-are']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>


    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/value_01.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Value Proposition
        </h4>

        <a [routerLink]="['/why-JTSi/value-proposition']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/news_thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Current News
        </h4>

        <a [routerLink]="['/why-JTSi/current-news']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/partner_01.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Partner with Us
        </h4>

        <a [routerLink]="['/why-JTSi/partner-with-us']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>


    <!-- <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/client-thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Client Testimonials
        </h4>

        <a [routerLink]="['/why-JTSi/testimonials']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/client-thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Employee Testimonials
        </h4>

        <a [routerLink]="['//why-JTSi/employeetestimonials']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div> -->

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/contact_thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Contact Us
        </h4>

        <a [routerLink]="['/contact-us']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

  </div>
</section>
