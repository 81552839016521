import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-product-development',
  templateUrl: './product-development.component.html',
  styleUrls: ['./product-development.component.scss']
})
export class ProductDevelopmentComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService

  ) { 
    this.titleService.setTitle('Custom Software Product Development company in Virginia | USA| JTSi Technologies');
    //this.meta.addTag(});
    //this.meta.addTag();
    this.meta.addTags([

      {name:'description', content:'The leading software engineering and product development company in New Jersey, USA that provides development services for start-ups, corporates, and enterprises.'},
      {name:'keywords', content:'Software Product Development, Custom software product development company, IT Product development services '},
      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:' Custom Software Product Development company in Virginia | USA| JTSi Technologies '},
      { property:'og:description', content:'The leading software engineering and product development company in New Jersey, USA that provides development services for start-ups, corporates, and enterprises.'},
      { property:'og:url', content:'https://www.jtsiindia.com/capabilities/product-development-services'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Product-Development-Services.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Custom Software Product Development company in Virginia | USA| JTSi Technologies'},
      { name:'twitter:description', content:'The leading software engineering and product development company in New Jersey, USA that provides development services for start-ups, corporates, and enterprises.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Product-Development-Services.webp'},
      
    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
