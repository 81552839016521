
<div class="innerHeader  privacy-policy">
  <div class="innerHeadings">
    <!-- <h4>technologies</h4> -->
    <h1>Privacy<span> policy</span></h1>
  </div>
</div>
<app-cirlelinks></app-cirlelinks>

<div class="containers commonPadding">
  <p>
JTSi is synonymous to privacy protection, autonomy adherence and trust creation in the world of digital innovation.
</p><p>
Privacy protection is one area where JTSi’s central focus lies on. The confidentiality of the project, process, product, and security of every information we collect is very crucial. This section of Privacy Policy illustrates the principles followed at JTSi in connection with collection, usage, sharing and processing of data for fulfilling various purposes of projects undertaken by JTSi. The data are usually collected for processing personal information of visitors, users, affiliates, customers, suppliers, representatives, and other stakeholders. 
</p>
<h4 class="subtitles_three">Collection and Usage of Data</h4>
<p>
JTSi may ask you to provide certain personally identifiable information that can be used to contact or identify. The information we ask might include name, email address, phone number and other personal information. This information is collected by JTSi from different parties and will be added to our databases for future calls and mailings regarding service updates, new products and services, and upcoming events. We use personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes. This information shall be used only after collecting the consent from the concerned individual or as law prescribes. The lawfully collected data and other information will be applied for various needs as and when the project demands. The collected data shall be strictly confidential and will not be shared to illicit third parties at any cost.
</p>
<h4 class="subtitles_three">Security</h4>
<p>
Data security is very important at JTSi. We take care of ensuring security and encrypting data on par with the best practices with full integrity given to personally identifiable information. We are committed to making reasonable efforts, in accordance with market best practices, to ensure the security of Personal Information. We take great care in implementing and maintaining the security of the Software and the Personal Information.
</p>
<p>
JTSi takes every precautionary measure for every sensitive information shared with us and whether in offline mode or online mode, your data will be guaranteed with maximum safeguarding.
</p>
<h4 class="subtitles_three">Updates or Amendments to the Privacy Policy</h4>
<p>
JTSi may revise this Privacy Policy from time to time, in our sole discretion keeping your privacy as our top priority, and the most current version will always be posted on the website. We encourage you to review this Privacy Policy regularly for any changes. In the event of material changes, we will notify you through our software or by email.
</p>
<p>
Your continued use of the Software, following the notification of such amendments, constitutes your acknowledgement and consent of such amendments to the Privacy Policy and your agreement to be bound by the terms of such amendments.
</p>
    </div>