export class Applynow {
    res_ID:number=0;
    res_Name:string='';
    res_Phone:string='';
    res_Email:string='';
    res_Message:string='';
    res_File:string='';
    res_BOReqID:string='';
    countryCode:string=''
}
