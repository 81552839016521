import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-business-technology',
  templateUrl: './business-technology.component.html',
  styleUrls: ['./business-technology.component.scss']
})
export class BusinessTechnologyComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Business management technology consulting services in USA |Washington DC | JTSi Technologies');
    
  
    this.meta.addTags([

  {name:'description', content:'JTSi\'s corporate digital business and technology consulting services boosts your business outputs by business strategic development and Improves business process smoothly.'},
  {name:'keywords', content:'Business Technology consulting, Business strategy consulting, Corporate consulting services'},
  { property:'og:locale',content:'en_US'},
	{ property:'og:type',content:'website'},
	{ property:'og:title',content:'Business management technology consulting services in USA |Washington DC | JTSi Technologies '},
	{ property:'og:description',content:'JTSi’s corporate digital business and technology consulting services boosts your business outputs by business strategic development and Improves business process smoothly.'},
	{ property:'og:url',content:'https://www.jtsiindia.com/capabilities/business-technology-consulting'},
	{ property:'og:image',content:'https://www.jtsiindia.com/assets/img/Business-Technology-Consulting.webp'},                                                           { property:'og:site_name',content:'JTSi Technologies India'},
  { property:'article:modified_time',content:'fetch current time'},
  { name:'twitter:card',content:'summary_large_image'},
  {name:'twitter:site',content:'@'},
  { name:'twitter:creator',content:'@'},
  { name:'twitter:title',content:'Business management technology consulting services in USA |Washington DC | JTSi Technologies'},
  { name:'twitter:description',content:'JTSi’s corporate digital business and technology consulting services boosts your business outputs by business strategic development and Improves business process smoothly.'},
  { name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/Business-Technology-Consulting.webp'},
      
    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

    
  }

}
