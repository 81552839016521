import { Injectable } from '@angular/core';
import { Contact } from './contact.model';
import { HttpClient } from "@angular/common/http";
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http:HttpClient) { }
  formData:Contact=new Contact();
    
  postContactDetail(){
    //return forkJoin(this.http.post(this.baseURL,this.formData),this.http.post(this.BOURL,this.formData)) 
    /*return this.http.post(this.BOURL, null, {
      params: {
        firstName: this.formData.ctD_Name,
        emailid: this.formData.ctD_Email, 
        phoneNumber: this.formData.ctD_Phone, 
        websiteAddress: this.formData.ctD_Website, 
        message: this.formData.ctD_Message
      }});
     */ 
   return forkJoin(this.http.post(`${environment.apiServer}ContactDetail`,this.formData),this.http.post(`${environment.boApiServer}Lead/saveLeadDetails`, null, {
      params: {
        firstName: this.formData.ctD_Name,
        emailid: this.formData.ctD_Email, 
        phoneNumber: this.formData.ctD_Phone, 
        websiteAddress: this.formData.ctD_Website, 
        message: this.formData.ctD_Message
      }}));
  }
  postquickContactDetail(){
   this.formData.ctD_Name="Quick Contact";
   return forkJoin(this.http.post(`${environment.apiServer}ContactDetail`,this.formData),this.http.post(`${environment.boApiServer}Lead/saveLeadDetails`, null, {
    params: {
      firstName:"Gitex Contact"+"¥"+"Quick Contact",
      emailid: this.formData.ctD_Email, 
      phoneNumber: this.formData.ctD_Phone, 
      websiteAddress: this.formData.ctD_Website, 
      message: this.formData.ctD_Message
    }}));
  }
}
