import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-deliverypricinggrid',
  templateUrl: './deliverypricinggrid.component.html',
  styleUrls: ['./deliverypricinggrid.component.scss']
})
export class DeliverypricinggridComponent implements OnInit {

  constructor(
    
     private titleService: Title,
     private meta: Meta,
     public commonfunctions: CommonfunctionsService,

  ) { 
      this.titleService.setTitle('JTSi Technologies: Software Pricing | Delivery Model | Development Methods in USA and Indias');

     this.meta.addTags([
     {name:'description', content:'Our team uses various software delivery models to prepare your software for market with cost-effective software pricing and development model in line with your project specifications.'},
     { property:'og:locale', content:'en_US'},
     { property:'og:type', content:'website'},
     { property:'og:title', content:'JTSi Technologies : Software Pricing | Delivery Model | Development Methods in USA and India'},
     { property:'og:description', content:'Our team uses various software delivery models to prepare your software for market with cost-effective software pricing and development model in line with your project specifications.'},
     { property:'og:url', content:'https://www.jtsiindia.com/assets/img/Delivery_header.webp'},
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Delivery_header.webp'},                                                          { property:'og:site_name', content:'JTSi Technologies India'},
     { property:'article:modified_time', content:'fetch current time'},

     { name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'JTSi Technologies : Software Pricing | Delivery Model | Development Methods in USA and India'},
     { name:'twitter:description', content:'Our team uses various software delivery models to prepare your software for market with cost-effective software pricing and development model in line with your project specifications.'},
     { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Delivery_header.webp'},
     ])
    }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  }

}
