<div class="promoheader">
  <article>
  <h1>
     Hire Dedicated Teams that <strong>Align with Your Goals</strong> 
  </h1>
  <p>
     Build a team that deliver results and complete <br> projects on time and within budget
  </p>
  <button id="landing-letus-discuss-project" data-bs-toggle="modal" data-bs-target="#meetingschedule">LET'S DISCUSS YOUR PROJECT</button>
  </article>
  <picture>
  <img src="../../../assets/img/promoheader.webp" alt="jtsi promotion">
  </picture>
  </div>
  <div class="containers">
  <div class="prompoints">
  <div>
  <h4>
    Our professionals will work alongside your internal teams to drive expansion and growth
  </h4>
  <ul class="tickUl">
  <li>Top talent pool with extensive industry-specific experience across several technologies and domains</li>
  <li>Better transparency, collaboration, and control over resources with dedicated account managers and in-house PMS tool </li>
  <li>High degree of adaptability for resource adjustments and ramping up to meet any shifting project requirements</li>
  
  </ul>
  </div>
  <div>
  <p>
  JTSi Technologies is a leading provider of highly skilled, talented, and experienced technology professionals for all your software development and IT based projects. Hire our dedicated resources or opt for Teams as a Service(TaaS) offering to execute your projects smoothly and quickly achieve your business goals
  </p>
  <ul class="colorbloks">
  <li>
     <p><strong>18+</strong>  Years of Experience</p> 
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#experience"></use>
    </svg>
  </li>
  <li>
  <p><strong> 130+</strong>  Employees</p> 
   <svg>
   <use xlink:href="../../assets/img/sprite.svg#newemps"></use>
     </svg>
   </li>
  </ul>
  </div>
  </div>
  </div>
  <section class="grayBg commonPadding">
  <div class="containers">
  <div class="promoforms">
  <article>
   <div class="lefttitles">
  <h4>
  How It Works
  </h4>
  <h2>
  We Support <strong>Your Success</strong>
  </h2>
  </div>
  <p>
  Quickly meet resource demands and bridge the skills gap to meet your project objectives without the hassles of recruitment and training. Speed up your time to market and minimize risks by choosing the required skill set that best fits the project at lowest rates.
  </p>
  <h5>
  Dedicated Resources
  </h5>
  <p>
  Hire experts who will work on your project full-time and exclusively for you
  </p>
  <h5>
  Teams as a Service(TaaS)
  </h5>
  <p>
  Hire a group of specialists chosen for their skillset and experience based on your project demands
  </p>
  <div class="promomedias">
  <h6>
  Connect with us on
  </h6>
  <ul>
  <li>
  <a href="https://twitter.com/Jtsiindia">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newtwitter"></use>
    </svg>
  </a>
  </li>
  <li>
  <a href="https://www.linkedin.com/company/jtsi-technologies-india">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newlinkedin"></use>
    </svg>
  </a>
  </li>
  <li>
  <a href="https://www.facebook.com/jtsitechnologies">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newfacebook"></use>
    </svg>
  </a>
  </li>
  <li>
  <a href="https://www.youtube.com/channel/UCcnZRAkcseIL8MbR47A6wlA">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newyoutube"></use>
    </svg>
  </a>
  </li>
  <li>
  <a href="mailto:bd@jtsiindia.com">
     <img src="../../../assets/img/gmail.svg" alt="">
  </a>
  </li>
  <li>
  <a href="#">
  <img src="../../../assets/img/whatsapp.svg" alt="">
  </a>
  </li>
  <li>
  <a href="tel:12024645874">
  <img src="../../../assets/img/call.svg" alt="">
  </a>
  </li>
     
  </ul>
  </div>
  </article>
  <div class="formbox">
  <div class="formboxcontent">
  <h3>
  Let's talk business
  </h3>

  <form class="form" (ngSubmit)="onSubmitContactfrm(frm1)" #frm1="ngForm">

  <div class="formGroup">

  <label for="">Full name</label>

  <input type="text" name="fullnamecntctfrm" #fullnamecntctfrm="ngModel" [(ngModel)]="fullname1"
    class="form-control" [ngClass]="{'is-invalid': frm1.submitted &&  fullnamecntctfrm.invalid}" required minlength="5" maxlength="50">
                                          
                                         
  <!-- <input type="text" placeholder="full name"> -->
  
</div>
  <div class="formGroup">
  <label for="">Phone number</label>
  <input type="text" ng2TelInput name="phoneNumber" #tkt_Phone="ngModel" [(ngModel)]="phoneNumber" style="padding-left: 52px"
  class="form-control" [ngClass]="{'is-invalid': frm1.submitted &&  tkt_Phone.invalid}" required maxlength="20"
  (hasError)="hasError($event,frm1)"
  [ng2TelInputOptions]="{autoPlaceholder:'false'}"
  (countryChange)="onContryChange($event,frm1)"
  >
  <input type="hidden" name="countryCode" class="form-control" #CountryCode="ngModel" [(ngModel)]="countryCode">

  </div>
  <!-- <input type="text"  name="phoneNumber" #frm1_phonenumber="ngModel" [(ngModel)]="phoneNumber"
  class="form-control" [ngClass]="{'is-invalid':frm1.submitted &&  frm1_phonenumber.invalid}"
  (hasError)="hasError($event,frm1)"
  
  required maxlength="20"> -->

  <!-- <div class="formGroup">   
  <label for="">Email</label>
   <input type="email" name="email" #frm1_Email="ngModel" [(ngModel)]="frm1_email"
   class="form-control" [ngClass]="{'is-invalid': frm1.submitted &&  frm1_Email.invalid}" required maxlength="100">
  </div> -->

  <div class="formGroup">
      <label>Email</label>
     <input type="email" name="email1" #frm1_Email="ngModel" [(ngModel)]="frm1_email"
       class="form-control" [ngClass]="{'is-invalid': frm1.submitted && frm1_Email.invalid}" required maxlength="100">
    </div>

  <label for="">Details</label>
  <textarea name="" id="" cols="15" rows="3"></textarea>
  <button id="landing-submit-business" type="submit">Submit</button>
 
  </form>
  </div>
 </div>
  </div>
  </div>
  </section>
  <section class="commonPadding">
  <div class="containers">
  <div class="lefttitles">
  <h4>
  Our Services
  </h4>
  <h2>
     Tailor-Made Solutions For  <strong>Your Business</strong>
  </h2>
  </div>
  <div class="pinkcolumns">
  <div class="box">
  <div class="icon">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newsd"></use>
    </svg>
  </div>
  <h4>
  Software & Application Development
  </h4>
  <p>
  Get dedicated developers and experts for wide range of tools and technologies to support your software development projects 
  </p>
  <a [routerLink]="['/capabilities/custom-software-development']" id="landing-know-more" >Know more
  <span>
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newknowmore"></use>
    </svg>   
  </span>
  </a>
  </div>
  <div class="box">
  <div class="icon">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newqa"></use>
    </svg>
  </div>
  <h4>
  QA & Testing
  </h4>
  <p>
  Access a team of highly qualified QA specialists to improve your product quality, ensure reliability and minimize risks
  </p>
  <a id="landing-know-more" [routerLink]="['/capabilities/application-QAT-services']">Know more
  <span>
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newknowmore"></use>
    </svg>   
  </span>
  </a>
  </div>
  <div class="box">
  <div class="icon">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newms"></use>
    </svg>
  </div>
  <h4>
  Maintenance & Support
  </h4>
  <p>
  Hire dedicated IT support staff to handle your IT infrastructure ensuring smooth operations, improved efficiency, and maximum uptime
  </p>
  <a id="landing-know-more" [routerLink]="['/capabilities/maintenance-services']">Know more
  <span>
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newknowmore"></use>
    </svg>   
  </span>
  </a>
  </div>
  <div class="box">
  <div class="icon">
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newtc"></use>
    </svg>
  </div>
  <h4>
  IT/ Technology Consulting
  </h4>
  <p>
  Scale up your internal capabilities and drive digital transformation utilizing the latest technologies to drive long-term growth
  </p>
  <a id="landing-know-more" [routerLink]="['/capabilities/it-consulting-services']">Know more
  <span>
  <svg>
  <use xlink:href="../../assets/img/sprite.svg#newknowmore"></use>
    </svg>   
  </span>
  </a>
  </div>
  </div>
  <div class="promobanner">
  <article>
  <h2>
    Enhance capabilities. <br> Drive Revenue.
  </h2>
  <p>
    Schedule a call or write to us to discuss requirements and take your next step towards success
  </p>
  <button  id="landing-letus-discuss" data-bs-toggle="modal" data-bs-target="#meetingschedule" class="redbtns">
    LET'S DISCUSS
  </button>
  </article>
  <div>
          <h5>
    Send e-mail to:
          </h5>
          <h3>
     bd@jtsiindia.com
          </h3>
          <a id="landing-send-email" href="mailto:bd@jtsiindia.com" class="redbtns">
            Send
    </a>
  </div>
  </div>
  </div>
  </section>
  <section class="grayBg commonPadding">
     <div class="containers">
             <div class="promopoints">
        <picture>
   <img src="../../assets/img/topnotch.webp" alt="">
        </picture>
        <article>
   <h2>
      Add top-notch tech talent to your team
   </h2>
   <p>
      JTSi provides highly qualified expertise at affordable rates to support your internal teams, providing your projects the boost it needs and assists in meeting organisational demands. We help you to be better equipped to handle new projects, expand expertise, diversify services, and improve scalability. 
   </p>
   <ul class="tickUl">
      <li>Client-centric model with dedicated teams</li>
      <li>Access to top quality resources and skill sets</li>
      <li>Complete flexibility to scale up or down</li>
      <li>Strict adherence to product Quality & Timeline</li>
      
   </ul>
   <a id="landing-know-more" routerLink="/delivery-pricing/Team-as-a-Service" class="btn btn-md btn-primary">Know more</a>
        </article>
             </div>
             </div>
             </section>
  
             <section class="commonPadding">
                          <div class="containers">
                                  <div class="skillgrid">
                                  <article>
                          <div class="lefttitles">
                          <h2>
                                          Fill in your skill   <strong>gaps and boost outcomes</strong>
                          </h2>
                          </div>
                          <p>
                            A group of people having passion towards what they do is the driving force behind all the accomplishments JTSi Technologies made so far. 
                            We trust the abilities of our employees and encourage them to explore their talents, stretch themselves beyond their potential and 
                            realize their true capabilities.
                          </p>
                          <img src="../../../assets/img/skillsets.webp" alt="">
                  </article>
                  <div class="skilltags">
          
            
                            <div class="skillsets open">
                    
                                <h5 class="skillname">Software Engineer</h5>
                                <div class="viewdetails ">
                                    <p>Our Software Engineers and Developers have vast expertise developing highly engaging and user
                                        friendly web, mobile, and cloud based applications using a wide range of cutting edge technologies </p>
                                    <p><b>Related Skills:</b></p>
                                    <p> ASP.Net, .Net Core, MVC, SQL, Java, Angular, Python
                    
                                    </p>
                    
                                </div>
                            </div>
                            <div class="skillsets closed">
                    
                                <h5 class="skillname">IT Recruiter</h5>
                                <div class="viewdetails ">
                                    <p>Our Recruiting Specialists can help you find the right candidates for a variety of business roles and
                                        the best technology talents, optimising your recruiting process for faster hiring and improved
                                        efficiency.</p>
                                    <p><b>Related Skills:</b></p>
                                    <p>Technical Hiring, Job postings, Online Portals, Applicant Tracking Systems</p>
                    
                                </div>
                            </div>
                    
                        
                    
                            <div class="skillsets closed">
                    
                                <h5 class="skillname">Business Analyst</h5>
                                <div class="viewdetails ">
                                    <p>Our Business Analysts help you analyse complex business process for improvements and help you better
                                        determine the changes required that supports the long-term growth of the business</p>
                                    <p><b>Related Skills:</b></p>
                                    <p>MySQL, Excel, Power BI, Tableau</p>
                    
                                </div>
                            </div>
                    
                            <div class="skillsets closed">
                    
                                <h5 class="skillname">React Developer</h5>
                                <div class="viewdetails ">
                                    <p>Hire our React Native Developers who are highly skilled at creating user-friendly applications for a
                                        variety of use-cases at lower costs and faster turnaround time while meeting strict deadlines.</p>
                                    <p><b>Related Skills:</b></p>
                                    <p>HTML, CSS, JavaScript ES6, JSX, Git, Node.JS, Redux</p>
                    
                                </div>
                    
                               </div>
                    
                                <div class="skillsets closed">
                                    <h5 class="skillname">Project Manager</h5>
                                    <div class="viewdetails ">
                                        <p>Our Project Managers help streamline your business processes regardless of the industry/domain
                                            right from planning to execution and deployment completing the project within the budget and
                                            timeline</p>
                                        <p><b>Related Skills:</b></p>
                                        <p>Product Roadmap, Burndown Chart, Release Plan, Budget Tracker, Project Charter
                                        </p>
                                    </div>
                                </div>
                    
                                <div class="skillsets closed">
                    
                                    <h5 class="skillname">UI/UX Designers</h5>
                                    <div class="viewdetails ">
                                        <p>Our UI/UX designers work with you to translate your product ideas into practical designs and
                                            build user journeys that are optimised for the finest user experience and highest conversion
                                            rates.
                                        </p>
                                        <p><b>Related Skills:</b></p>
                                        <p>Sketch, Figma, Adobe CC</p>
                                    </div>
                    
                                </div>
                    
                                <div class="skillsets closed">
                    
                                    <h5 class="skillname">DevOps Engineer</h5>
                                    <div class="viewdetails ">
                                        <p>Our DevOps engineer employ the best CI/CD practices for automating your entire delivery cycle by
                                            ensuring all your deployments and rollbacks are accomplished quickly, safely, and efficiently.
                                        </p>
                                        <p><b>Related Skills:</b></p>
                                        <p>AWS, Azure, Cloud Computing, Docker, Continuous Integration/Delivery, Kubernetes
                                        </p>
                                    </div>
                    
                                </div>
                    
                    
                                <div class="skillsets closed">
                    
                                    <h5 class="skillname">QA Tester</h5>
                                    <div class="viewdetails ">
                                        <p>Our Testing and QA engineers offer meticulous manual & automated testing solutions that will
                                            ensure your software solution is free from any performance, stability, or functionality issues.
                                        </p>
                                        <p><b>Related Skills:</b></p>
                                        <p>JavaScript, Selenium
                                        </p>
                                    </div>
                    
                                </div>
                         
  
                    
                            </div> 
            
          </div>
           
                          </div>
  
                          <div class="promobtncenter">
  
                          <button id="landing-letus-discuss-more"  class="redbtns" data-bs-toggle="modal" data-bs-target="#meetingschedule"> LET'S DISCUSS</button>
                        </div>
                          </section>
  
                         
  
                          <section class="commonPadding Tpadd0">
                              <div class="containers">
                                  <div class="texttitle_two"><h2 >FAQs</h2></div>
                            <div class="commonquestions">
          
                              <div class="freeaqs closed">
                                  <h5 class="ottachodyam" ><b>Why Hire our dedicated resources for your projects?</b></h5>
                                  <div class="viewdetails ">
                                      <p>Our dedicated resources will act as a part of your in-house teams and will work with your
                                          employees to complete the task. Save time and money by eliminating costly hiring, talent
                                          training processes, and other hardware and software expenses. You can either hire a
                                          single individual or a team of dedicated resources from our side.</p>
                                  </div>
                  
                              </div>
                  
                  
                              <div class="freeaqs closed">
                                  <h5 class="ottachodyam">Does the billing rate depend on the team members' experience, skills,
                                      proficiency and so on</h5>
                                  <div class="viewdetails ">
                                      <p>Yes, It depends on the level of expertise of the chosen resources that you would like to
                                          work with. For more detailed information you can contact us anytime.</p>
                                  </div>
                  
                              </div>
                  
                              <div class="freeaqs closed">
                                  <h5 class="ottachodyam">Can I have complete ownership of the software developers and manage them
                                      directly ?</h5>
                                  <div class="viewdetails ">
                                      <p>Yes. You can assign tasks to your developers, monitor the results, and manage them
                                          directly based on your needs. However, we are ready to provide you with experienced
                                          Account Managers who can serve as your primary point of contact while overseeing
                                          numerous developers on your behalf.
                                      </p>
                                  </div>
                  
                              </div>
                  
                              <div class="freeaqs closed">
                                  <h5 class="ottachodyam">Why choose JTSi’s dedicated resources to support my software development
                                      projects?</h5>
                                  <div class="viewdetails ">
                                      <p>JTSi is a highly reputed software development company headquartered in Bangalore with our
                                          development centre in Kochi, Kerala, and offices across USA. We have successfully delivered more than 18+ years
                                          of IT outsourcing and have vast experience in a wide spectrum of technologies,
                                          industries, and applications. We are a reliable partner offering thoroughly vetted and
                                          skilled dedicated resources & teams for software development at lowest prices in the
                                          market.
                                      </p>
                                  </div>
                  
                              </div>
                  
                              <div class="freeaqs closed">
                                  <h5 class="ottachodyam">What are the processes involved in developing custom software for my business?
                                  </h5>
                                  <div class="viewdetails ">
                                      <p>Our experts will get in touch with you to understand your requirements and devise a
                                          tailored strategy as per your business goals. We then move ahead with coding your
                                          software using highly agile practices and conduct stringent testing before launch. We
                                          then ensure that your software is deployed safely, regularly maintained and offer
                                          support and assistance as needed.
                  
                                      </p>
                                  </div>
                          
                              </div>   
                  
                          </div>
                          </div>
                          </section>
  
                          <div class="modal fade" id="meetingschedule" tabindex="-1" aria-labelledby="meetingscheduleLabel" aria-hidden="true">
                              <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="modal-title">Let’s discuss your project</h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                <img class="img-fluid" src="../../../assets/img/promodalhead.webp" alt="">
                                <div class="promodalcontent">
                                  <h3>
                                    Hire our highly skilled experts and teams
                                  </h3>
                                  <p>
                                    Are you looking to kickstart a new project or need additional 
resources for your existing ones?. Quickly onboard our professionals, well versed in the latest technologies.

                                  </p>

                                </div>
                                      <div class="filltheForm">
                                       <p>
                                        Fill out the form and we will get in touch with you
                                       </p>
                                        <form class="form" (ngSubmit)="frm.form.valid && onSubmitPopumfrm(frm)" #frm="ngForm">
                                          <div class="formspiltgroup">
                                          <div class="formGroup">
                                            <label>Full Name</label>
                                            <input type="text" name="fullname" #fullname="ngModel" [(ngModel)]="name"
                                            class="form-control" [ngClass]="{'is-invalid': frm.submitted &&  fullname.invalid}" required minlength="5" maxlength="50">
                                          
                                          </div>
                                          <div class="formGroup">
                                            <label>Email</label>
                                           <input type="email" name="email" #tkt_Email="ngModel" [(ngModel)]="email"
                                             class="form-control" [ngClass]="{'is-invalid': frm.submitted && tkt_Email.invalid}" required maxlength="100">
                                          </div>
                                        </div>
                                          <div class="formGroup">
                                            <label>Your Message</label>
                                            <textarea cols="5" rows="4" name="message"
                                                      maxlength="3000"></textarea>
                                          </div>
                            
                                          <button id="landing-submit-project"  type="submit" class="btn btn-md btn-primary">
                                             
                                              SUBMIT
                                            </button>
                                        </form>
                                      </div>
                        
                                  </div>
                                </div>
                              </div>
                            </div>