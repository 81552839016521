import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-recruitment',
  templateUrl: './recruitment.component.html',
  styleUrls: ['./recruitment.component.scss']
})
export class RecruitmentComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
      this.titleService.setTitle('HR Staffing & Recruitment | Talent Acquisition services in USA |Washington DC | JTSi Technologies');
      this.meta.addTags([
      { name: 'description', content: 'JTSi as an expert hr and recruitment service company that can manage whole human resources activities like HR staffing and Outsourcing, talent acquisition, payroll solutions and employee recruitment.' },
      { name: 'keywords', content: 'Human resource consulting, HR Recruitment, Human resource management, Talent acquisition, Employee recruitment' },
      { name: 'description', content: 'en-US', property: 'og:locale' },
      { name: 'description', content: 'website', property: 'og:type' },
      { property: 'og:title', content: '"HR Staffing & Recruitment | Talent Acquisition services in USA |Washington DC | JTSi Technologies' },
      { property: 'og:description', content: '="JTSi as an expert hr and recruitment service company that can manage whole human resources activities like HR staffing and Outsourcing, talent acquisition, payroll solutions and employee recruitment.' },
      { property: 'og:url', content: 'https://www.jtsiindia.com/capabilities/recruitment-hr-services' },
      { property: 'og:image', content: 'https://www.jtsiindia.com/assets/img/Recruitment-HR-Services.webp' },
      { property: 'og:site_name', content: 'JTSi Technologies India' },
      { property: 'article:modified_time', content: 'fetch current time' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@Jtsiindia' },
      { name: 'twitter:creator', content: '@Jtsiindia' },
      { name: 'twitter:title', content: 'HR Staffing & Recruitment | Talent Acquisition services in USA |Washington DC | JTSi Technologies' },
      { name: 'twitter:description', content: 'JTSi as an expert hr and recruitment service company that can manage whole human resources activities like HR staffing and Outsourcing, talent acquisition, payroll solutions and employee recruitment.' },
      { name: 'twitter:image', content: 'https://www.jtsiindia.com/assets/img/Recruitment-HR-Services.webp' }
      ]);
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }
  }


