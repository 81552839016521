import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-officegaps',
  templateUrl: './officegaps.component.html',
  styleUrls: ['./officegaps.component.scss']
})
export class OfficegapsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,
    private _renderer2:Renderer2,@Inject(DOCUMENT) private _document:Document


  ) { 
    this.titleService.setTitle('Office Gaps | Workflow Task, Employee Performance & Human Resource Management| JTSi Technologies');
    
    this.meta.addTags([
      {name:'description', content:'Officegaps is a software that combines the tasks of back office/ employee performance/workflow/human resource/custom relationship/asset and inventory management.'},
      {name:'keywords', content:'Workflow Task management, Back-office management, Employee performance management, Human resource management, Asset and Inventory management, Custom Relationship management'},
      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:'Office Gaps | Workflow Task, Employee Performance & Human Resource Management| JTSi Technologies'},
      { property:'og:description', content:'Officegaps is a software that combines the tasks of back office/ employee performance/workflow/human resource/custom relationship/asset and inventory management.'},
      { property:'og:url', content:'https://www.jtsiindia.com/solutions/OfficeGaps'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/OGinnerhead.webp'},                                                           
      { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Office Gaps | Workflow Task, Employee Performance & Human Resource Management| JTSi Technologies'},
      { name:'twitter:description', content:'Officegaps is a software that combines the tasks of back office/ employee performance/workflow/human resource/custom relationship/asset and inventory management.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/OGinnerhead.webp'},
      
    ])
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
    // let script=this._renderer2.createElement('script');
    // script.type=`application/Id+json`;
    // script.text=`{
      
    //   "@context": "http://schema.org/",
    //   "@type": "Product",
    //   "name": "OfficeGaps",
    //   "url":  "https://www.jtsiindia.com/solutions/OfficeGaps",
    //   "image": "https://www.jtsiindia.com/assets/img/OGinnerhead.webp",
    //   "brand": "JTSi India",
    //   "description": "OfficeGaps is an Integrated Business Management Solution offering highly configurable, affordable, and transformative back-office systems and more focusing on automating the end-to-end operations to grow faster and more effectively for small and medium-sized businesses, and large enterprises."
    

    // }`

    // this._renderer2.appendChild(this._document.body,script)
  }

}
