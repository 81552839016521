import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';

@Component({
  selector: 'app-employeetestimonials',
  templateUrl: './employeetestimonials.component.html',
  styleUrls: ['./employeetestimonials.component.scss']
})
export class EmployeetestimonialsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta

  ) { 
    this.titleService.setTitle('title');
    this.meta.addTag({name:'description', content:'content'});
    this.meta.addTag({name:'keywords', content:'content'});
  }

  ngOnInit(): void {
  }

}
