import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-java',
  templateUrl: './java.component.html',
  styleUrls: ['./java.component.scss']
})
export class JavaComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Java development and support company in USA | New Jersy |Washington DC | JTSi Technologies');
    
    this.meta.addTags([
      {name:'description', content:'JTSi is a java software development and support company with skilled developers to develop applications using latest and advanced technologies for our clients in UK, USA and Europe.'},
      {name:'keywords', content:'Java Development, Java update'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:'"Java development and support company in USA | New Jersy |Washington DC | JTSi Technologies. '},
      {property :'og:description', content:' "JTSi is a java software development and support company with skilled developers to develop applications using latest and advanced technologies for our clients in UK, USA and Europe.'},
      {property:'og:url',content:'https://www.jtsiindia.com/technologies/java' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/javaBanner.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Java development and support company in USA | New Jersy |Washington DC | JTSi Technologies.'},
      {name:'twitter:description', content:'JTSi is a java software development and support company with skilled developers to develop applications using latest and advanced technologies for our clients in UK, USA and Europe. '},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/javaBanner.webp'}
      ]);
  }


  ngOnInit(): void {
                    this.commonfunctions.CreateCanonicalURL();
                   }

}
