import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-bigdata-analytics',
  templateUrl: './bigdata-analytics.component.html',
  styleUrls: ['./bigdata-analytics.component.scss']
})
export class BigdataAnalyticsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
      this.titleService.setTitle('Top Big Data Analytics Company in USA |Washington DC| New Jersey | JTSi Technologies');
      this.meta.addTags([
      {name:'description', content:'Our Big data analytics consulting team with their vast expertise, helps to plan a meaningful Business data and analytics strategy that helps clients to choose best technologies.'},
      {name:'keywords', content:'Big data Analytics Company, Big data technologies, Data analytics consulting '},
      {name:'description', content:'Our Big data analytics consulting team with their vast expertise, helps to plan a meaningful Business data and analytics strategy that helps clients to choose best technologies.'},
      {name:'keywords', content:'Big data Analytics Company, Big data technologies, Data analytics consulting '},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:'"Top Big Data Analytics Company in USA |Washington DC| New Jersey | JTSi Technologies'},
      {property :'og:description', content:'"Our Big data analytics consulting team with their vast expertise, helps to plan a meaningful Business data and analytics strategy that helps clients to choose best technologies.'},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/bigdata-analytics' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/Big-Data-Processing.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Top Big Data Analytics Company in USA |Washington DC| New Jersey | JTSi Technologies'},
      {name:'twitter:description', content:'Our Big data analytics consulting team with their vast expertise, helps to plan a meaningful Business data and analytics strategy that helps clients to choose best technologies.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Big-Data-Processing.webp'}
      ]);
    }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
