<div class="innerHeader  Disclaimer">
  <div class="innerHeadings">
    <!-- <h4>technologies</h4> -->
    <h1>Disclaimer</h1>
  </div>
</div>
<app-cirlelinks></app-cirlelinks>
<div class="containers commonPadding">
  <p>
    The information contained in this website is for general information purposes only. The information is provided by JTSi and while we endeavour to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
  </p><p>
    Under no circumstances shall JTSi be held responsible for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
  </p><p>
    Through this website you will be provided access gateways to other websites which are not under the control of JTSi. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
  </p><p>
    Every effort is made to keep the website up and running smoothly. However, JTSi takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical glitches that are beyond our control.

  </p>
</div>


