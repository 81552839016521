import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-whoweare',
  templateUrl: './whoweare.component.html',
  styleUrls: ['./whoweare.component.scss']
})
export class WhoweareComponent implements OnInit {

  constructor(

    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  )
  {
  this.titleService.setTitle('Software Development Outsourcing company |India |Kerala | Kochi | Infopark | JTSi Technologies');
  this.meta.addTags([
    {name:'description', content:'Are you searching for a top software development company in India? JTSi technology is the best bespoke software development firm located in Infopark, Kochi, Kerala.'},
    {name:'keywords', content:'Software Development Outsourcing, software service solutions, software company '},
    {property:'og:locale', content:'en_US' } ,
	  {property:'og:type', content:'website'},
    {property:'og:title' ,content:'"Software Development Outsourcing company |India |Kerala | Kochi | Infopark | JTSi Technologies'},
	  {property:'og:description', content:'"Are you searching for a top software development company in India? JTSi technology is the best bespoke software development firm located in Infopark, Kochi, Kerala.'},
    {property:'og:url', content:'"https://www.jtsiindia.com/why-JTSi/who-we-are'},
    {property:'og:image', content:'https://www.jtsiindia.com/assets/img/who-we-are-header.webp'},
    {property:'og:site_name', content:'JTSi Technologies India'},
    {property:'article:modified_time', content:'fetch current time' },
    {name:'twitter:card', content:'summary_large_image'},
    {name:'twitter:site', content:'@Jtsiindia'},
    {name:'twitter:creator', content:'@Jtsiindia'},
    {name:'twitter:title', content:'Software Development Outsourcing company |India |Kerala | Kochi | Infopark | JTSi Technologies'},
    {name:'twitter:description', content:'Are you searching for a top software development company in India? JTSi technology is the best bespoke software development firm located in Infopark, Kochi, Kerala'},
    {name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/who-we-are-header.webp'}
    ]);
  }

  ngOnInit(): void {
                  this.commonfunctions.CreateCanonicalURL();

                   }


}
