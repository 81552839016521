import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-taa-s',
  templateUrl: './taa-s.component.html',
  styleUrls: ['./taa-s.component.scss']
})
export class TaaSComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Software team as a service solution provider | USA |New Jersey| JTSi Technologies');
    
  
    this.meta.addTags([
      {name:'description', content:'Our Team as a service (Taas) is an experienced and dedicated team of specialists in software industry for UK, US and Canada areas and they contribute to your project and accomplish business goals.'},
      {name:'keywords', content:'Software team as a service, team service management, team as a service solution provider'},
      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:'Software team as a service solution provider | USA |New Jersey| JTSi Technologies '},
      { property:'og:description', content:'Our Team as a service (Taas) is an experienced and dedicated team of specialists in software industry for UK, US and Canada areas and they contribute to your project and accomplish business goals.'},
      { property:'og:url', content:'https://www.jtsiindia.com/delivery-pricing/Team-as-a-Service'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Term-as-a-service.webp'},                                                           
      { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Software team as a service solution provider | USA |New Jersey| JTSi Technologies'},
      { name:'twitter:description', content:'Our Team as a service (Taas) is an experienced and dedicated team of specialists in software industry for UK, US and Canada areas and they contribute to your project and accomplish business goals.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Term-as-a-service.webp'},

    ])
  }



  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  }

}
