import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta

  ) { 
    this.titleService.setTitle('Client Testimonials | Customer feedback | JTSi Technologies');
    this.meta.addTag({name:'description', content:'In our company, our valued customers and their opinions are the biggest assets. We establish strong relationships with all our customers.'});
  }


  ngOnInit(): void {
  }

}
