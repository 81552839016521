import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.scss']
})
export class MigrationComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('IT Migration| Digital Product and Software Migration for start-Up, enterprise and corporate | JTSi Technologies');
    this.meta.addTag({name:'description', content:'We are an experienced IT migration service company that offers cloud migration, digital product migration, data /database migration and technology migration for Europe, US, UK and Canada areas.'});
    this.meta.addTag({name:'keywords', content:'IT Migration, Software migration, digital product migration, data migration, technology migration'});
this.meta.addTags([
  { property:'og:locale', content:'en_US'},
	{ property:'og:type', content:'website'},
	{ property:'og:title', content:'IT Migration| Digital Product and Software Migration for start-Up, enterprise and corporate | JTSi Technologies '},
	{ property:'og:description', content:'We are an experienced IT migration service company that offers cloud migration, digital product migration, data /database migration and technology migration for Europe, US, UK and Canada areas.'},
	{ property:'og:url', content:'https://www.jtsiindia.com/capabilities/migration-services'},
	{ property:'og:image', content:'https://www.jtsiindia.com/assets/img/Migration-Services.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
  { property:'article:modified_time', content:'fetch current time'},
  
 { name:'twitter:card', content:'summary_large_image'},
 { name:'twitter:site', content:'@Jtsiindia'},
 { name:'twitter:creator', content:'@Jtsiindia'},
 { name:'twitter:title', content:'IT Migration| Digital Product and Software Migration for start-Up, enterprise and corporate | JTSi Technologies'},
 { name:'twitter:description', content:'We are an experienced IT migration service company that offers cloud migration, digital product migration, data /database migration and technology migration for Europe, US, UK and Canada areas.'},
 { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Migration-Services.webp'},
  
    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  }

}
