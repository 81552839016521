<div class="innerHeader why">
  <div class="innerHeadings">
    <h1>Value  <span>Proposition</span></h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>

<div class="captop">
  <div class="picture">
    <img class="img-fluid" src="../../assets/img/value_01.webp" alt="The JTSi value proposition ensures uncompromising quality in services and products">
  </div>
  <div class="texts">
    <div class="heading">
        <div class="redBg">
            <h2>Business that is </h2>
        </div>
        <div class="darkBg">
            <h3>Value-driven and customer-focused! </h3>
        </div>
    </div>
    <p>
      JTSi upholds a Value Proposition that is uncompromised on quality while delivering product or service in a cost-effective way. We are continually enhancing our products and services so that the customer who avails it is satisfied with its performance. JTSi makes every effort that marks our products/services ‘one of a kind’ in the industry.
    </p><p>
      Irrespective of the business challenges, our proficient team has the capability to come up with innovative outcomes and that too in a very short time span! We deploy the top-notch platforms and state-of-the-art techniques in our schema thus bridging the gap between technology and industries. 
    </p>
    <p>
      Our Value Proposition does not exist only on paper, but is a driving force behind our business strategy, and it is put into practice for every project we commit to. We value our customers to the utmost, and our business decisions are heavily driven by this objective. The solid Value Proposition we offer is evident by our steady growth and strong customer base. Additionally, our marketing strategies align well with our core values and are one of the key factors that catalyze our development efforts on the long run.
    </p>
    </div>
</div>
