import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-fulltime-equivalent',
  templateUrl: './fulltime-equivalent.component.html',
  styleUrls: ['./fulltime-equivalent.component.scss']
})
export class FulltimeEquivalentComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Full time equivalent software development pricing and engagement model| JTSi Technologies');
    
    this.meta.addTags([
      {name:'description', content:'JTSi has full time equivalent (FTE) model services that is provided to the clients with single and multiple employees that works on Fulltime contract basis in UK, US, Canada and Europe countries.'},
      {name:'keywords', content:'FTE Model, Full time equivalent pricing model, full time equivalent service model'},
      {name :'description', content:'en-US',property:'og:locale'},
     {name :'description', content:'website',property:'og:type'},
     { property :'og:title', content:'"Full time equivalent software development pricing and engagement model| JTSi Technologies'},
   
     { property :'og:description', content:'"JTSi has full time equivalent (FTE) model services that is provided to the clients with single and multiple employees that works on Fulltime contract basis in UK, US, Canada and Europe countries. '},
         { property:'og:url',content:'"https://www.jtsiindia.com/delivery-pricing/fulltime-equivalent' },
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Full-Time-Equavalent-(-FTE-).webp' } ,                                                         
      { property:'og:site_name', content:'JTSi Technologies India'},
     {property:'article:modified_time', content:'fetch current time'},
     {name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'Full time equivalent software development pricing and engagement model| JTSi Technologies'},
     { name:'twitter:description', content:'JTSi has full time equivalent (FTE) model services that is provided to the clients with single and multiple employees that works on Fulltime contract basis in UK, US, Canada and Europe countries. '},
   {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Full-Time-Equavalent-(-FTE-).webp'}
  ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
