<div class="innerHeader capabilities Technologies">
  <div class="innerHeadings">
    <!-- <h4>Capabilities</h4> -->
    <!-- <h3>Software </h3> -->
    <h1>Techstacks</h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>
<section class="commonPadding" id="Technologies">
  <div class="texttitle_two">
    <h2 class="nocase">Technologies</h2>
  </div>
  <div class="containers homeGrid">
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/micro_1.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Microsoft
        </h4>

        <a [routerLink]="['/technologies/microsoft']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/java_thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Java
        </h4>

        <a [routerLink]="['/technologies/java']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/sap_thumb.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          SAP
        </h4>

        <a [routerLink]="['/technologies/sap']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/opensource_01.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Open Source
        </h4>

        <a [routerLink]="['/technologies/opensource']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/mobile_02.webp" alt="">
      </div>
      <div class="overlays">
        <h4>
          Mobile
        </h4>

        <a [routerLink]="['/technologies/mobile']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

  </div>
</section>
