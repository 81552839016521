import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-technologiesgrid',
  templateUrl: './technologiesgrid.component.html',
  styleUrls: ['./technologiesgrid.component.scss']
})
export class TechnologiesgridComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService

  ) { 
    this.titleService.setTitle('JTSi Technologies: Using Latest Software Technologies and tools | USA | India');
  this.meta.addTags([{name:'description', content:'We develop your software using the latest software technologies and tools. Our team can help you build software for mobile devices, tablets, desktops, and cross-platform devices.'},
  { property:'og:locale', content:'en_US'},
	{ property:'og:type', content:'website'},
	{ property:'og:title', content:'JTSi Technologies : Using Latest Software Technologies and tools | USA|India'},
	{ property:'og:description', content:'Our commercial aircraft MRO aviation business management software is fully integrated and comprehensive software solutions to manage, maintenance, engineering and logistics of airlines'},
	{ property:'og:url', content:'https://www.jtsiindia.com/technologies'},
	{ property:'og:image', content:'https://www.jtsiindia.com/assets/img/Technologies_header.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
	{ property:'article:modified_time', content:'fetch current time'},
  { name:'twitter:card', content:'summary_large_image'},
  { name:'twitter:site', content:'@Jtsiindia'},
  { name:'twitter:creator', content:'@Jtsiindia'},
  { name:'twitter:title', content:'JTSi Technologies : Using Latest Software Technologies and tools | USA|India'},
  { name:'twitter:description', content:'Our commercial aircraft MRO aviation business management software is fully integrated and comprehensive software solutions to manage, maintenance, engineering and logistics of airlines.'},
  { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Technologies_header.webp'},
]);
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
