import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-waterfall-development',
  templateUrl: './waterfall-development.component.html',
  styleUrls: ['./waterfall-development.component.scss']
})
export class WaterfallDevelopmentComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Waterfall Software development and design models in USA | New Jersey | JTSi Technologies.');
    
    this.meta.addTags([
      {name:'description', content:'JTSi have capability to implement traditional and iterative waterfall software development model and flexiblity to develop software based on client requirements in US, UK ,Canada and Europe.'},
   {name:'keywords', content:'waterfall software development, Iterative waterfall development model, Traditional software development'},
      {name :'description', content:'en-US',property:'og:locale'},
     {name :'description', content:'website',property:'og:type'},
     { property :'og:title', content:'" Waterfall Software development and design models in USA | New Jersey | JTSi Technologies'},
   
     { property :'og:description', content:' "JTSi have capability to implement traditional and iterative waterfall software development model and flexiblity to develop software based on client requirements in US, UK ,Canada and Europe.'},
         { property:'og:url',content:'"https://www.jtsiindia.com/delivery-pricing/waterfall-development-model' },
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Water-falls-model.webp' } ,                                                         
      { property:'og:site_name', content:'JTSi Technologies India'},
     {property:'article:modified_time', content:'fetch current time'},
     {name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'Waterfall Software development and design models in USA | New Jersey | JTSi Technologies.'},
     { name:'twitter:description', content:'JTSi have capability to implement traditional and iterative waterfall software development model and flexiblity to develop software based on client requirements in US, UK ,Canada and Europe. '},
   {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Water-falls-model.webp'}
  ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
