
<script type="application/ld+json">
  "@context": "http://www.schema.org",
  "@type": "ProfessionalService",
  "name": "JTSi Technologies India Pvt.Ltd",
  "url": "https://www.jtsiindia.com",
  "logo": "https://www.jtsiindia.com/assets/img/JTSi-Logo.svg",
  "image": "https://www.jtsiindia.com/assets/img/slide_01.webp",
  "description": "JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Third Floor, Thapasya Building, Infopark, Kakkanad",
    "addressLocality": "Kochi",
    "addressRegion": "Kerala",
    "postalCode": "682042",
    "addressCountry": "India"
  },
  "openingHours": "Mo, Tu, We, Th, Fr 09:30-18:30",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+91 484 407-0368",
    "contactType": "Local"
  }
                           

}
</script>

<script type="application/ld+json">

{
  "@context": "http://www.schema.org",
  "@type": "ProfessionalService",
  "name": "JTSi Technologies India Pvt.Ltd",
  "url": "https://www.jtsiindia.com",
  "logo": "https://www.jtsiindia.com/assets/img/JTSi-Logo.svg",
  "image": "https://www.jtsiindia.com/assets/img/slide_01.webp",
  "description": "JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.",
"address": {
"@type": "PostalAddress",
"streetAddress": "11490 Commerce Park Dr., 4th Floor,
Reston, Virginia, USA",
"addressLocality": "Reston",
 "addressRegion": "Virginia",
"postalCode": "20191",
 "telephone": "+1 (703)949-9100"
                           },
  "openingHours": "Mo, Tu, We, Th, Fr 09:30-18:30"
                           
}

</script>




<div class="innerHeader capabilities location">
    <div class="innerHeadings">
<h1>Contact Us</h1>
</div>
</div>

<app-cirlelinks></app-cirlelinks>
<section class="commonPadding">
<div class="containers">
<div class="equalclmns">
  <div class="details">
    <div class="info">
      <h2>
        START A CONVERSATION
      </h2>
      <p>
        Our mission is to help you make the right choice for the right outcomes. That starts with an honest conversation about your challenges. We look forward to talking.
      </p>
    </div>
    <ul class="contactsocial">
      <li>
        <a href="mailto:info@jtsiindia.com">
          <svg>
            <use xlink:href="../../assets/img/imgaccounting/sprite.svg#email"></use>
          </svg>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/jtsi-technologies-india" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlink:href="../../assets/img/imgaccounting/sprite.svg#linkedin"></use>
          </svg>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/jtsitechnologies" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlink:href="../../assets/img/imgaccounting/sprite.svg#facebook"></use>
          </svg>
        </a>
      </li>
      <li>
        <a hhref="https://twitter.com/Jtsiindia" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlink:href="../../assets/img/imgaccounting/sprite.svg#twitter"></use>
          </svg>
        </a>
      </li>
      <li>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlink:href="../../assets/img/imgaccounting/sprite.svg#youtube"></use>
          </svg>
        </a>
      </li>
      <!-- <li>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <svg>
            <use xlink:href="../../assets/img/imgaccounting/sprite.svg#youtube"></use>
          </svg>
        </a>
      </li> -->
    </ul>
    
    <h3>
      Our Office
    </h3>
    <h4>
      <svg>
        <use xlink:href="../../assets/img/sprite.svg#indiaflag"></use>
      </svg>
      India
    </h4>
    <div class="addresses">
    <address class="address">
      <p>
        <strong>JTSi Technologies India Pvt. Ltd.</strong>
        <br>
        Third Floor, Thapasya Building,
        <br>
        Infopark, Kakkanad,
        <br>
        Kochi – 682042, Kerala, India.
        <br>
        Tel: +91 484 407-0368
        <br>
     
        Tel: +1(202)464-JTSi(5874) (US)
      <br>
      Email: <a href="mailto:marketing@jtsiindia.com">marketing@jtsiindia.com</a>
      <br>
      Support: <a href="mailto:info@jtsiindia.com"> info@jtsiindia.com </a>
      </p>
    </address>
    <address class="address">
<p>
  17/A, 4th Cross, Ananthappa Layout,
  <br>
Doresani Palya, Near Kalyani Infopark,
<br>
Bengaluru, Karnataka, India (HQ).
<br>
Tel: +91 80 4111-4111
<br>
Tel: +1(202)464-JTSi(5874) (US)
<br>
Fax: +91 80 4111-4646
  <br>
  Email: <a href="mailto:info@jtsiindia.com"> info@jtsiindia.com </a>
</p>
    </address>
  </div>
  </div>
  <div class="forms">
    <form class="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
     
      <div class="formGroup">
        <label>Full Name</label>
        <input type="hidden" name="lead_ID" value="service.formData.ctD_ID">
        <input type="text" name="firstname" #ctD_Name="ngModel" [(ngModel)]="service.formData.ctD_Name"
               class="form-control" [ngClass]="{'is-invalid': f.submitted &&  ctD_Name.invalid}" required maxlength="50">
      </div>
      <div class="formGroup">
        <label>Email</label>
        <input type="email" name="email" #ctD_Email="ngModel" [(ngModel)]="service.formData.ctD_Email"
               class="form-control" [ngClass]="{'is-invalid': f.submitted &&  ctD_Email.invalid}" required maxlength="100">
      </div>

   
      <div class="formGroup">
        <label>Phone Number</label>
        <input type="text" ng2TelInput name="phoneNumber" #ctD_Phone="ngModel" [(ngModel)]="service.formData.ctD_Phone"
               class="form-control phone" [ngClass]="{'is-invalid': f.submitted &&  ctD_Phone.invalid}" required maxlength="20"
               (hasError)="hasError($event,f)"
               (countryChange)="onContryChange($event,f)">
        <input type="hidden" name="countryCode" class="form-control" #CountryCode="ngModel" [(ngModel)]="service.formData.countryCode">
      </div>
      <div class="formGroup">
        <label>Website</label>
        <input class="form-control" type="text" name="website" #ctD_Website="ngModel" [(ngModel)]="service.formData.ctD_Website"
               maxlength="100">
      </div>

      <div class="formGroup">
        <label>Your Message</label>
        <textarea class="form-control" cols="4" rows="8" name="message" #ctD_Message="ngModel" [(ngModel)]="service.formData.ctD_Message"
                  maxlength="3000"></textarea>
      </div>

      <button class="btn btn-lg btn-primary mt1" type="submit">Submit Here</button>
    </form>
  </div>
</div>


  <!-- <div class="contact-top">
    <div class="contactform">
      <h3>
        Always our greatest pleasure is
        <span>hearing from you</span>
      </h3>
      <h5>Whether you would like to talk about working with us or just a "Hello", either of them makes us delighted.</h5>
     
    </div>
    <div class="info">
      <div class="offices">
        <h4>India Regional Office - Kochi</h4>
         
    <p>
      <strong>JTSi Technologies India Pvt. Ltd.</strong>
      <br>
      Third Floor, Thapasya Building,
      <br>
      Infopark, Kakkanad,
      <br>
      Kochi – 682042, Kerala, India.
      <br>
      Tel: +91 484 407-0368
      <br>
   
      Tel: +1(202)464-JTSi(5874) (US)
    <br>
    Email: <a href="mailto:marketing@jtsiindia.com">marketing@jtsiindia.com</a>
    <br>
    Support: <a href="mailto:info@jtsiindia.com"> info@jtsiindia.com </a>
    </p>
    <a class="findarea" target="_blank" href="https://goo.gl/maps/su8PN5A8MJcL7Vqz9">
      <svg>
        <use xlink:href="../../assets/img/sprite.svg#locations"></use>
      </svg>
      <span>View in Maps</span>
    </a>
      </div>
    <div class="offices">
      <h4>Strategic Alliance Partner – USA</h4>
       
  <p>
    <strong>Johnson Technology Systems, Inc.</strong>
  <br>
  11490 Commerce Park Dr., 4th Floor,
  <br>
  Reston, Virginia 20191
  <br>
  Tel: +1 (703)949-9100
  <br>
  Fax: +1 (201)624-7671
  <br>
  Email: <a href="mailto:info@jtsusa.com"> info@jtsusa.com </a>
  </p>
  <a class="findarea" target="_blank" href="https://goo.gl/maps/GNXoMrdbDzQ6LMQdA">
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#locations"></use>
    </svg>
    <span>View in Maps</span>
  </a>
    </div>
  
    <div class="offices">
      <h4>India Regional Office - Bangalore</h4>
       
  <p>
    <strong>JTSi Technologies India Pvt. Ltd.</strong>
    <br>
    17/A, 4th Cross, Ananthappa Layout,
    <br>
Doresani Palya, Near Kalyani Infopark,
<br>
Bengaluru, Karnataka, India (HQ).
<br>
Tel: +91 80 4111-4111
<br>
Tel: +1(202)464-JTSi(5874) (US)
<br>
Fax: +91 80 4111-4646
    <br>
    Email: <a href="mailto:info@jtsiindia.com"> info@jtsiindia.com </a>
  </p>

  <a class="findarea" target="_blank" href="https://goo.gl/maps/X1mh2B3Fc42vreK1A">
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#locations"></use>
    </svg>
    <span>View in Maps</span>
  </a>
    </div>
    </div>
  </div> -->

</div>
</section>
<section class="commonPadding Tpadd0">
  <div class="containers">
    <div class="mapouter"><div class="gmap_canvas"><iframe style="height: 35rem; width: 100%;"  id="gmap_canvas" src="https://maps.google.com/maps?q=JTSi Technologies India Pvt. Ltd. Third Floor, Thapasya Building, Infopark Campus, Kakkanad, Kochi, Kerala 682042&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><br><style>.mapouter{position:relative;text-align:right;height:35rem;width:100%;}</style><style>.gmap_canvas {overflow:hidden;background:none!important;height:35rem;width:100%;}</style></div></div>
  </div>
</section>