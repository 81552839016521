import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-web-application',
  templateUrl: './web-application.component.html',
  styleUrls: ['./web-application.component.scss']
})
export class WebApplicationComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService

  ) { 
    this.titleService.setTitle('No: 1 Web Application Development Company in USA |New Jersey| JTSi Technologies');
    
  
    this.meta.addTags([
      
  {name:'keywords', content:'Web Development company, Web application development, web designing'},
  {name:'description', content:'With expertise in .NET, JAVA, PHP, Angular, web and graphic design, we are reliable and the top rated web application development and web designing company in Washington DC, New Jersey, US.'},
  { property:'og:locale', content:'en_US'},
	{ property:'og:type', content:'website'},
	{ property:'og:title', content:'No: 1 Web Application Development Company in USA |New Jersey| JTSi Technologies ewebkitRequestAnimationFrame'},
	{ property:'og:description', content:'With expertise in .NET, JAVA, PHP, Angular, web and graphic design, we are reliable and the top rated web application development and web designing company in Washington DC, New Jersey, USewebkitRequestAnimationFrame'},
	{ property:'og:url', content:'https://www.jtsiindia.com/capabilities/web-application-developmenewebkitRequestAnimationFrame'},
  { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Web-Application-Development.jpewebkitRequestAnimationFrame'},                                                           
  { property:'og:site_name', content:'JTSi Technologies IndiewebkitRequestAnimationFrame'},
  { property:'article:modified_time', content:'fetch current time'},
  { name:'twitter:card', content:'summary_large_image'},
  { name:'twitter:site', content:'@Jtsiindia'},
  { name:'twitter:creator', content:'@Jtsiindia'},
  { name:'twitter:title', content:'No:1 Web Application Development Company in USA |New Jersey| JTSi Technologies '},
  { name:'twitter:description', content:'With expertise in .NET, JAVA, PHP, Angular, web and graphic design, we are reliable and the top rated web application development and web designing company in Washington DC, New Jersey, US.'},
  { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Web-Application-Development.webp'},


  ])

    }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
