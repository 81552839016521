
<div class="innerHeader testimonials">
  <div class="innerHeadings">
    <h1>Employee   <span>Testimonials</span></h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>


<div class="containers commonPadding">
  <div class="employee_testimonial">
    <div class="emphoto">
      <img src="../../assets/img//sarath-mohan.webp" alt="">
    </div>
    <div class="empopinioin">
      <p>
        Being part of a dynamic team with goal-centric processes is truly a lucky thing. Each day is a new day and each project is a new lesson. Employees have respect for each other that is irrespective of the organizational level, team for which they are working or any other such aspect. JTSi is an organization that can be exclusively exemplified as the best working place.  Truly blessed to be part of such an amazing team!
      </p>
      <h5>Sarath Mohan G, Accounts Executive</h5>
    </div>
  </div>
  <div class="employee_testimonial rightpic">
    <div class="emphoto">
      <img src="../../assets/img//aneesh-gopinath.webp" alt="">
    </div>
    <div class="empopinioin">
      <p>
        The opportunity put forth to every employee is truly amazing and there is turning back as far as career growth is concerned. For me and my colleagues JTSi has been the road to excellence professionally as well as personally. In my experience I have got back appreciation and positive reception manifold more than the hard work and dedication put for work.
      </p>
      <h5>
        Aneesh Gopinath – Team Lead Finance
      </h5>
    </div>
  </div>

  <div class="employee_testimonial">
    <div class="emphoto">
      <img src="../../assets/img//ramesh.webp" alt="">
    </div>
    <div class="empopinioin">
      <p>
        JTSi managers are keenly interested in recognising and boosting the potential of each of the team members. The positivity in the work environment and the ability-testing routine tasks keeps on catalysing the best of our abilities. This is very critical, for individual as well as organizational growth. One thing I have always taken by surprise if the fact that JTSi strictly adheres to its time limits for delivering projects. What is the result that you get from JTSi at the end of the day? My own satisfaction and company’s recognition!
      </p>
      <h5>
        Ramesh R – Team Lead – Software Engineering Services
      </h5>
    </div>
  </div>

  <div class="employee_testimonial rightpic">
    <div class="emphoto">
      <img src="../../assets/img//subash-chandran.webp" alt="">
    </div>
    <div class="empopinioin">
      <p>
        For me every single day with JTSi has been unique and gratifying. There are plenty of opportunities in front of you and every single project is your tool to gain knowledge. The company has always evolved to the competitive and ever-changing market conditions. Their many years of expertise are truly a competitive advantage. As an employee who has been with them for quite a while now I can undoubtedly say that this is one of the best companies to work with.
      </p>
      <h5>
        Subash Chandran – Architect – Projects
      </h5>
    </div>
  </div>

  <div class="employee_testimonial">
    <div class="emphoto">
      <img src="../../assets/img//preethy.webp" alt="">
    </div>
    <div class="empopinioin">
      <p>
        I was always concerned about managing my personal and professional lives. When I joined JTSi, the flexible working setting and the always approachable management made things easier. JTSi always make sure that their employees get equal opportunities and are heavily invested in elevating the professional knowledge through training and continuous development. Supportive work culture and wonderful colleagues to work with- it is my pride to be part of the JTSi team.
      </p>
      <h5>
        Preethy T S - Team Lead – Software Engineering Services
      </h5>
    </div>
  </div>
</div>

