<div class="innerHeader news">
  <div class="innerHeadings">
    <h1>Current  <span>News</span></h1>
  </div>
</div>

<app-cirlelinks></app-cirlelinks>

  <!-- <div class="holidaysection">
<div class="holidaycontent">
  <div class="holytitle">
    <h2>Happy Holidays and 
    <span>Happy New Year 2023</span> </h2>
  </div>
  <div class="holycontent">
    <h4>
      Wishing you happy holidays and prosperous New Year
    </h4>
    <p>
      Warmest thoughts and best wishes to you from all of us at JTSi. Thank you for choosing us as your partner and we look forward to serving you another year. May the coming year be full of grand adventures and opportunities.
    </p>
  </div>
</div>
<div class="holidvideo">
<iframe class="videoframe" src="https://www.youtube.com/embed/_SKEZ2Orf4Q" title="Merry Christmas & Happy New Year Wishes | JTSi Technologies India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div> -->
<div class="splitcontent mb-0">

  <div class=" contents leftSide">
    <h2>JTSi’s Web Presence</h2>
    <p>
      You can get in touch with JTSi from anywhere around the world virtually through our website. The presence of people and organizations in the web world is skyrocketing and it is very gratifying to communicate with our stakeholders anytime, anywhere through the JTSi website. There is no more desk-bound work for us anymore, we connect through the internet and collaboratively propel our tasks.
    </p><p>
      Our team is persistently trying to make the JTSi website platform look perfect and highly interactive through constant upgrades. We ensure that our sites are highly transparent and intuitive for better experience of the parties involved. The design, the content and every element that make our site look idyllic are always kept updated so that our team members and other stakeholders does not lose track of our site news.
    </p><p>
      Do not miss the latest updates happening at JTSi! Sign up with us today and know more about our products and services!

    </p>
  </div>
  <div class="pictures newsite">

  </div>
</div>
<div class="splitcontent mt-0 mb-0">
  <div class="pictures office_gap">

  </div>
  <div class="darkBg contents rightSide">
    <h2>Introducing  OfficeGaps</h2>
    <p>
      Wouldn’t it be great to have a software that can handle back-office operations for rationalizing your business? JTSi is pleased to introduce you to OfficeGaps, software that focuses on backend operations for swift and successful growth of the organization. OfficeGaps provide world-class back office software solutions to clients at most favorable costs. Additional advantages of OfficeGaps include high configurability and affordability. This transformative back office software solution is best suited for small, medium or large businesses. Based on the client's needs and specifications, we also provide trusted advisory services such as software selection and assist system configuration and implementation of integrated solutions that is critical for improving productivity and driving organizational growth.
    </p>
  </div>

</div>
<div class="splitcontent mt-0 mb-0">

  <div class="grayBg contents leftSide">
    <h2>Our New Office</h2>
    <p>
      JTSi's office is located at Infopark, Kochi- the growing world-class IT hub in Kerala. We recently moved into a new workspace within the Thapasya building with around 5500 square feet area that is well designed and uniquely furnished. Our infrastructure has a design that is planned and established ahead of its time.
    </p><p>
      We combine world-class technology with infrastructure, focusing on security and scalability to take your business to the next level. Our office has more than 120 seating capacity for employees and is equipped with the latest amenities like global connectivity to remote users and video conferencing.
    </p><p>
      The meeting rooms, conference halls, interview cabins, manager cabins and every single workstation are so well defined in order to have a serene atmosphere while our employees and consultants work. Different departments can collaboratively work despite having categorized departments into different spaces based on the technologies and services they work on.
    </p>
  </div>
  <div class="pictures newOffice">

  </div>
</div>



