import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-software-engine',
  templateUrl: './software-engine.component.html',
  styleUrls: ['./software-engine.component.scss']
})
export class SoftwareEngineComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,
    

  ) { 
    this.titleService.setTitle('Software Automation Engine Programming and Development firm | USA| New Jersey | JTSi Technologies');
    
  
    this.meta.addTags([
      {name:'description', content:'Our Software factory has software automation engines including database, data transformation, business rules and application software engines, which are developed by our dedicated software engineers'},
      {name:'keywords', content:'Software engine programming, software engine development, software frameworks and modules'},
      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:'Software Automation Engine Programming and Development firm | USA| New Jersey | JTSi Technologies '},
      { property:'og:description', content:'Our Software factory has software automation engines including database, data transformation, business rules and application software engines, which are developed by our dedicated software engineers'},
      { property:'og:url', content:'https://www.jtsiindia.com/capabilities/software-engine'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/software_engine_02.webp'},                                                           
      { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Software Automation Engine Programming and Development firm | USA| New Jersey | JTSi Technologies'},
      { name:'twitter:description', content:'Our Software factory has software automation engines including database, data transformation, business rules and application software engines, which are developed by our dedicated software engineers.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/software_engine_02.webp'},
      ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
