<header class="accountheader">
    <div class="topbar">
      <p>
        <svg>
          <use xlink:href="../../assets/img/imgaccounting/sprite.svg#email2"></use>
        </svg>
        <a href="mailto:info@jtsiindia.com">info@jtsiindia.com</a>
        <svg>
          <use xlink:href="../../assets/img/imgaccounting/sprite.svg#phone"></use>
        </svg>
        <a href="tel:+914844070368">+91 484 407-0368, </a>
        <a href="tel:+12024645874">+1(202)464-JTSi(5874)</a>
      </p>
      <ul class="social">
  
        <li>
          <a href="mailto:info@jtsiindia.com">
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#email"></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/jtsi-technologies-india" target="_blank" rel="noopener noreferrer">
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#linkedin"></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/jtsitechnologies" target="_blank" rel="noopener noreferrer">
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#facebook"></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/Jtsiindia" target="_blank" rel="noopener noreferrer">
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#twitter"></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCcnZRAkcseIL8MbR47A6wlA" target="_blank" rel="noopener noreferrer">
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#youtube"></use>
            </svg>
          </a>
        </li>
        <!-- <li>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#youtube"></use>
            </svg>
          </a>
        </li> -->
      </ul>
    </div>
    <nav>
      <div class="awardsandlogo">
        <a [routerLink]="['']" class="logo">
      <img src="../../assets/img/imgaccounting/JTSiindia-Logo.svg" alt="">
      <h5>Affordable | Quality | On-Time</h5>
        </a>
    <div class="awardpoints">
      <ul>
        <li>
        <a [routerLink]="['']" title="SOP/Work-Flow based">  <i class="icon ion-md-checkbox"></i> ISO 9001-2015</a>
        </li>
        <li>
          <a [routerLink]="['']" title="17 years of Reputation"> <i class="icon ion-md-checkbox"></i> 19 Years Into Business</a>
        </li>
        <li>
          <a [routerLink]="['']" title="3 Tier Facility Security Control"> <i class="icon ion-md-checkbox"></i> 3 Tier Security</a>
        </li>
        <li>
          <a [routerLink]="['']" title="Prestigious Software Technology Parks of India Member"> <i class="icon ion-md-checkbox"></i> Member STPI</a>
        </li>
      </ul>
    </div>
  </div>
    <div class="menu">
      <ul>
        <li><a [routerLink]="['/accounting-services']">
          Home
        </a></li>
        <li><a [routerLink]="['/accounting-services']" fragment="aboutaccounts">
        About Us
        </a></li>
        <li><a [routerLink]="['/accounting-services']" fragment="accountservcices">
          Services
          </a></li>
          <li><a [routerLink]="['/accounting-services']" fragment="accountsecurity">
            Security
            </a></li>
            <li><a [routerLink]="['/accounting-services']" fragment="accountprice">
              Delivery/Pricing
              </a></li>
              <li><a [routerLink]="['/accounting-services']" fragment="accountfooter">
                Contact
                </a></li>
      </ul>
    </div>
    <div class="navbtns">
  <a class="btns" [routerLink]="['/contact-us']" target="_blank" rel="noopener noreferrer">
    Get a Quote
  </a>
    </div>
  </nav>
  </header>
  
  <section class="hero">
    <article>
    <h1>
      Accounting & Financial <span>Services</span>
    </h1>
    <p>
      Our knowledgeable team of professionals have the experience and expertise to maximize your financial success.
    </p>
    <a [routerLink]="['/contact-us']" target="_blank" rel="noopener noreferrer">
      Get Started
    </a>
    </article>
    <picture>
      <div class="quicklogo">
        <img src="../../assets/img/imgaccounting/QuickBooks_logo.png" alt="">
      </div>
    <img src="../../assets/img/imgaccounting/bannerimage.png" alt="">
    </picture>
    </section>
    <section class="sections" id="aboutaccounts">
    <div class="containers">
    <div class="splitclmns">
      <picture>
        <img src="../../assets/img/imgaccounting/aboutus.jpg" alt="">
      </picture>
      <article>
        <h2>
          <span>
            About us
          </span>
          About us
        </h2>
        <p>
          JTSI Technologies India is a leading provider of accounting and financial services. We specialize in a range of services including auditing, tax accounting, financial accounting. and payroll services. Our team of experienced professionals are dedicated to helping our clients manage their finances in an efficient and cost-effective manner.
        </p>
        <div class="subsection">
        <p>
          We provide personalized solutions to meet each client's needs and goals. Our services are intended to save organization’s money and time while maintaining accuracy and legal compliance. We strive to provide the best possible service for our clients so that they can focus on growing their business without worrying about their Finance and accounting processes.
        </p>
        <img src="../../assets/img/imgaccounting/Ab2.jpg" alt="">
      </div>
      </article>
    </div>
    </div>
    </section>
    <section class="sections homegray" id="accountservcices">
        <div class="sectitles">
          <h2>
            Services
          </h2>
        </div>
        <div class="contentgrid">
          <article>
            <h2>
              Accounting & Financial Services
            </h2>
            <p>
              Accounting and financial services involves a wide range of activities such as bookkeeping, auditing, tax accounting, financial accounting and payroll services. An account services provider can provide businesses with the necessary support to ensure that all the financials are in order. Accounting and financial services also include budgeting, cash flow management and forecasting which helps businesses make better decisions when it comes to investing in new projects or expanding their operations. Moreover, these professionals can help companies manage their taxes by ensuring that they are following all the regulations set forth by the government.
            </p>
          </article>
          <picture>
            <img src="../../assets/img/imgaccounting/service.jpg" alt="">
          </picture>
        </div>
    </section>
    <section class="alomostgray">
    <div class="graygrid">
    <div class="clmn vcenter">
      <h3>
        Outsourced Accounting- Service Offerings
      </h3>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service01"></use>
      </svg>
      <h4>
        General Accounting and Bookkeeping Services
      </h4>
      <p>
        Preparation and maintenance of day-to-day bookkeeping and monthly or quarterly accounts, where we act as your accounting bookkeeper.
      </p>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service02"></use>
      </svg>
      <h4>
        Preparation of Financial Statements
      </h4>
      <p>
        Preparation of annual accounts for the statutory annual audit.
      </p>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service03"></use>
      </svg>
      <h4>
        Payroll Services (through our US office)
      </h4>
      <p>
        Preparation of salary statements of employees, calculation of bonus payments, and administering of commissions.
      </p>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service04"></use>
      </svg>
      <h4>
        Cash Forecasting Services
      </h4>
      <p>
        Analysis of the cash requirements of the business and forecasting cash requirements.
      </p>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service05"></use>
      </svg>
      <h4>
        Debt Collection Services
      </h4>
      <p>
        Assistance for the recovery of debts and taking necessary legal action.
      </p>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service06"></use>
      </svg>
      <h4>
        Reconciliations
      </h4>
      <p>
        Our services include various reconciliations such as general ledger, balance sheet, bank, credit card, intercompany, suspense account, stock, payroll transactions, revenue, and other client-specific reconciliations.
      </p>
    </div>
    <div class="clmn">
      <svg>
        <use xlink:href="../../assets/img/imgaccounting/sprite.svg#service07"></use>
      </svg>
      <h4>
        Analytical Reports
      </h4>
      <p>
        Our analytical reports include the following are financial sustainability liquidity analysis, profitability ratios, analysis of the business activity (turnover ratios), any other analytical reports based on client needs.
      </p>
    </div>
    </div>
    </section>
    
    
    <div class="grayribbon">
      <h3>
        For expert guidance and know-how, you can trust JTSi.
      </h3>
    </div>
    <section class="sections" id="accountsecurity">
      <div class="containers">
        <div class="splitclmns shadowed">
          <picture>
            <img src="../../assets/img/imgaccounting/security.jpg" alt="">
          </picture>
          <article>
            <h2 class="dblue">
              Security
            </h2>
            <ul class="bigtick">
              <li>All systems will be password protected USB ports will be disabled on all machines.</li>
    <li>Restricted internet access to protect the confidential data from sending out of the office.</li>
    <li>Employees who handle financial information will be placed closer to the supervisor's work stations for constant monitoring.</li>
    <li>CCTV cameras with remote access monitoring security check for all employees at the premises (access to client).</li>
    <li>PDAs, bags, pens & papers and cell phones not allowed inside the office. All documents are stored in client's system and not allowed to download any files to local systems.</li>
    <li>Advanced fire & alarm services connected to our vendor central security desk.</li>
    
            </ul>
    
          </article>
        </div>
        </div>
        </section>
    
        <section class="sections bluetale">
          <div class="containers">
            <div class="splitclmns">
              <article>
                <h2 class="dblue">
                  Internal Audits for Continuous Improvement
                </h2>
                <ul class="bigtick">
                  <li>Through Technical and Process Audits were conducted across the organization
                    <ul>
                      <li>Gaps were identified</li>
                      <li> Processes got streamlined</li>
                      <li>Observations are getting closed</li>
                    </ul>
                  </li>
                  <li>The audit cycles will be repeated on a monthly basis</li>
                  <li>Audit findings will be discussed with the Head- Accounting BPO and the deviations are taken very seriously</li>
                  <li>Audit findings will be recorded and the respective heads are supposed to close the observations in stipulated time</li>
                  <li>Continuous Technical and Process training for the team will be implemented</li>
    
                </ul>
              </article>
              <picture>
                <img src="../../assets/img/imgaccounting/audits.jpg" alt="">
              </picture>
            </div>
            </div>
            </section>
    
    
        <section class="sections justgray" id="accountprice">
          <div class="containers">
            <div class="sectitles">
              <h2>
                Delivery / Pricing Model
              </h2>
            </div>
            <h3>
              Delivery
            </h3>
            <div class="delivergrid">
              <div class="clmn">
                <svg>
                  <use xlink:href="../../assets/img/imgaccounting/sprite.svg#hire"></use>
                </svg>
              </div>
              <div class="clmn">
                <h4>
                  Hire Resources
                </h4>
                <p>
                  Our Dedicated Accounting experts offer the highest-quality resources at the best possible price range and focus on delivering smart work within the stipulated time.
                </p>
              </div>
              <div class="clmn">
                <h4>
                  Team as a Service (TaaS)
                </h4>
                <p>
                  Our Team as a Service enables organisations to put together teams of people who have the precise talents required for the project. It provides to individuals who may need to scale up work quickly due to rapidly changing requirements.
                </p>
              </div>
              <div class="clmn">
                <svg>
                  <use xlink:href="../../assets/img/imgaccounting/sprite.svg#taas"></use>
                </svg>
              </div>
            </div>
            <h3>
              Pricing
            </h3>
            <div class="pricegrid">
              <div class="clmn">
                <span>
                  <svg>
                    <use xlink:href="../../assets/img/imgaccounting/sprite.svg#tandm"></use>
                  </svg>
                </span>
                <h4>
                  Time and Material (T&M)
                </h4>
                <p>
                  Client-friendly model where clients pay only for time and resources consumed!
                </p>
              </div>
              <div class="clmn">
                <span>
                  <svg>
                    <use xlink:href="../../assets/img/imgaccounting/sprite.svg#fulte"></use>
                  </svg>
                </span>
                <h4>
                  Full Time Equivalent (FTE)
                </h4>
              <p>
                It provides clients with individual employees who work on a full time basis thereby eliminating the need to spend time on calculating the hourly work remuneration and team velocity.
              </p>
              </div>
              <div class="clmn">
                <span>
                  <svg>
                    <use xlink:href="../../assets/img/imgaccounting/sprite.svg#ffprice"></use>
                  </svg>
                </span>
                <h4>
                  Firm Fixed Price (FFP)
                </h4>
                <p>
                  It provides for a price that is not subject to any adjustment on the basis of the contractor's cost experience in performing the contract.
                </p>
              </div>
            </div>
            </div>
            </section>
    <section class="sections lightblue">
      <div class="sectitles">
        <h2>
          Clients
        </h2>
      </div>
      <div class="containers">
        <swiper #swiperRef [config]="config"
        [breakpoints]="breakpoints" [loop]="false">
    
               <ng-template swiperSlide>
                 <div class="card">
                  <img src="../../assets/img/imgaccounting/partners/Aig.jpg" alt="">
               </div>
               </ng-template>
    
               <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/AT.jpg" alt="">
              </div>
              </ng-template>
    
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/Bristol.jpg" alt="">
              </div>
              </ng-template>
    
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/Chaseo.jpg" alt="">
              </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/ConEdison.jpg" alt="">
              </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/covanta.jpg" alt="">
              </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/Johnson&Johnson.jpg" alt="">
              </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/Jtsi-Johnson-technology.jpg" alt="">
              </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card">
                 <img src="../../assets/img/imgaccounting/partners/Phzer.jpg" alt="">
              </div>
              </ng-template>
             </swiper>
      </div> 
    </section>
    <footer class="acfooter" id="accountfooter">
        <div class="footop">
        <div class="footbox">
        <h3>
          Where To Find Us
        </h3>
        <div class="clmn">
          <picture>
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#address"></use>
            </svg>
          </picture>
          <address>
            <h4>
              JTSi Technologies India Pvt. Ltd.
            </h4>
            <p>
              Third Floor, Thapasya Building,Infopark,
              <br>
        Kakkanad,Kochi – 682042, Kerala, India.
            </p>
          </address>
        </div>
        <div class="clmn">
          <picture>
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#email"></use>
            </svg>
          </picture>
          <address>
            <h4>
              Email Us
            </h4>
            <p>
              Email: marketing@jtsiindia.com
              <br>
        Support: info@jtsiindia.com
            </p>
          </address>
        </div>
        <div class="clmn">
          <picture>
            <svg>
              <use xlink:href="../../assets/img/imgaccounting/sprite.svg#phone"></use>
            </svg>
          </picture>
          <address>
            <h4>
              Call Us
            </h4>
            <p>
              +91 484 407-0368
              <br>
        +1(202)464-JTSi(5874)
            </p>
          </address>
        </div>
        </div>
        <div class="footform">
        <h2>
          Sign up for Free
        </h2>
        <p>
          Sign up to our Newsletter to get the latest news and offers.
        </p>
        <div class="footfrmgroup">
          <input type="email" name="" id="" placeholder="Enter your email">
          <button type="submit">Subscribe</button>
        </div>
        <div class="footlinks">
          <div class="clmn">
            <h5>
              Discover
                </h5>
                <ul>
                  <li><a [routerLink]="['/why-JTSi']">About Us</a></li>
                    <li><a [routerLink]="['/capabilities']" >Capabilities</a></li>
                        <li><a  [routerLink]="['/delivery-pricing']" >Delivery/Pricing</a></li>
                          <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
                </ul>
          </div>
          <div class="clmn">
            <!-- <h5>
              Services
                </h5>
                <ul>
                  <li><a routerLink="['']">
                    Accounting & Financial Services
                  </a></li>
                    <li><a routerLink="['']">
                      Outsourced Accounting- Service Offerings
                      </a></li>
                </ul> -->
          </div>
        </div>
        </div>
        </div>
        <div class="copyrights">
          &#169; Copyright 2023 by JTSi Technologies India Pvt. Ltd.
        </div>
        </footer>
        