import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from '../utils/commonfunctions.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService

  ) { 
    this.titleService.setTitle('Disclaimer Policy| JTSi Technologies');
    
    this.meta.addTags([
      {name:'description', content:'Take the time to review disclaimer policies and safeguard your business'},
      { property:'og:locale', content:'en_US'},
      { property:'og:type', content:'website'},
      { property:'og:title', content:' Disclaimer Policy| JTSi Technologies. '},
      { property:'og:description', content:'Take the time to review disclaimer policies and safeguard your business'},
      { property:'og:url', content:'https://www.jtsiindia.com/disclaimer'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Disclaimer.webp'},                                                           { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'Disclaimer Policy| JTSi Technologies.'},
      { name:'twitter:description', content:'Take the time to review disclaimer policies and safeguard your business.'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Disclaimer.webp'},


    ])
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();

  }

}
