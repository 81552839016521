<div class="innerHeader deployable sapim">
    <div class="innerHeadings">
<!-- <h4>Deployable Solutions</h4> -->
<h1>SAPiM V1</h1>

</div>
</div>

<div class="diplotop">

    <div class="texts">
        <div class="heading">
            <div class="darkBg">
                <h2>Pellentesque  morbi habitant</h2>
            </div>
            <div class="redBg">
                <h3>Nullam scelerisque bibendum auctor fusce vel sapien sed tellus dapibus dictum</h3>
            </div>
        </div>
        <p>Morbi auctor dui scelerisque fringilla hendrerit. In condimentum, ante nec ultrices pretium, sem magna tincidunt velit, ac vehicula nisi ligula a turpis. Aenean felis orci, interdum et justo ac, venenatis ullamcorper quam. Suspendisse eu accumsan quam. Nam leo diam, luctus quis ullamcorper et, consequat a tortor. Duis egestas, magna rhoncus luctus fermentum, urna ante mollis lectus, et scelerisque lorem lectus id diam. Phasellus tincidunt pharetra egestas. Integer rutrum orci eu eleifend fringilla. Vivamus eget molestie nunc. Ut blandit quam et nunc semper hendrerit. Nulla sed sem nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque bibendum auctor. Fusce vel sapien sed tellus dapibus dictum.</p>
    <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras tincidunt maximus venenatis. Ut quis libero ut magna aliquam faucibus pellentesque vel odio. Cras in tellus nec libero cursus ornare id eget felis. Ut lectus nisi, tempor eget aliquam ac, imperdiet eu nibh. Curabitur quis facilisis libero, ut ullamcorper lacus. Nunc nunc elit, aliquam quis elit sed, volutpat gravida neque. Fusce maximus mauris a imperdiet luctus.</p>
    </div>
    <div class="picture">
        <img class="img-fluid" src="../../assets/img/bo_02.webp" alt="">
    </div>
</div>

<div class="fullwidthSection grayBg">
<div class="commonContents">
    <div class="boxtitle_two">
        <h2>Excepturi harum iure</h2>
    </div>
    <div class="iconColumns">
        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#communication"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#customerFirst"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#contracts"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#weDo"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#manager"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#reviews"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#userlog"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

        <div class="iconbox">
            <svg>
                <use xlink:href="../../assets/img/sprite.svg#search"></use>
              </svg>
              <h4>Pellentesque habitant morbi</h4>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptatem dolor quo, sapiente, ipsam ratione reiciendis.</p>
        </div>

    </div>
</div>
</div>

<div class="splitcontent">
    <div class="pictures bo_03">
       
    </div>
    <div class="whiteBg contents rightSide">
        <h2>Pellentesque habitant senectus</h2>
        <ul class="bulletpoints">
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid porro, aspernatur delectus, excepturi harum iure saepe expedita similique deleniti repellendus fugit nisi accusamus pariatur ducimus, impedit nulla dicta nihil id.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid porro, aspernatur delectus, excepturi harum iure saepe expedita similique deleniti repellendus fugit nisi accusamus pariatur ducimus, impedit nulla dicta nihil id.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid porro, aspernatur delectus, excepturi harum iure saepe expedita similique deleniti repellendus fugit nisi accusamus pariatur ducimus, impedit nulla dicta nihil id.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid porro, aspernatur delectus, excepturi harum iure saepe expedita similique deleniti repellendus fugit nisi accusamus pariatur ducimus, impedit nulla dicta nihil id.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid porro, aspernatur delectus, excepturi harum iure saepe expedita similique deleniti repellendus fugit nisi accusamus pariatur ducimus, impedit nulla dicta nihil id.</li>
        </ul>
    </div>
</div>
<app-cirlelinks></app-cirlelinks>