import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss']
})
export class MobileAppComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
      this.titleService.setTitle('Best IOS & Android Mobile Application Development company| USA| Washington DC| JTSi Technologies');
      this.meta.addTags([
      {name:'description', content:'JTSi is a leading mobile app development company in Virginia & New Jersey, USA with a strong team of app developers and expertise in developing Android, IOS and Hybrid mobile apps for start-ups and enterprises'},
      {name:'keywords', content:'Mobile application development, IOS App development, Android App development'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:' Best IOS & Android Mobile Application Development company| USA| Washington DC| JTSi Technologies '},
      {property :'og:description', content:'"JTSi is a leading mobile app development company in Virginia & New Jersey, USA with a strong team of app developers and expertise in developing Android, IOS and Hybrid mobile apps for start-ups and enterprises'},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/mobile-application-development' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/Mobile-Application-Development.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Best IOS & Android Mobile Application Development company| USA| Washington DC| JTSi Technologies'},
      {name:'twitter:description', content:'JTSi is a leading mobile app development company in Virginia & New Jersey, USA with a strong team of app developers and expertise in developing Android, IOS and Hybrid mobile apps for start-ups and enterprises'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Mobile-Application-Development.webp'}
      ]);
    }  
  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
