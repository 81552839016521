import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Careers } from './careers.model';
 
@Injectable({
  providedIn: 'root'
})
export class CareersService {
  constructor(private http: HttpClient) { }
  getRequisiotnDetail() {
    return this.http.get(`${environment.apiServer}Requisition`); 
  }
  getRequisiotnDetails():Observable<Careers[]> {
    return this.http.get<Careers[]> (`${environment.boApiServer}Requisition/GetRequisitionDetails`); 
  }
}
