import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-whyjtsigrid',
  templateUrl: './whyjtsigrid.component.html',
  styleUrls: ['./whyjtsigrid.component.scss']
})
export class WhyjtsigridComponent implements OnInit {

  
  constructor(
    
    private titleService: Title,
    private meta: Meta, private commonfunctions : CommonfunctionsService

  )

  {
    this.titleService.setTitle('JTSi Technologies: Software Development Projects | IT Onsite Services Based in India | USA');
    this.meta.addTags([
      {name:'description', content:'JTSi delivers cutting edge software development projects and provides software services, helping companies across the globe untangle complex issues that persist in their business processes'},
      {property:'og:locale', content:'en_US' } ,
	    {property:'og:type', content:'website'},
      {property:'og:title' ,content:'JTSi Technologies : Software Development Projects | IT Onsite Services Based in India | USA'},
	    {property:'og:description', content:'JTSi delivers cutting edge software development projects and provides software services, helping companies across the globe untangle complex issues that persist in their business processes'},
      {property:'og:url', content:'https://www.jtsiindia.com/why-JTSi'},
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/why-jtsi-header.webp'},
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time' },
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'JTSi Technologies : Software Development Projects | IT Onsite Services Based in India | USA'},
      {name:'twitter:description', content:'JTSi delivers cutting edge software development projects and provides software services, helping companies across the globe untangle complex issues that persist in their business processes.'},
      {name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/why-jtsi-header.webp'}
      ]);
  }  

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
