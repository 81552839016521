<div class="innerHeader leadership">
    <div class="innerHeadings">
  <h1>Leadership  <span>Team</span></h1>
  </div>
  </div>
  
  <div class="captop">
      <div class="picture">
          <img class="img-fluid" src="../../assets/img/joe.webp" alt="">
      </div>
      <div class="texts">
          <div class="heading">
              <div class="redBg">
                  <h2>Joe MeenamKunnel</h2>
              </div>
              <div class="darkBg">
                  <h3>Chairman & Managing Director</h3>
              </div>
          </div>
         <p>Joe ipsum dolor sit amet, consectetur adipiscing elit. Integer maximus erat vitae lectus interdum, at tempus lectus pharetra. Nunc mollis ornare dapibus. Praesent elementum odio volutpat, gravida velit et, finibus arcu. Pellentesque dapibus congue interdum. In sed semper magna, nec dictum lacus. Ut ut eleifend ipsum, sed rutrum felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum egestas mollis nunc ut bibendum. Maecenas pharetra congue ligula nec vehicula. Fusce velit quam, commodo et iaculis eget, mollis in arcu. Duis fermentum quis magna nec consequat. Donec a gravida quam, nec congue enim. Maecenas eros ligula, porta vitae ullamcorper in, placerat vitae erat. Donec volutpat maximus urna, nec vehicula orci euismod non. Nulla lacinia porttitor volutpat.</p>
      </div>
  </div>
  
  <div class="commonPadding grayBg topm5">
    <div class="containers">
  <div class="boxtitle_two">
    <h2>Our Team</h2>
  </div>
  <div class="teamtext">
    <p>
      JTSi has a dedicated group of individuals who works as a strong team to give the best service to its clients. For us, commitment is the key to customer satisfaction. We follow the best industrial standards in delivering our products and services to the clients around the world. Our young, talented, and enthusiastic professionals are given time to time training in the latest tools and technologies to ensure quality of service. More than the price JTSi believes in providing exceptional service to our clients. This is one place where there is no shortage for talent, experience, and skill. With its state-of-the-art-facilities and hardworking employees we are committed to give our best to every opportunity that comes our way. JTSi knows the value of its client’s time and money, so we ensure that our clients’ specific requirements are met on time.
    </p>
  </div>
  <div class="teams">
    <div class="teamember">
      <div class="overlays">
        <p>Philip ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/philip.webp" alt="">
      <div>
        <h4>Philip Mathew</h4>
        <p>Director – India</p>
      </div>
    </div>
  
    <div class="teamember">
      <div class="overlays">
        <p>Manoj ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/manoj.webp" alt="">
      <div>
        <h4>Manoj kumar</h4>
        <p>V.P - Product and Services (global)</p>
      </div>
    </div>
  
    <div class="teamember">
      <div class="overlays">
        <p>Sandosh ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/manavalan.webp" alt="">
      <div>
        <h4>Sandosh Manavalan</h4>
        <p>V.P – Global Service Delivery</p>
      </div>
    </div>
  
    <div class="teamember">
      <div class="overlays">
        <p>Sumesh ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/sumesh.webp" alt="">
      <div>
        <h4>Sumesh K.K </h4>
        <p>Manager Finance</p>
      </div>
    </div>
  
    <div class="teamember">
      <div class="overlays">
        <p>Rajesh ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/rajesh.webp" alt="">
      <div>
        <h4>Rajesh C.J </h4>
        <p>Project Manager</p>
      </div>
    </div>
  
    <div class="teamember">
      <div class="overlays">
        <p>Sanjay ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/sanjay.webp" alt="">
      <div>
        <h4>Sanjay Parackal </h4>
        <p>Program Manager</p>
      </div>
    </div>
  
    <div class="teamember">
      <div class="overlays">
        <p>Vimal ipsum dolor sit amet consectetur adipisicing elit. Neque rem ratione possimus aspernatur repellat quia, autem porro! Facilis harum obcaecati sint officiis. Dolorum consequuntur, ullam magni cupiditate nisi consectetur quaerat.</p>
      </div>
      <img src="../../assets/img/vimal.webp" alt="">
      <div>
        <h4>Vimal Joseph Mathews </h4>
        <p>Sales Manager</p>
      </div>
    </div>
  
  </div>
    </div>
  </div>
  <app-cirlelinks></app-cirlelinks>