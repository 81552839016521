import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { NgForm, FormGroup, Form, FormBuilder } from '@angular/forms';
import { ContactService } from '../shared/contact.service';
import { ToastrService } from 'ngx-toastr';
import { Contact } from '../shared/contact.model';
import { CommonfunctionsService } from '../utils/commonfunctions.service';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  phoneValid = true;
  constructor(
    public service: ContactService,
    public commonfunctions: CommonfunctionsService,
    private toaster: ToastrService,
    private titleService: Title,
    private meta: Meta,
    private _renderer2:Renderer2,@Inject(DOCUMENT) private _document:Document

  ) {
    this.titleService.setTitle('Contact Us | Find Office locations | JTSi Technologies');
    this.meta.addTags([
      { name: 'description', content: 'Invest in best software development solutions for New Jersey, Washington DC, US, UK, Canada, India, and Europe. Get in touch with our team.' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Contact Us | Find Office locations | JTSi Technologies' },
      { property: 'og:description', content: '"Invest in best software development solutions for New Jersey, Washington DC, US, UK, Canada, India, and Europe. Get in touch with our team.' },
      { property: 'og:url', content: '"https://www.jtsiindia.com/contact-' },
      { property: 'og:image', content: 'https://www.jtsiindia.com/assets/img/locations.webp' },
      { property: 'og:site_name', content: 'JTSi Technologies India' },
      { property: 'article:modified_time', content: 'fetch current time' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@Jtsiindia' },
      { name: 'twitter:creator', content: '@Jtsiindia' },
      { name: 'twitter:title', content: 'Contact Us | Find Office locations | JTSi Technologies' },
      { name: 'twitter:description', content: 'Invest in best software development solutions for New Jersey, Washington DC, US, UK, Canada, India, and Europe. Get in touch with our team.' },
      { name: 'twitter:image', content: 'https://www.jtsiindia.com/assets/img/locations.webp' }
    ]);
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
    // let script1=this._renderer2.createElement('script');
    // script1.type=`application/Id+json`;
    // script1.text=`{
    //   "@context": "http://www.schema.org",
    //   "@type": "ProfessionalService",
    //   "name": "JTSi Technologies India Pvt.Ltd",
    //   "url": "https://www.jtsiindia.com",
    //   "logo": "https://www.jtsiindia.com/assets/img/JTSi-Logo.svg",
    //   "image": "https://www.jtsiindia.com/assets/img/slide_01.webp",
    //   "description": "JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.",
    //   "address": {
    //     "@type": "PostalAddress",
    //     "streetAddress": "Third Floor, Thapasya Building, Infopark, Kakkanad",
    //     "addressLocality": "Kochi",
    //     "addressRegion": "Kerala",
    //     "postalCode": "682042",
    //     "addressCountry": "India"
    //   },
    //   "openingHours": "Mo, Tu, We, Th, Fr 09:30-18:30",
    //   "contactPoint": {
    //     "@type": "ContactPoint",
    //     "telephone": "+91 484 407-0368",
    //     "contactType": "Local"
    //   }
                               
    
    // }`
    // this._renderer2.appendChild(this._document.body,script1)
    // let script2=this._renderer2.createElement('script');
    // script2.type=`application/Id+json`;
    // script2.text=`{
  
    
    // {
    //   "@context": "http://www.schema.org",
    //   "@type": "ProfessionalService",
    //   "name": "JTSi Technologies India Pvt.Ltd",
    //   "url": "https://www.jtsiindia.com",
    //   "logo": "https://www.jtsiindia.com/assets/img/JTSi-Logo.svg",
    //   "image": "https://www.jtsiindia.com/assets/img/slide_01.webp",
    //   "description": "JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.",
    // "address": {
    // "@type": "PostalAddress",
    // "streetAddress": "11490 Commerce Park Dr., 4th Floor,
    // Reston, Virginia, USA",
    // "addressLocality": "Reston",
    //  "addressRegion": "Virginia",
    // "postalCode": "20191",
    //  "telephone": "+1 (703)949-9100"
    //                            },
    //   "openingHours": "Mo, Tu, We, Th, Fr 09:30-18:30"
                               
    // }
    
    //   `
    //  this._renderer2.appendChild(this._document.body,script2)
    }
  onSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }
    if (!this.commonfunctions.validateWebsite(frm.value.website) && frm.value.website != '' && frm.value.website != null) {
      this.toaster.error("Website is not valid");
      return;
    }
    frm.controls['phoneNumber'].setValue(frm.value.countryCode + frm.value.phoneNumber);
    console.log(frm.value);
    this.service.postContactDetail().subscribe(
      res => {
        this.resetForm(frm);
        this.toaster.success("You have successfully submitted response", "");
      },
      err => {
        this.toaster.error("Failed to submit response", "");
        console.log(err);
      }
    )
  }
  resetForm(frm: NgForm) {
    frm.resetForm();
    this.service.formData = new Contact();
  }
  hasError(event: any, frm: NgForm) {
    this.phoneValid = true;
    if (!event && frm.value.phoneNumber != "") {
      this.phoneValid = false;
      //frm.controls['phoneNumber'].setErrors(["Invalid Phone",true]);
    }
  }
  onContryChange(event: any, frm: NgForm) {
    frm.controls['countryCode'].setValue(event.dialCode);
  }

}