
<div id="homeSlider" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <ol class="carousel-indicators">
      
    <li data-bs-target="#homeSlider" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#homeSlider" data-bs-slide-to="1"></li>
    <li data-bs-target="#homeSlider" data-bs-slide-to="2"></li>
    <li data-bs-target="#homeSlider" data-bs-slide-to="3"></li>
    <li data-bs-target="#homeSlider" data-bs-slide-to="4"></li>
    <li data-bs-target="#homeSlider" data-bs-slide-to="5"></li>
  </ol>
  <div class="carousel-inner">

    <!-- <img src="../../assets/img/New-year-title.gif" alt="" class="newyeartitle"> -->
      
    
    <div class="carousel-item active slide_01">
        
      <div class="carousel-caption">
        <h1>
          You Dream IT
          <span>We Build IT</span>
        </h1>
        <a [routerLink]="['/capabilities/software-engineering-center']" id="learn-more-soft-engineering">Learn More</a>
      </div>
    </div>
    <div class="carousel-item slide_02">
      <div class="carousel-caption">
        <h1>OfficeGaps </h1>
        <a [routerLink]="['/solutions/OfficeGaps']" id="learn-more-officegaps">Learn More</a>
      </div>
    </div>
    <div class="carousel-item slide_03">
      <div class="carousel-caption">
        <h1 class="sapcolor">
          SAP Support &#38;
          <span>Solution Development Services </span>
        </h1>
        <a [routerLink]="['/capabilities/SAP-support-services']" id="learn-more-sap">Learn More</a>
      </div>
    </div>
    <div class="carousel-item slide_04">
      <div class="carousel-caption">
        <h1>Careers</h1>
        <a [routerLink]="['/careers']" id="learn-more-careers">Learn More</a>
      </div>
    </div>
    <div class="carousel-item slide_05">
      <div class="carousel-caption">
          <h1>JTSi Software Factory
              <span>Up your game</span>
              In 90 Days
            </h1>
        <a [routerLink]="['/capabilities/software-factory']" id="learn-more-soft-factory">Learn More</a>
      </div>
    </div>
    <div class="carousel-item slide_06">
      <div class="carousel-caption">
          <h1>Team as a Service(TaaS) 
              <span>Your friendy off-shore team in minutes! </span>
            </h1>
      
        <a [routerLink]="['/delivery-pricing/Team-as-a-Service']" id="learn-more-team-service">Learn More</a>
      </div>
      
    </div>

  </div>
  <a class="carousel-control-prev" href="#homeSlider" role="button" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </a>
  <a class="carousel-control-next" href="#homeSlider" role="button" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </a>
  
</div>

<app-cirlelinks></app-cirlelinks>
<section class=" commonPadding">
  <div class="containers">
    <div class="newHomeAbout">
      <div class="pictureSide">
        <div class="picture">
          <img src="../../assets/img/newHomeAbout.webp" alt="Best software development company in NJ,NY,Washington DC, USA.">
        </div>
      </div>
      <div class="textSide">
        <h2>About Us</h2>
  <p>
    JTSi Technologies India Private Limited was established in the year 2004. Over the years, we have been in the frontier of <strong> Software Development Business, IT Offshore Development Services and Professional Consulting Services</strong>. Our ideal works have earned us global clients that also include Fortune 100 companies. Clients across the globe have been trustily availing our services in various multitudes like IT consulting, recruitment services and accounting services.
  </p>
<p>
  JTSi has dexterous teams who build, integrate and manage IT systems for public as well as private enterprises. Our customer base is herculean, rooted on valuable partnerships and fervent workforce. JTSi handles multifaceted IT projects with innovative solutions under the supervision of Project Managers with decades of IT experience.
</p>
        <a class="btn btn-lg btn-primary" [routerLink]="['/why-JTSi/who-we-are']" id="who-we-are-button">Who We Are →</a>
      </div>
    </div>
  </div>
</section>

<section class="grayBg commonPadding">
  <div class="containers">
    <div class="homeCompany">

      <div class="textSide">
          <h2> <span>Software</span> Development Company</h2>
          <p>
            JTSi is highly experienced in delivering impactful sofware solutions that leverage the latest technologies. As a software development company, we have start-ups, <strong> Small and Medium Enterprises (SME) </strong> and <strong>Fortune 100 companies </strong>  in our clientele. Our team focuses on developing reliable and scalable software solutions. Our custom solutions are the resultant of deep industry knowledge and compliance to latest technological advancements.
          </p>
          <p>
            JTSi has a team who is dab hand at various subject matters and young technology-savvy minds in our team. The software development framework of JTSi provide a competitive advantage for our clients. We are working towards accomplishing automation to push your businesses to maximum performance limits. 
          </p>
          <p>
            Our team is dedicatedly working on software needs arising from different business platforms like <strong>ERP, CRM, Logistics, Retail and E-commerce, Healthcare, HRM software, Banking and Accounting software, FinTech </strong> and other software development projects. 
          </p>
      </div>
      <div class="pictureSide">
        <div class="pictures">
          <img src="../../assets/img/homeCompany.webp" alt="Professional IT Offshore development services in New jersey, USA.">
        </div>
      </div>

    </div>
  </div>
</section>
<section class=" commonPadding">
  <div class="containers">
    <div class="texttitle_two">
      <h2>Partner with us for all your software needs</h2>
    </div>

    <div class="partnerNeeds">
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#Inquire"></use>
          </svg>
        </div>
        <h3>
          Enquiry
        </h3>
        <p>
          Any IT requirements you have can be shared with us
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#scoping"></use>
          </svg>
        </div>
        <h3>
          Scoping
        </h3>
        <p>
          We will come up with a clearly defined roadmap
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#proposal"></use>
          </svg>
        </div>
        <h3>
          Proposal
        </h3>
        <p>
          Detailed proposal is created for a better transition
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#develop"></use>
          </svg>
        </div>
        <h3>
          Develop
        </h3>
        <p>
          Our CoE develops the right solution for you
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#packages"></use>
          </svg>
        </div>
        <h3>
          Package
        </h3>
        <p>
          Your product is packaged before release
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#golive"></use>
          </svg>
        </div>
        <h3>
          QA & Go Live
        </h3>
        <p>
          Go live with the help of our implementation specialists
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#supportive"></use>
          </svg>
        </div>
        <h3>
          Supp. & Maint.
        </h3>
        <p>
          Your products are cared for throughout their life cycle
        </p>
      </div>
      <div class="partBox">
        <div class="partIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#postgolive"></use>
          </svg>
        </div>
        <h3>
          Post Go Live
        </h3>
        <p>
          We will support you as your product evolve
        </p>
      </div>
    </div>
  </div>
</section>

<!-- <app-capabilitiesgrid></app-capabilitiesgrid> -->
<app-capabilities-item></app-capabilities-item>







<section class="commonPadding">
  <div class="containers">
    <div class="texttitle_two">
      <h2>Easy Hiring Process</h2>
    </div>

    <div class="easygrid">
      <div class="easybox">
        <div class="easyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#sahreidea"></use>
          </svg>
        </div>
        <h3>
          Share Job Description
        </h3>
        <p>
          Need an employee? Tell us about the job requirement and we will track the right candidate who befits your organization. Your staffing needs, our obligation!
        </p>
      </div>
      <div class="easybox">
        <div class="easyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#interview"></use>
          </svg>
        </div>
        <h3>
          Interview Candidates
        </h3>
        <p>
          Entrust candidate short listing, initial interview and other hiring processes to our expert team and we will provide a broad candidate list to choose from!
        </p>
      </div>
      <div class="easybox">
        <div class="easyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#weDo"></use>
          </svg>
        </div>
        <h3>
          Hire Right Candidates
        </h3>
        <p>
          Fill the employee gap in your organization with the right resource. We will make sure that your organization’s projects run smoothly with sufficient resources to drive it onward.
        </p>
      </div>
    </div>
  </div>
</section>


 <!-- <app-deliverypricinggrid></app-deliverypricinggrid> -->
 <app-delivery-pricing-item></app-delivery-pricing-item>

<section class="commonPadding">
  <div class="containers">
    <div class="texttitle_two">
      <h2>Convenient Pricing Models</h2>
    </div>

    <div class="tabgrid">
      <div class="phonetab">
        <div class="pricingtab " id="tandm">
          <div class="icons">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#hoverglass"></use>
            </svg>
          </div>
          <h3>Time and Material (T&M)</h3>
          <ul class="displaylist">
            <li>	Allows for a flexible and negotiable project budget</li>
            <li>	Low risks involved for client and service providing company</li>
            <li>	Pay in fractions and only for completed tasks</li>
            <li>	For long term projects that cannot determine the expected final product in the initial phases, T&M contracts can do the best</li>

          </ul>
        </div>
        <div class="pricingtab " id="fte">
          <div class="icons">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#timings"></use>
            </svg>
          </div>
          <h3>Full Time Equivalent (FTE)</h3>
          <ul class="displaylist">
            <li>	Applying the FTE model minimizes the risk.</li>
            <li>	Recruit staff on a fixed-time and fixed-wage basis for projects with unplanned needs</li>
            <li>	Our clients will have the full control of the hired person</li>
            <li>	There are no employee layoffs but only closure of decided tenure of hired employee</li>
            <li>	Extra advantageous for clients who are into software development</li>

          </ul>
        </div>
        <div class="pricingtab" id="ffp">
          <div class="icons">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#pricing"></use>
            </svg>
          </div>
          <h3>Firm Fixed Price (FFP)</h3>
          <ul class="displaylist">
            <li>	FFP is well suited for short-term project scopes</li>
            <li>	For limited requirements, the price stands fixed</li>
            <li>	There is precise depiction of what the project goals are and the need for change is hence minimized</li>
            <li>	The need for project flexibility is completely unneeded</li>

          </ul>
        </div>
      </div>
      <div class="righttab">
          <ul class="tabuls">
         

              <li class="tabitem" (click)="priceMethod('event', 'tandm')" id="opened">
                <div class="tabtitle">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#hoverglass"></use>
                  </svg>
                  <h3>Time and Material (T&M)</h3>
                </div>
                <p>
                  Client-friendly model where clients pay only for time and resources consumed!
                </p>
              </li>
              <li class="tabitem" (click)="priceMethod('event', 'fte')">
                <div class="tabtitle">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#timings"></use>
                  </svg>
                  <h3>Full Time Equivalent (FTE)</h3>
                </div>
                <p>
                  Your gauge to quantify full-time and part-time employees!
                </p>
              </li>
              <li class="tabitem" (click)="priceMethod('event', 'ffp')">
                <div class="tabtitle">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#pricing"></use>
                  </svg>
                  <h3>Firm Fixed Price (FFP)</h3>
                </div>
                <p>
                  Fixed price model where resources or time spent does not tot up!
                </p>
              </li>
            </ul>
      </div>
    </div>
  </div>
</section>
<div class="clientele">
  <div class="sec_title_01">
    <h2>Clientele</h2>
    <h4>Delivering better solutions that drive superior services & business results</h4>
  </div>
  <div class="containers text-center">
    <p>
      Smaller projects are smarter projects. Rather than thinking big, let’s think iterative. It is our specialty. Not only are small projects more likely to be on time and budget, but they also let you win quicker and learn more. Quick wins build momentum for acting on learnings. And that is why we’re driven to make ideas work.
    </p><p>
      We map out quick wins to help your business make big strides. We have worked with diverse industry segments including Pharmaceutical & Healthcare, Aerospace & Defence, Oil & Gas, Banking, Associations & State Governments, Retail, and Industrial. We are committed to the success of every client and have an exceptional client-retention rate. We have consistently delivered excellence through quality.
    </p>
    <div class="logolists">
      
      <img src="../../assets/img/AT.webp" alt="AT&T|Software Development company in USA">
      <img src="../../assets/img/bluecross.webp" alt="Bluecross & Blueshield | Offshore Development company in USA">
      <img src="../../assets/img/Bristol.webp" alt="Bristol-Myers Squibb | Software Development company in Virginia">
      <img src="../../assets/img/Chaseo.webp" alt=" Chase| Custom Software Development company in USA" >
      <img src="../../assets/img/ConEdison.webp" alt="Conedison | Enterprise Software Development company in USA">
      <img src="../../assets/img/covanta.webp" alt="Covanta Energy |Top Software Development company in Virginia ">
      <img src="../../assets/img/Johnson&Johnson.webp" alt="Johnson&Johnson | Software Development company inwashington DC">
      <img src="../../assets/img/Jtsi-Johnson-technology.webp" alt="JTSI |Best Software Development company in New jersey">
      <img src="../../assets/img/merck.webp" alt="MERCK | Professional Software Development company in USA" >
      <img src="../../assets/img/metlife.webp"  alt="METLIFE | IT Offshore Development company in USA" >
      <img src="../../assets/img/Phzer.webp"  alt="Pfizer | Software OutSourcing Development company in USA" >
      <img src="../../assets/img/Prudential.webp"  alt="Prudential | Product Software Development company in USA" >
      <img src="../../assets/img/sharp.webp"  alt="Sharp | IT Consulting Services company in USA" >
      <img src="../../assets/img/Thehomedepot.webp" alt="The Home Depot | Software Development company in USA ">
      <img src="../../assets/img/U.s-D.Interior.webp" alt="US Departments | Web & Mobile Development company in USA">
      <img src="../../assets/img/wyeth.webp" alt="Wyeth | Cloud Software Development Company in USA">
    </div>
  </div>
</div>
<!--script type="text/javascript">
  (function (d, src, c) {
      var t = d.scripts[d.scripts.length - 1], s = d.createElement('script');
      s.id = 'la_x2s6df8d';
      s.async = true; s.src = src;
      s.onload = s.onreadystatechange = function () {
        var rs = this.readyState;
        if (rs && (rs != 'complete') && (rs != 'loaded')) { return; }
        c(this);
      };
      t.parentElement.insertBefore(s, t.nextSibling);
    })

      (document,
        'https://jtsiindia.ladesk.com/scripts/track.js',
        function (e) { LiveAgent.createKbSearchWidget('null', e); });
</script-->
<!-- <app-decorations></app-decorations> -->

<!-- <div class="modal" id="eventModal" tabindex="-1" role="dialog" aria-labelledby="eventModalLabel" aria-hidden="true" style="background-color: #0000004d;" [ngStyle]="{'display': displayModal }">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content admodal">
      <div class="modal-header">
        <button class="btn-close" data-dismiss="modal" aria-label="close" (click)="closeModal()"></button>
        <div class="onloadmodal">
          <img src="../../assets/img/Gitex-Global-Strip-Logo.png" alt="">
          <img class="title" src="../../assets/img/Gitex-Global-ext.png" alt="">
          <p>
            Explore the future of technology and services with  <span class="red">JTSi Technologies India Private Limited</span>  at <span class="darks">GITEX GLOBAL 2023</span>,  Dubai World Trade Centre, the largest tech and startup event in the world!  
            </p>
            <p>
  Experience firsthand, the latest innovations and digital transformation  that we offer across different industries.  
</p>
<p>
 <strong>Discover endless possibilities for blending your business  technological needs with us!</strong> 
  </p>
  <p>
 <span class="pinkn"> We look forward to seeing you there!</span>

          </p>
        </div>
      </div>
    </div>
  </div>

</div> -->