import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  )
  
  { 
    this.titleService.setTitle('Accounting and Finance Outsourcing services in USA | New Jersy |JTSi Technologies.');
    this.meta.addTags([
      {name:'description', content:'We offer Large/small businesses with customized Financial/Management/cost/Tax Accounting solutions with qualified accountants that provides excellent customer support and top-notch quality services in USA.'},
      {name:'keywords', content:'Accounting services, Accounting software industry, accounting software for small and large business'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:' Accounting and Finance Outsourcing services in USA | New Jersy |JTSi Technologies.'},
      {property :'og:description', content:'"We offer Large/small businesses with customized Financial/Management/cost/Tax Accounting solutions with qualified accountants that provides excellent customer support and top-notch quality services in USA. '},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/accounting-services' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/Accounting-services.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Accounting and Finance Outsourcing services in USA | New Jersy |JTSi Technologies.'},
      {name:'twitter:description', content:'We offer Large/small businesses with customized Financial/Management/cost/Tax Accounting solutions with qualified accountants that provides excellent customer support and top-notch quality services in USA. '},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Accounting-services.webp'}
      ]);
   }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
