import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-pagethumbnails',
  templateUrl: './pagethumbnails.component.html',
  styleUrls: ['./pagethumbnails.component.scss']
})
export class PagethumbnailsComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService

  ) { 
    this.titleService.setTitle('JTSi Technologies: Software Engineering and IT Professional Capabilities in India and USA');
    this.meta.addTags([
      { property:'og:type', content:'websites' },
      { property:'og:title', content:' JTSi Technologies : Software Engineering and IT Professional Capabilities in India and USA'},
      { property:'og:description' ,content:'Our Software Engineering capabilities include delivering fully customized applications on mobile, web, and cloud platforms for easy integration, flexibility, and cost-effectiveness'},
      { property:'og:url', content:'https://www.jtsiindia.com/capabilities'},
      { property:'og:image', content:'https://www.jtsiindia.com/assets/img/locations.webp'},                                                          
      { property:'og:site_name', content:'JTSi Technologies India'},
      { property:'article:modified_time', content:'fetch current time'},
      { name:'twitter:card', content:'summary_large_image'},
      { name:'twitter:site', content:'@Jtsiindia'},
      { name:'twitter:creator', content:'@Jtsiindia'},
      { name:'twitter:title', content:'JTSi Technologies : Software Engineering and IT Professional Capabilities in India and USA'},
      { name:'twitter:description', content:'Our Software Engineering capabilities include delivering fully customized applications on mobile, web, and cloud platforms for easy integration, flexibility, and cost-effectiveness'},
      { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Capabilities_header.webp'}
    
      ])
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
