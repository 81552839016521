import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-microsoft',
  templateUrl: './microsoft.component.html',
  styleUrls: ['./microsoft.component.scss']
})
export class MicrosoftComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService
  ) { 
    this.titleService.setTitle('Microsoft Technology company in USA |Virginia | JTSi Technologies.');
   
    this.meta.addTags([
      {name:'description', content:'JTSi is microsoft technology support company and has a team of experts in Microsoft azure, azure SQL, azure IOT, CMS, .net core third party payment gateway solutions, micro dots and micro services'},
      {name:'keywords', content:'Microsoft Technology, Microsoft support, Microsoft office '},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:'"Microsoft Technology company in USA |Virginia | JTSi Technologies.'},
      {property :'og:description', content:'"JTSi is microsoft technology support company and has a team of experts in Microsoft azure, azure SQL, azure IOT, CMS, .net core third party payment gateway solutions, micro dots and micro services. '},
      {property:'og:url',content:'https://www.jtsiindia.com/assets/img/microsoft_header.webp' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/microsoft_header.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Microsoft Technology company in USA |Virginia | JTSi Technologies.'},
      {name:'twitter:description', content:' JTSi is microsoft technology support company and has a team of experts in Microsoft azure, azure SQL, azure IOT, CMS, .net core third party payment gateway solutions, micro dots and micro services.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/microsoft_header.webp'}
      ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
