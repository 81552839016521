import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capabilities-item',
  templateUrl: './capabilities-item.component.html',
  styleUrls: ['./capabilities-item.component.scss']
})
export class CapabilitiesItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
