import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';

@Component({
  selector: 'app-pricingpage',
  templateUrl: './pricingpage.component.html',
  styleUrls: ['./pricingpage.component.scss']
})
export class PricingpageComponent implements OnInit {

  constructor(

    private titleService: Title,
    private meta: Meta

  ) 
  {
    this.titleService.setTitle('JTSi Technologies: Software Pricing | Delivery Model | Development Methods in USA and India');
    this.meta.addTag({name:'description', content:'Our team uses various software delivery models to prepare your software for market with cost-effective software pricing and development model in line with your project specifications.'});
   }

  ngOnInit(): void {
  }

}
