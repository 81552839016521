import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-agile-development',
  templateUrl: './agile-development.component.html',
  styleUrls: ['./agile-development.component.scss']
})
export class AgileDevelopmentComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Agile Offshore software development operating model | USA| JTSi Technologies');
   
    this.meta.addTags([
      {name:'description', content:'JTSi could easily ensure the quality of product by the agile/scrum offshore software development methodology and offers agile consulting services in US, UK and Europe countries.'},
      {name:'keywords', content:'agile software development, scrum software development, software development operating model'},
      {name :'description', content:'en-US',property:'og:locale'},
     {name :'description', content:'website',property:'og:type'},
     { property :'og:title', content:'"Agile Offshore software development operating model | USA| JTSi Technologies'},
   
     { property :'og:description', content:' "JTSi could easily ensure the quality of product by the agile/scrum offshore software development methodology and offers agile consulting services in US, UK and Europe countries.'},
         { property:'og:url',content:'"https://www.jtsiindia.com/delivery-pricing/agile-development-model' },
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Agile-Development-Model-2.webp' } ,                                                         
      { property:'og:site_name', content:'JTSi Technologies India'},
     {property:'article:modified_time', content:'fetch current time'},
     {name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'Agile Offshore software development operating model | USA| JTSi Technologies'},
     { name:'twitter:description', content:'JTSi could easily ensure the quality of product by the agile/scrum offshore software development methodology and offers agile consulting services in US, UK and Europe countries. '},
   {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Agile-Development-Model-2.webp'}
  ]);
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
