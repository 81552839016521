import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-software-center',
  templateUrl: './software-center.component.html',
  styleUrls: ['./software-center.component.scss']
})
export class SoftwareCenterComponent implements OnInit {


  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Software Engineering Technology company in USA | New Jersey | Washington| JTSi Technologies');

  
     this.meta.addTags([

  {name:'description', content:'The software engineering and development team at JTSi Technologies is based in Washington DC, USA and uses the latest software and mobile technologies, backed by a strong industry support network.'},
  {name:'keywords', content:'software Engineering Technologies, Software development, latest Software Technologies'},
      
  { property:'og:locale', content:'en_US'},
	{ property:'og:type', content:'website'},
	{ property:'og:title', content:'Software Engineering Technology company in USA | New Jersey | Washington| JTSi Technologies '},
	{ property:'og:description', content:'The software engineering and development team at JTSi Technologies is based in Washington DC, USA and uses the latest software and mobile technologies, backed by a strong industry support network.'},
	{ property:'og:url', content:'https://www.jtsiindia.com/capabilities/software-engineering-center'},
  { property:'og:image', content:'https://www.jtsiindia.com/assets/img/slide_01.webp'},                                                           
  { property:'og:site_name', content:'JTSi Technologies India'},
  { property:'article:modified_time', content:'fetch current time'},
  { name:'twitter:card', content:'summary_large_image'},
  { name:'twitter:site', content:'@Jtsiindia'},
  { name:'twitter:creator', content:'@Jtsiindia'},
  { name:'twitter:title', content:'Software Engineering Technology company in USA | New Jersey | Washington| JTSi Technologies'},
  { name:'twitter:description', content:'The software engineering and development team at JTSi Technologies is based in Washington DC, USA and uses the latest software and mobile technologies, backed by a strong industry support network.'},
  { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/slide_01.webp'},
    ])
  
  }


  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
