import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgForm, FormBuilder, FormGroup } from '@angular/forms';
import { TalktousService } from '../shared/talktous.service';
import { CommonfunctionsService } from '../utils/commonfunctions.service';
import { ToastrService } from 'ngx-toastr';
import { Talktous } from '../shared/talktous.model';
import { CookieService } from 'ngx-cookie-service';
declare var require: any
const FileSaver = require("file-saver");

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  
})
export class FooterComponent implements OnInit {
  frmMngCookies: FormGroup;
  essentials: boolean = true;
  targetadvertise: boolean = true;
  personalisation: boolean = true;
  public sidebarShow: boolean = false;
  analytics: boolean = true;
  public showCookiePopup: boolean = false;
  constructor(
    public talktousservice: TalktousService,
    public cdRef: ChangeDetectorRef,
    public commonfunctions: CommonfunctionsService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private cookie: CookieService) { }
  phoneValid = true;
  isShowBtn: boolean = false;
  ngOnInit(): void {
    this.initCookieFrm();
    this.essentials = true;
    this.analytics = true;
    this.targetadvertise = true;
    this.personalisation = true;
    const cookie_btn = document.querySelectorAll(".cookiechoice");
    cookie_btn.forEach(button =>
      button.addEventListener("click", _ => {
        document.getElementById("cookienavmenu").classList.toggle("open");
      })
    );
    this.checkCookies();
  }
  onSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }

    frm.controls['phoneNumber'].setValue(frm.value.countryCode + frm.value.phoneNumber);
    console.log(frm.value);
    this.talktousservice.postTalktousDetail().subscribe(
      res => {
        this.resetForm(frm);
        this.downloadPDF();
        this.toaster.success("Thank you for contacting us", "");
      },
      err => {
        this.toaster.error("Failed to download", "");
        console.log(err);
      }
    )
    
  }


  resetForm(frm: NgForm) {
    frm.resetForm();
    this.talktousservice.formData = new Talktous();
  }
  hasError(event: any, frm: NgForm) {

    this.phoneValid = true;

    if (!event && frm.value.phoneNumber != "") {
      this.phoneValid = false;


    }
  }

  onContryChange(event: any, frm: NgForm) {
    frm.controls['countryCode'].setValue(event.dialCode);
  }

  downloadPDF() {
    FileSaver.saveAs("../../assets/img/JTSiIndiaBrochure.pdf", "JTSiIndiaBrochure");
  }

  initCookieFrm() {
    this.frmMngCookies = this.fb.group({
      essentials: [''],
      targetadvertise: [''],
      personalisation: [''],
      analytics: [''],
    })
  }
  manageCookies(data: any) {
    const Essentials =  this.cookie.check('Essentials');
    const targetadvertise =  this.cookie.check('targetadvertise');
    const personalisation =  this.cookie.check('personalisation');
    const analytics =  this.cookie.check('analytics');
    this.sidebarShow = !this.sidebarShow
    if(data.essentials == true){
      
        this.cookie.set('Essentials','Essentials',{expires: 1})
     
    }
    if(data.targetadvertise == true){
      if(targetadvertise == false){
        this.cookie.set('targetadvertise','targetadvertise',{expires: 1}) 
      }
    }else{
      this.cookie.delete('targetadvertise')
    }
    if(data.personalisation == true){
      if(personalisation == false){
      this.cookie.set('personalisation','personalisation',{expires: 1}) 
      }
    }else{
      this.cookie.delete('personalisation') 
    }
    if(data.analytics == true){
      if(analytics  == false){
      this.cookie.set('analytics','analytics',{expires: 1}) 
      }
    }else{
      this.cookie.delete('analytics') 
    }
  }
  checkCookies(){
   const isChecked =  this.cookie.check('Essentials');
   const targetadvertise =  this.cookie.check('targetadvertise');
   const personalisation =  this.cookie.check('personalisation');
   const analytics =  this.cookie.check('analytics');
 
   this.showCookiePopup = isChecked == true || targetadvertise == true || personalisation == true || analytics == true? true: false;
   this.isShowBtn = isChecked == true || targetadvertise == true || personalisation == true || analytics == true? true: false;
  }
  showPreference(){
    this.sidebarShow = !this.sidebarShow ;
    this.showCookiePopup =true;
    this.isShowBtn =true;
  }
  getCookie(){
    this.sidebarShow = !this.sidebarShow ;
    this.showCookiePopup =true;
    this.isShowBtn =true;
    const isChecked =  this.cookie.check('Essentials');
    const targetadvertise =  this.cookie.check('targetadvertise');
    const personalisation =  this.cookie.check('personalisation');
    const analytics =  this.cookie.check('analytics');
    this.essentials = isChecked == true? true: false;
    this.targetadvertise = targetadvertise == true? true: false;
    this.personalisation = personalisation == true? true: false;
    this.analytics = analytics == true? true: false;
    if (this.essentials == false){
      this.essentials = true;
      this.analytics = true;
      this.targetadvertise = true;
      this.personalisation = true; 
    }
  }
}
