import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Title, Meta } from '@angular/platform-browser';
SwiperCore.use([Autoplay]);

@Component({
  selector: 'app-accountingservicenew',
  templateUrl: './accountingservicenew.component.html',
  styleUrls: ['./accountingservicenew.component.scss']
})
export class AccountingservicenewComponent implements OnInit {

  constructor(private titleService: Title,
    private meta: Meta,) { 
      this.titleService.setTitle('Outsourced Accounting Services Offerings|Accounting and Financial services in USA|New Jersy|JTSi Technologies.');
    
      this.meta.addTags([
        {name:'description', content:'We offer large and small business with customized and Outsourced Financial/Management/Cost/Tax Accounting solutions and Internal Auditing with qualified accountants that provides excellent customer support and top-notch quality services in USA'},
        {name:'keywords', content:'Accounting services, Financial services, Outsourced accounting services, Accounting software industry, Accounting software for small and large business, Internal auditing services'}
      ])
    }

  ngOnInit(): void {
  }

  slidesPerView: number = 4;
  scrollbar: any = false;

  // toggleScrollbar() {
  //   if (!this.scrollbar) {
  //     this.scrollbar = { draggable: true };
  //   } else {
  //     this.scrollbar = false;
  //   }
  // }
  config: SwiperOptions = {
    spaceBetween: 50,
    autoplay: {
      delay:2000,
      disableOnInteraction:false
    }
  }
  breakpoints = {
    320: { slidesPerView: 1, spaceBetween: 1 },
    640: { slidesPerView: 2, spaceBetween: 5 },
    768: { slidesPerView: 3, spaceBetween: 8 },
    1024:{ slidesPerView: 4, spaceBetween: 50 },
  };

}
