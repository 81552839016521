import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-enterprise-software',
  templateUrl: './enterprise-software.component.html',
  styleUrls: ['./enterprise-software.component.scss']
})
export class EnterpriseSoftwareComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Enterprise software application development company in Washington DC| JTSi Technologies');
    
  this.meta.addTags([
  {name:'description', content:'JTSiiiiiiiiiiiiiiiiiii technologies Is a leading enterprise software development company based in the United States who offer deep expertise in enterprise application development and the integration of sophisticated software solutions'},
  {name:'keywords', content:'Enterprise Software Development, Enterprise application development services, Enterprise software design'},
  {property :'og:type', conten:'website'},
  {property :'og:title', content:'Enterprise software application development company in Washington DC| JTSi Technologies'},
  {property :'og:description', content:'JTSi technologies Is a leading enterprise software development company based in the United States who offer deep expertise in enterprise application development and the integration of sophisticated software solutions.'},
  {property :'og:url', content:'https://www.jtsiindia.com/capabilities/enterprise-software-development'},
  {property :'og:image', content:'https://www.jtsiindia.com/assets/img/Enterprise-Software-Development.webp'},                                                          
  {property :'og:site_name', content:'JTSi Technologies India'},
  {property :'article:modified_time', content:'fetch current time'},
  { name:'twitter:card', content:'summary_large_image'},
  { name:'twitter:site', content:'@Jtsiindia'},
  { name:'twitter:creator', content:'@Jtsiindia'},
  { name:'twitter:title', content:'Enterprise software application development company in Washington DC| JTSi Technologies'},
  { name:'twitter:description', content:'JTSi technologies Is a leading enterprise software development company based in the United States who offer deep expertise in enterprise application development and the integration of sophisticated software solutions.'},
  { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Enterprise-Software-Development.webp'},
    
])
  }
  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}


    
  
