import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-time-and-material',
  templateUrl: './time-and-material.component.html',
  styleUrls: ['./time-and-material.component.scss']
})
export class TimeAndMaterialComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,

  ) { 
    this.titleService.setTitle('Time and material software development pricing and engagement model| JTSi Technologies');
    this.meta.addTag({name:'description', content:'JTSi offers time and material contracts and method of software pricing and engagement based on man-hours of specialists and resources used for short and long-term clients in New jersey, USA'});
    this.meta.addTag({name:'keywords', content:'Time and material pricing, Time and material software development, Staffing solution pricing model'});
  
    this.meta.addTags([

     { property:'og:locale', content:'en_US'},
     { property:'og:type', content:'website'},
     { property:'og:title', content:'Time and material software development pricing and engagement model| JTSi Technologies '},
     { property:'og:description', content:'JTSi offers time and material contracts and method of software pricing and engagement based on man-hours of specialists and resources used for short and long-term clients in New jersey, USA.'},
     { property:'og:url', content:'https://www.jtsiindia.com/delivery-pricing/time-and-material'},
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Time-and-Material.webp'},                                                          { property:'og:site_name', content:'JTSi Technologies India'},
     { property:'article:modified_time', content:'fetch current time'},
     { name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'Time and material software development pricing and engagement model| JTSi Technologies'},
     { name:'twitter:description', content:'JTSi offers time and material contracts and method of software pricing and engagement based on man-hours of specialists and resources used for short and long-term clients in New jersey, USA.'},
     { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Time-and-Material.webp'},
    ])
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
