import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-implementation',
  templateUrl: './implementation.component.html',
  styleUrls: ['./implementation.component.scss']
})
export class ImplementationComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
      this.titleService.setTitle('Software Service and Product Implementation Company| USA |New Jersey| JTSi Technologies');
      this.meta.addTags([
      {name:'description', content:'As a leading provider of software and product implementation services in the U.S., UK, and Canada, we provide the best solutions and technology experience ranging from small businesses to large enterprises '},
      {name:'keywords', content:'Software Implementation, IT Implementation, Product and service Implementation'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:'Software Service and Product Implementation Company| USA |New Jersey| JTSi Technologies'},
      {property :'og:description', content:'"As a leading provider of software and product implementation services in the U.S., UK, and Canada, we provide the best solutions and technology experience ranging from small businesses to large enterprises.'},
      {property:'og:url',content:'https://www.jtsiindia.com/capabilities/implementation-services' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/Implementaion-Service.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Software Service and Product Implementation Company| USA |New Jersey| JTSi Technologies.'},
      {name:'twitter:description', content:'As a leading provider of software and product implementation services in the U.S., UK, and Canada, we provide the best solutions and technology experience ranging from small businesses to large enterprises.'},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Implementaion-Service.webp'}
      ]);
    }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
