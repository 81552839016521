import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-valueproposition',
  templateUrl: './valueproposition.component.html',
  styleUrls: ['./valueproposition.component.scss']
})
export class ValuepropositionComponent implements OnInit {

  constructor(

    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Value Proposition | Cost effective solutions | JTSi Technologies');
    this.meta.addTags([
      {name:'description', content:'JTSi\'s value proposition ensures uncompromised excellence from a cost-effective perspective.'},
      {property:'og:locale', content:'en_US' } ,
      {property:'og:type', content:'website'},
      {property:'og:title' ,content:'"Value Proposition | Cost effective solutions | JTSi Technologies '},
      {property:'og:description', content:'JTSis  value proposition ensures uncompromised excellence from a cost-effective perspective.'},
      {property:'og:url', content:'"https://www.fingent.com'},
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/homeSlide.webp'},
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time' },
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Value Proposition | Cost effective solutions | JTSi Technologies'},
      {name:'twitter:description', content:'JTSi technologies is a software development company based in Virginia,United States that offers offshore software and IT development services as well as professional business consulting.'},
      {name:'twitter:image',content:'https://www.jtsiindia.com/assets/img/homeSlide.webp'}
      ]);
        
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
