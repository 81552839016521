import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor( 
    private titleService: Title,
    private meta: Meta,
    public commonfunctions: CommonfunctionsService,) {
      this.titleService.setTitle('IT | Software Application maintenance services in USA | New Jersy | JTSi Technologies.');
      this.meta.addTag({name:'description', content:'We provide global IT maintenance and support services for our clients in US, UK, and Canada. We specialize in software application maintenance solutions.'});
      this.meta.addTag({name:'keywords', content:'IT Maintenance, Software application maintenance, Technology support'});

    this.meta.addTags([
     { property:'og:locale', content:'en_US'},
     { property:'og:type', content:'website'},
     { property:'og:title', content:' IT | Software Application maintenance services in USA | New Jersy | JTSi Technologies.'},
     { property:'og:description', content:'We provide global IT maintenance and support services for our clients in US, UK, and Canada. We specialize in software application maintenance solutions.'},
     { property:'og:url', content:'https://www.jtsiindia.com/capabilities/maintenance-services'},
     { property:'og:image', content:'https://www.jtsiindia.com/assets/img/Maintenance-Services.webp'},                                                          { property:'og:site_name', content:'JTSi Technologies India'},
     { property:'article:modified_time', content:'fetch current time'},

     { name:'twitter:card', content:'summary_large_image'},
     { name:'twitter:site', content:'@Jtsiindia'},
     { name:'twitter:creator', content:'@Jtsiindia'},
     { name:'twitter:title', content:'IT | Software Application maintenance services in USA | New Jersy | JTSi Technologies.'},
     { name:'twitter:description', content:'We provide global IT maintenance and support services for our clients in US, UK, and Canada. We specialize in software application maintenance solutions.'},
     { name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/Maintenance-Services.webp'},


    ])
   }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
