import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
@Component({
  selector: 'app-whyjtsi',
  templateUrl: './whyjtsi.component.html',
  styleUrls: ['./whyjtsi.component.scss']
})
export class WhyjtsiComponent implements OnInit {

  constructor(){}
  
  ngOnInit(): void {
        }

}
