import { Component, OnInit } from '@angular/core';
import { Meta, Title}from '@angular/platform-browser';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  constructor(
    
    private titleService: Title,
    private meta: Meta,
    private commonfunctions : CommonfunctionsService

  ) { 
    this.titleService.setTitle('Mobile Development Technologies and solutions in USA | JTSi Technologies.');
    this.meta.addTags([
      {name:'description', content:'The JTSi mobile technology development services strive to make the latest mobile development technologies and mobility solutions for countries such as UK, US, Canada, and Europe to meet their business needs.'},
      {name:'keywords', content:'Mobile development, Mobile technologies, Latest mobile technologies'},
      {name :'description', content:'en-US',property:'og:locale'},
      {name :'description', content:'website',property:'og:type'},
      {property :'og:title', content:'"Mobile Development Technologies and solutions in USA | JTSi Technologies.'},
      {property :'og:description', content:'"The JTSi mobile technology development services strive to make the latest mobile development technologies and mobility solutions for countries such as UK, US, Canada, and Europe to meet their business needs. '},
      {property:'og:url',content:'https://www.jtsiindia.com/technologies/mobile' },
      {property:'og:image', content:'https://www.jtsiindia.com/assets/img/mobileBanner.webp' } ,                                                         
      {property:'og:site_name', content:'JTSi Technologies India'},
      {property:'article:modified_time', content:'fetch current time'},
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:site', content:'@Jtsiindia'},
      {name:'twitter:creator', content:'@Jtsiindia'},
      {name:'twitter:title', content:'Mobile Development Technologies and solutions in USA | JTSi Technologies.'},
      {name:'twitter:description', content:'The JTSi mobile technology development services strive to make the latest mobile development technologies and mobility solutions for countries such as UK, US, Canada, and Europe to meet their business needs. '},
      {name:'twitter:image', content:'https://www.jtsiindia.com/assets/img/mobileBanner.webp'}
      ]);
 
  }

  ngOnInit(): void {
    this.commonfunctions.CreateCanonicalURL();
  }

}
